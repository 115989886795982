@import url('https://fonts.googleapis.com/css?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

@import url('./Common.css');

#alterra-chat-button {
    background-color: rgba(33, 93, 244, 0.773) !important;
    border-radius: 15px;
    bottom: 1em !important;
    right: 0.9em !important;
}

#chartcontainer div {
    width: 100% !important;
}

#clearsources {
    height: 2.1em !important;
}

#contain {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding-top: 20px;
}

#contain .form-label {
    font-size: 0.9em !important;
}

#ellipse {
    left: 0px;
    top: 0;
    z-index: 1;
}

#explainsteps .rcicon-check:before {
    color: white;
}

#explainsteps .rc-steps-item {
    padding-bottom: 10px !important;
    padding-right: 20px !important;
}

#explainsteps .rc-steps-item-description {
    color: #212529 !important;
    font-size: 16px !important;
}

#explainsteps .rc-steps-item-icon {
    background-color: var(--primary-color) !important;
    color: var(--primary-color-text) !important;
    font-size: 20px !important;
    height: 36px !important;
    line-height: 2 !important;
    top: -6 px !important;
    width: 36px !important;
}

#explainsteps .rc-steps-item-tail {
    left: 16px !important;
    top: 5px !important;
}

#explainsteps .rc-steps-item-title {
    color: var(--primary-color-2) !important;
    font-size: 20px !important;
    margin-top: 6px !important;
}

#firstpage {
    background-image: url("./landingbackground.png");
    background-repeat: no-repeat !important;
    background-size: 100%;
}

#highcost .card-body {
    cursor: pointer;
    margin-left: 5px;
    padding-bottom: 2px;
    padding-top: 2px;
}

#highcost .card-header {
    cursor: pointer;
    padding-bottom: 2px;
    padding-top: 2px;
}

#highcost .card-header:hover {
    text-decoration: underline;
}

#homebutton {
    background-color: rgb(6, 9, 72) !important;
    height: 45px;
    width: 45px;
}

#homebutton:hover {
    filter: drop-shadow(1px 6px 3px rgb(0, 22, 50));
}

#homepic {
    height: 30em;
    width: 30em;
}

#iphone {
    left: 0px;
    top: 0px;
    z-index: -1;
}

#liabilitystepper .rcicon-check:before {
    color: rgb(3, 182, 3);
    cursor: pointer;
    font-size: 18px !important;
    font-weight: 700 !important;
}

#liabilitystepper .rcicon-cross:before {
    color: rgb(255, 157, 157);
    font-size: 18px !important;
    font-weight: 700 !important;
}

#liabilitystepper .rc-steps-item {
    padding-bottom: 1px !important;
    padding-right: 20px !important;
}

#liabilitystepper .rc-steps-item-content {
    font-size: 0.8em !important;
}

#liabilitystepper .rc-steps-item-description {
    color: #212529 !important;
    font-size: 16px !important;
}

#liabilitystepper .rc-steps-item-icon {
    _background: #1F3988;
    font-size: 16px !important;
    height: 30px !important;
    line-height: 2 !important;
    margin-top: 2px;
    width: 30px !important;
}

#liabilitystepper .rc-steps-item-process .rc-steps-item-icon {
    background: #1F3988;
}

#liabilitystepper .rc-steps-item-sub-step .rc-steps-item-icon {
    _background: #cccccc;
    font-size: 0px !important;
    height: 20px !important;
    line-height: 2 !important;
    margin-top: 10px;
    width: 20px !important;
}

#liabilitystepper .rc-steps-item-tail {
    left: 16px !important;
    top: 5px !important;
}

#liabilitystepper .rc-steps-item-title {
    color: #212529 !important;
    font-size: 16px !important;
    margin-top: 6px !important;
    width: 100%;
}

#loans {
    table-layout: auto;
}

#more {
    border: none;
    float: left;
}

.bluebackground {
    background-color: #F7F8FC !important;
}

#myapp {
    height: 100%;
    width: 100%;
    background-color: #F7F8FC;
    ;
}

#navb {
    margin-top: 17px;
}

#root {
    height: 100vh;
}

#scaledtable {
    table-layout: auto;
}

#scaledtable td {
    padding-bottom: 0.75em !important;
    padding-top: 0.75em !important;
}

._7ahQImyV4dj0EpcNOjnwA {
    margin-bottom: 2px !important;
}

.about {
    color: #060948 !important;
    font-size: 1.4em !important;
    font-weight: bold !important;
}

.about:hover {
    text-decoration: underline !important;
}

.acct {
    font-size: 1.1em !important;
    font-weight: bold;
}

.alterra-chat-tooltip-arrow {
    border-left-color: #215ef4 !important;
}

.alterra-chat-tooltip-text {
    background-color: #215ef4 !important;
}

.arrowdown {
    bottom: 2px;
    color: rgba(0, 48, 116, 1) !important;
    cursor: pointer;
    left: 50%;
    opacity: 0.4;
    position: fixed;
    text-align: center;
    z-index: 10000;
}

.arrowdown:hover {
    background-color: transparent !important;
    color: #215ef4 !important;
    opacity: 0.7 !important;
}

.avatar {
    border: 2px rgb(57, 79, 61);
    border-radius: 50%;
    height: 36px;
    margin-top: 6px;
    width: 36px;
}

.avatartd {
    display: flex !important;
    justify-content: center !important;
    padding: 0px !important;
    vertical-align: middle !important;
}

.backimage {
    background-image: url("./housemoney.png");
    background-position: bottom 0px right 50px;
    background-repeat: no-repeat !important;
    position: relative;
}

.backimagecalc {
    background-image: url("./tools.png");
    background-position: bottom 0px left 50px;
    background-repeat: no-repeat !important;
    background-size: 30%;
    position: relative;
}

.bimagex {
    background-image: url("./strip.png") !important;
    background-position: top 0px right 0px;
    background-repeat: repeat-y !important;
}

.blackongray {
    background-color: #ebebeb;
}

.blink_me {
    animation: pulse 1s linear;
    animation-delay: 1s;
    animation-iteration-count: 10;
}

.blink_me_off {
    animation: pulse 1s linear;
    animation-delay: 1.5s;
    animation-iteration-count: 10;
}

.blocklink {
    background-color: #f7f9fc;
    padding-left: 8px;
    padding-right: 8px;
    color: var(--primary-color-2)
}

.blocklink:hover {
    color: var(--primary-color-3)
}

.blue {
    color: #222b44 !important;
}

.blue-border {
    border-color: rgba(0, 48, 116, 1) transparent transparent !important;
}

.bluish {
    color: var(--primary-color-3);
}

.primary-color {
    color: var(--primary-color) !important;
}

.primary-dark-color {
    color: var(--primary-color-3) !important;
}

button.primary-dark-color:focus {
    box-shadow: 0 0 0 2px var(--primary-color-2) !important;
}

.primary-color-pale {
    color: var(--primary-pale) !important;
}

.ratesfilterDropdown {
    min-width: 300px !important;
    min-height: 450px !important;
}

.bluishbackground {
    background-color: #E9F0FD !important;
}

.borrowercolumn {
    text-align: center;
    line-height: 50px;
    min-width: 6em !important;
    width: 8em !important;
}

.bottompadding {
    padding-bottom: 4em;
}

.border {
    border-radius: 4px;
}

.brandtext {
    color: #060948 !important;
    font-family: Inter !important;
    font-size: 1.3em;
    font-weight: 500 !important;
    margin-left: 2px;
    margin-top: -4px;
}

.btn-circle {
    border-radius: 15px;
    bottom: 10px;
    font-size: 12px;
    height: 30px;
    left: 10px;
    line-height: 1.42857;
    opacity: 0.4;
    padding: 0px 0px;
    position: fixed;
    text-align: center;
    width: 30px;
}

.btn-circle.btn-xl {
    border-radius: 35px;
    font-size: 24px;
    height: 50px;
    line-height: 1.33;
    padding: 10px 16px;
    width: 50px;
}

.btn-dropdown {
    background-color: #325CEB !important;
    border-radius: 0 4px 4px 0 !important;
    color: white !important;
}

.btn-dropdown:focus {
    box-shadow: 0 0 0 0.2px var(--secondary-color) !important;
}

.btn-dropdown:hover,
.btn-dropdown:active,
.btn-dropdown:visited {
    background-color: var(--primary-color-2) !important;
    border-color: var(--primary-color-3) !important;
    color: var(--primary-color-2-text) !important;
}

.btn-landing {
    background-color: rgb(233, 236, 239) !important;
    border-color: rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px !important;
    border-style: solid !important;
    box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1) inset !important;
    cursor: default;
    text-align: center;
    transition: all 0.1s;
}

.btn-landing:active {
    background: #102693 !important;
    border: solid 3px #828ece !important;
    color: white !important;
}

.btn-landing:hover {
    background: #102693 !important;
    color: white !important;
}

.btn-outline-success,
.btn-outline-warning {
    background-color: white !important;
    border-radius: 4px !important;
}

.btn-secondary {
    background-color: var(--primary-color) !important;
    color: var(--primary-color-text) !important;
    border-color: var(--primary-color-2) !important;
}

.btn-success {
    border-radius: 2px !important;
    font-size: 0.97em !important;
    padding-left: 2em !important;
    padding-right: 2em !important;
}

.calcChart {
    height: 65%;
    width: 65%;
}

.capitalize {
    text-transform: capitalize !important;
}

.cardbody {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.15s ease;
}

.cCseLN {
    background: white;
    border: 1px solid #dedede;
    width: 100% !important;
}

.clearfix {
    zoom: 1;
}

.clearfix:after {
    clear: both;
}

.clearfix:before,
.clearfix:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}

.closewindow {
    color: #933;
    cursor: pointer !important;
}

.closewindow:hover {
    color: #d22;
    cursor: pointer !important;
}

.container {
    padding-left: 10px;
    padding-right: 10px;
    position: relative;
    width: 100% !important;
}

.crisscrossed {
    background: repeating-linear-gradient(-45deg, transparent, transparent 10px, #fee 10px, #fee 20px),
        linear-gradient(to bottom, #fee, #edd);
}

.darkgreen {
    color: rgb(0, 109, 44);
}

.darkred {
    color: rgb(106, 0, 0);
}

.deepblue {
    color: #151a30 !important;
}

.deepestblue {
    color: #060948 !important;
}

.divlink {
    /* font-size: 14px; */
    color: #586570;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
}

.divlink:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
}

.downarrow {
    color: #060948;
}

.downarrow:hover {
    box-shadow: 0 0 2px #215ef4;
    color: #1F3988;
    cursor: pointer;
}

.downpayment {
    height: 20px !important;
    width: 70px !important;
}

.dropdown-menu {
    height: auto !important;
}

.dropdown-menu {
    height: 300px;
    overflow-y: scroll;
}

.dropdown-menu {
    max-height: 15em;
}

.dropdownsort button {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
    background-color: white !important;
}

.dropdownsort button:focus {
    background-color: var(--primary-color-2) !important;
    border-color: var(--primary-color) !important;
    color: var(--primary-color-2-text) !important;
}

.dropdownsort button:hover {
    background-color: var(--primary-color-2) !important;
    border-color: var(--primary-color) !important;
    color: var(--primary-color-2-text) !important;
}

.dropdown-toggle {
    font-family: Inter !important;
    font-size: 1em;
    font-weight: bold !important;
}

.drop-shadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    moz-box-shadow: 0 0 10px var(--primary-color-3);
    webkit-box-shadow: 0 0 10px var(--primary-color-3);
}

.drop-big-shadow {
    box-shadow: 0 0 40px var(--primary-color-3);
    moz-box-shadow: 0 0 40px var(--primary-color-3);
    webkit-box-shadow: 0 0 40px var(--primary-color-3);
}

.email-column {
    min-width: 100px !important;
}

.err {
    color: red;
    font-size: 13px;
    margin-bottom: 1rem;
}

.fa-caret-down {
    margin-top: 0px !important;
}

.fahover:hover {
    color: rgba(0, 48, 116, 1) !important;
}

.fingerclass {
    animation: wshake 1s 8 ease;
    webkit-animation: wshake 1s 8 ease;
}

.font_rotate {
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9914448613738104, M12=-0.13052619222005157, M21=0.13052619222005157, M22=0.9914448613738104, sizingMethod='auto expand');
    moz-transform: rotate(17.5deg);
    ms-transform: rotate(17.5deg);
    o-transform: rotate(17.5deg);
    transform: rotate(17.5deg);
    webkit-transform: rotate(17.5deg);
    zoom: 1;
}

.footer {
    bottom: 0px;
    height: 210px;
    margin-top: 0px;
    padding-top: 0px;
    position: relative;
    width: 100%;
}

.form-check-label {
    cursor: pointer !important;
    font-size: 14px;
    margin-top: 3px;
    margin-left: 5px;
}

.form-control-sm {
    font-size: 1rem !important;
    height: calc(1.5em + 0.5rem + 2px);
    line-height: 1.5;
    padding: 0.25rem 0.5rem;
}
.form-control.liabilities-form-control-sm {
    border-radius: 6px;
    border: 1px solid #ECECEC;
    background-color: #FCFCFC;
}
.fullwidth {
    margin-left: 10px;
    margin-right: 10px;
    width: 100% !important;
}

.gray-background {
    background-color: #eeeeee !important;
}

.gray-shadow {
    box-shadow: 0 0 40px #cccccc;
    moz-box-shadow: 0 0 40px #cccccc;
    webkit-box-shadow: 0 0 40px #cccccc;
}

.graytext {
    color: #bbb !important;
}

.graytext .blue {
    color: #bbb !important;
}

.greencrisscrossed {
    background: repeating-linear-gradient(-45deg, transparent, transparent 10px, #efe 10px, #efe 20px),
        linear-gradient(to bottom, #efe, #ded);
}

.gubmint:invalid {
    border-color: red;
}

.gubmint:invalid:focus {
    border-color: red;
}

.heading {
    font-size: 22px !important;
    margin-left: 40px;
    white-space: normal;
}

.subheading {
    font-size: 14px !important;
    white-space: normal;
    color: #6E6E70;
}

.heavyspaced {
    color: #060948 !important;
    font-family: "Inter" !important;
    font-size: 3.1em !important;
    font-weight: 500 !important;
    letter-spacing: 0.08em !important;
}

.high-cost-modal {
    max-width: 550px !important;
    width: 550px !important;
}

.home {
    background-color: white !important;
    box-shadow: none;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 2px 10px 4px rgba(51, 51, 51, 0.06);
    min-width: 100px;

}

.hubspotbox {
    background-color: #eef2fa;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
}

.ib {
    display: inline-block;
    margin-right: 2px;
}

.incard {
    background-color: #F5F5F7 !important;
    color: var(--primary-pale-text) !important;
    border-radius: 6px;
    margin-bottom: 15px;
    padding-bottom: 20px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 15px;
}



.incdropdown {
    margin: 0px !important;
    padding-left: 0em !important;
    padding-right: 0.1em !important;
}

.input-group-prepend,
.input-group-append {
    font-size: 0.8rem !important;
}

.inputSection {
    display: flex;
    flex-direction: column;
    /* width: calc(100% - 10px); */
}

.landingbutton {
    font-size: 1.1em !important;
}

.left {
    float: left;
    height: 200px;
    width: 100px;
}

.lightblue {
    background-color: #e9f2ff;
}

.lightgreen {
    background-color: rgb(232, 242, 236);
}

.littleheading {
    font-weight: 500 !important;
}

.loader {
    animation: round 1.7s infinite ease;
    color: var(--primary-color);
    filter: drop-shadow(2px 2px 2px var(--secondary-color));
    font-size: 90px;
    margin: 0px auto;
    ms-transform: translateZ(0);
    opacity: 0.3;
    overflow: hidden;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    webkit-animation: round 1.7s infinite ease;
    webkit-filter: drop-shadow(2px 2px 2px var(--secondary-color));
    webkit-transform: translateZ(0);
    width: 54px;
}

.loancard {
    font-size: 1em;
}

.loandetails {
    max-width: 90% !important;
    width: 90% !important;
}

.loandetails .close {
    color: white;
}

.loandetails .close:hover {
    color: white;
    font-weight: 600;
}

.loandetails .modal-body {
    background: var(--primary-pale);
    padding-bottom: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
}

.loandetails .modal-content {
    height: 700px !important;
    min-height: 700px !important;
}

.loandetails .modal-footer {
    background: var(--primary-pale);
    padding-bottom: 4px;
    padding-top: 4px;
}

.loandetails .modal-header {
    background-color: var(--primary-color-2);
    color: var(--primary-color-2-text) !important;
    padding-bottom: 2px;
    padding-top: 2px;
}

.loandetails a {
    color: black;
}

.loandetails a:hover {
    color: black;
    cursor: pointer !important;
    text-decoration: underline !important;
}

.employed .modal-content {
    height: 300px !important;
}

.loginbox {
    background-color: #f7f9fc;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    /* height: 700px; */
    padding-top: 40px;
}

.loginWrap {
    background-color: #f7f9fc;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    max-width: 450px;
    padding: 40px 35px 0px 35px;
    min-height: 500px;
}

.logo {
    height: 26px;
    margin-left: 40px;
    margin-top: -7px;
}

.logo-notifications {
    height: 26px;
    margin-left: 10px;
    margin-top: 4px;
}

.logoprivate {
    height: 25px;
    margin-left: 10px;
    margin-top: -5px;
}

.lpbutton,
.btn-primary {
    background-color: var(--primary-color) !important;
    border-radius: 4px !important;
    font-size: 0.97em !important;
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
    border-color: var(--primary-color-2) !important;
    color: var(--primary-color-2-text) !important;
    height: 31px;
}

.lpbutton:hover,
.btn-primary:hover {
    background-color: var(--primary-color-2) !important;
    color: var(--primary-color-2-text) !important;
}

.maxwidth-400 {
    width: 600px;
}

.mobilewide {
    padding-left: 3em !important;
    padding-right: 3em !important;
}

.modal-50w {
    max-width: 650px !important;
    width: 650px !important;
}

.modal-75w {
    max-width: 75% !important;
    width: 75% !important;

}

.modal-dialog {
    margin: 0 auto !important;
}

.taskCount {
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #FFF;
    text-align: center;

    background: #1630AC;
    border-radius: 50%;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-right: 3px
        /* 171.429% */
}

.ant-input-outlined {
    border: 1px solid #ECECEC;
    border-radius: 6px !important;
}

.modal-90w {
    max-width: 90% !important;
    width: 90% !important;
}

.modal-body {
    z-index: 8100 !important;
    /* height: 83.6vh; */
    max-height: 100vh !important
}

.modal-content {
    z-index: 8000 !important;
    height: 100vh;
}

.preQualLetter .modal-content {
    top: 200px !important;
    height: 100% !important;
}

.modalfooter {
    margin-left: 0px !important;
    margin-right: 26px !important;
}

.modal-footer {
    z-index: 8100 !important;
}

.modal-header {
    z-index: 8100 !important;
}

.mpt-2 {
    padding-top: 0.65em !important;
}

.mpt-4 {
    padding-top: 1.3em !important;
}

.mybutton {
    background: none !important;
    border: none;
    color: rgba(0, 48, 116, 1) !important;
    padding: 0 !important;
}

.mybutton:hover {
    cursor: pointer;
    text-decoration: underline !important;
}

.mymodal {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    height: 100% !important;
    left: 0;
    overflow: auto;
    padding-top: 100px;
    position: fixed;
    top: 0;
    width: 100% !important;
    z-index: 1;
}

.mymodal-content {
    background-color: rgba(0, 255, 255, 0);
    border-width: 0px;
    height: 400pt !important;
    margin: auto;
    width: 300pt !important;
}

.myrow {
    display: block !important;
}

.navbar {
    padding: 0.8rem 1rem !important;
}

.navbar-brand {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    margin-left: 11px !important;
}

.navbar-nav {
    padding-bottom: 3px;
    padding-top: 3px;
}

.navbar-text {
    color: var(--primary-color) !important;
    padding-bottom: 0.15em !important;
    padding-top: 0.15em !important;
}

.nav-link {
    font-size: 0.8em;
    padding-bottom: 0.5em !important;
    padding-top: 0.5em !important;
}

.nomargin {
    margin: 0px !important;
}

.notification-header-text {
    color: black;
    margin: auto;
}

.notification-pannel-specific {
    position: absolute;
    right: 7vw;
    top: 2vh;
    z-index: 200;
}

.oscillate {
    animation: osc 2s infinite linear;
}

.padrow {
    padding-left: 4em;
    padding-right: 4em;
}

.paleblue {
    color: #7f92a4;
    font-family: serif;
}

.pb-10 {
    padding-bottom: 100px !important;
}

.pop-up-notification {
    box-shadow: -1px 0px 39px -1px var(--primary-color) !important;
    moz-box-shadow: -1px 0px 39px -1px var(--primary-color) !important;
    webkit-box-shadow: -1px 0px 39px -1px var(--primary-color) !important;
}

.public-DraftEditor-content {
    background-color: white;
    border: 1px solid lightgrey;
    max-height: 15em;
    min-height: 20em;
}

.punch {
    font-size: 1.2em !important;
}

.rdw-editor-toolbar {
    border: 1px solid lightgrey !important;
}

.rectangular {
    border-radius: 3px !important;
}

.red-background {
    background-color: #cc7777 !important;
}

.red-shadow {
    box-shadow: 0 0 10px #e9060699;
    moz-box-shadow: 0 0 10px #e9060699;
    webkit-box-shadow: 0 0 10px #e9060699;
}

.reveal {
    animation: animate 3s linear forwards;
}

.right {
    float: right;
    height: 200px;
    width: 100px;
}

.rightmobile {
    padding-right: 8px !important;
}

.roundedtab {
    background-color: var(--primary-color) !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-color: var(--primary-color-2);
    color: var(--primary-color-3-text) !important;
}

.row {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.row-nowrap {
    flex-wrap: nowrap !important;
}

.secondbackimage {
    background-position: top 0px right 0px;
    background-repeat: no-repeat !important;
    background-size: 40%;
    position: relative;
}

.sectionquestion {
    font-size: 1.1em;
    font-weight: bold;
    white-space: normal;
}

.businessquestion {
    color: #62707C;
    font-weight: 500;
    margin-bottom: 6px;
}

.shevron {
    color: white !important;
    font-size: 1.5em !important;
}

.shevron:hover {
    color: var(--secondary-color) !important;
}

.shevronr {
    color: var(--primary-color);
    font-size: 1.5em !important;
}

.shevronr:hover {
    color: var(--primary-color-2) !important;
}

.slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    webkit-transition: 0.4s;
}

.slider.round {
    background-color: green;
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider:before {
    background-color: white;
    bottom: 1px;
    content: "";
    height: 22px;
    left: 4px;
    position: absolute;
    transition: 0.4s;
    webkit-transition: 0.4s;
    width: 22px;
}

.smallhouse {
    height: 20% important !;
    width: 20% important !;
}

.smenu {
    font-size: 1em !important;
}

.social {
    max-width: 64px;
}

.socialicon {
    border-radius: 50%;
    cursor: pointer !important;
    width: 36px;
}

.socialicon:hover {
    border-radius: 50%;
    box-shadow: 1px 1px 5px #fff;
    moz-box-shadow: 1px 1px 5px #fff;
    webkit-box-shadow: 1px 1px 5px #fff;
}

.spaced {
    font-family: "Inter" !important;
    font-size: 2em !important;
    letter-spacing: 0.3em !important;
}

.spinarrowDown {
    border-color: rgba(0, 0, 0, 0.7) transparent transparent;
    border-style: solid;
    border-width: 0.5ex 0.5ex 0 0.5ex;
    height: 0;
    left: 50%;
    margin: -0.3ex 0 0 -0.56ex;
    position: absolute;
    top: 50%;
    width: 0;
}

.spinarrowUp {
    border-color: transparent transparent rgba(0, 0, 0, 0.7);
    border-style: solid;
    border-width: 0 0.5ex 0.5ex 0.5ex;
    height: 0;
    left: 50%;
    margin: -0.3ex 0 0 -0.56ex;
    position: absolute;
    top: 50%;
    width: 0;
}

.spinbtn {
    background: rgb(233, 236, 239);
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
    cursor: default;
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 16px !important;
    position: absolute;
    text-align: center;
    transition: all 0.1s;
    width: 20px !important;
}

.spinbtn:active {
    background: rgb(216, 219, 222);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset, -1px -1px 4px rgba(255, 255, 255, 0.5) inset;
}

.spinbtn:hover {
    background: rgb(221, 224, 228);
}

.spinbtnDown {
    border-radius: 0 0 2px 2px;
    border-width: 0 1px 1px 1px;
    bottom: -0px !important;
    top: 50%;
}

.spinbtnUp {
    border-radius: 2px 2px 0 0;
    border-width: 1px 1px 0 1px;
    bottom: 50%;
    top: 0px !important;
}

.spinwrap {
    display: "inline-block";
    position: relative;
}

.state-dropdown-menu button {
    background-color: var(--primary-color);
    border-color: var(--primary-color-2) !important;
    color: var(--primary-color-text) !important;
    overflow-y: scroll;
    width: 30px !important;
}

.state-dropdown-menu button:focus,
.state-dropdown-menu button:active,
.state-dropdown-menu button:visited {
    background-color: var(--primary-color-2) !important;
    border-color: var(--primary-color) !important;
    border-radius: 2px !important;
    box-shadow: 0 0 0pt 2pt var(--secondary-color);
    color: var(--primary-color-2-text) !important;
    outline: 2px solid var(--primary-color);
}

.state-dropdown-menu button:hover {
    background-color: var(--primary-color-2) !important;
    color: var(--primary-color-2-text) !important;
}

.steptop {
    margin-right: 3em;
    margin-top: 0.33em;
}

.steptop1 {
    margin-right: 5em;
    margin-top: 0.4em;
}

.subtag {
    font-size: 1em !important;
    margin-top: 23px;
}

.suggestion-active {
    background-color: rgba(0, 48, 116, 1) !important;
    color: #ffffff;
    cursor: pointer;
    font-weight: 700;
}

.suggestions {
    background-color: rgba(0, 48, 116, 1);
    border: 1px solid #999;
    border-top-width: 0;
    display: block;
    font-size: 0.8em;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    position: absolute;
    width: calc(300px + 1rem);
    z-index: 1;
}

.suggestions li {
    background-color: rgb(255, 255, 255);
    padding: 0.25rem;
}

.suggestions li:hover {
    background-color: #e9f2ff !important;
    color: #000000;
    cursor: pointer;
    font-weight: 700;
}

.switch {
    display: inline-block;
    height: 24px;
    position: relative;
    width: 60px;
}

.switch input {
    height: 0;
    opacity: 0;
    width: 0;
}

.tabajacent {
    border-bottom: 1px solid #222b44 !important;
}

.table-info tbody+tbody,
.table-info td,
.table-info th,
.table-info thead th {
    border-color: rgb(190, 196, 206) !important;
}

.table-info,
.table-info>td,
.table-info>th {
    background-color: rgb(221, 227, 237) !important;
    border-color: rgb(190, 196, 206) !important;
}

.tab-pane {
    background-color: white !important;
}

.tagline {
    font-size: 2.6em !important;
    font-weight: 500 !important;
    margin-bottom: 0px;
    margin-top: 73px !important;
    padding-bottom: 0px;
}

.teamicon {
    border-radius: 50%;
    height: 96px;
    margin-bottom: 0px;
    margin-top: 6px;
    width: 96px;
}

.teamicon:active {
    border: solid 2px rgb(103, 115, 140) !important;
    box-shadow: 0px 0px 5px rgb(103, 115, 140);
    margin-bottom: 0px;
    margin-top: 6px;
}

.teamicon:hover {
    border: solid 1px rgb(103, 115, 140) !important;
    box-shadow: 0px 5px 5px rgb(103, 115, 140);
    margin-bottom: 3px;
    margin-top: 3px;
}

.toolwrap {
    display: flex;
    width: 100%;
}

.toppadding {
    padding-top: 4em;
}

.transparent {
    opacity: 0;
}

.twtr-color-fill--blue-dark {
    fill: #1da1f2;
}

.twtr-icon {
    height: 24px;
    width: 24px;
}

.updown {
    background: rgb(233, 236, 239);
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
    box-shadow: -1px -1px 3px rgba(0, 0, 0, 0.1) inset, 1px 1px 3px rgba(255, 255, 255, 0.7) inset;
    cursor: default;
    height: 13px;
    left: 86px;
    position: absolute;
    text-align: center;
    transition: all 0.1s;
    width: 2ex;
}

.updown:active {
    background: rgb(216, 219, 222);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset, -1px -1px 4px rgba(255, 255, 255, 0.5) inset;
}

.updown:hover {
    background: rgb(221, 224, 228) wheat;
}

.uspinbtn:active {
    background: rgb(216, 219, 222);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset, -1px -1px 4px rgba(255, 255, 255, 0.5) inset;
}

.uspinbtn:hover {
    background: rgb(221, 224, 228);
}

.uspinbtnDown {
    border-radius: 0 0 2px 2px;
    border-width: 0 1px 1px 1px;
    bottom: 2px !important;
    top: 50%;
}

.uspinbtnUp {
    border-radius: 2px 2px 0 0;
    border-width: 1px 1px 0 1px;
    bottom: 50%;
    top: 0px !important;
}

.vertab {
    border-bottom: 2px solid #e9e9e9;
    border-bottom-left-radius: 12px;
    border-left: 2px solid #e9e9e9;
    border-right: none;
    border-top: 2px solid #e9e9e9 !important;
    border-top-left-radius: 12px;
    margin-left: 6px !important;
    position: relative;
    right: -1px;
    text-align: right;
}

.vertab:hover {
    background-color: #f7f9fca8;
    cursor: pointer !important;
    text-decoration: underline !important;
}

.vertabactive {
    background-color: #f7f9fc;
    border-bottom: 2px solid #d7d5d5;
    border-bottom-left-radius: 12px;
    border-left: 2px solid #d7d5d5;
    border-right: none;
    border-top: 2px solid #d7d5d5 !important;
    border-top-left-radius: 12px;
    position: relative !important;
    right: -1px !important;
    text-align: right;
    z-index: 1000;
}

.vertical-middle {
    vertical-align: middle !important;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.viewport {
    background-color: white;
    border-radius: 6px;
    margin-left: 15px;
    margin-right: 20px;
    padding-bottom: 20px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 20px;
}

.w-10 {
    height: 100% !important;
    max-width: 170px !important;
    width: 170px !important;
}

.w-50 {
    width: 50%;
}

.w-90 {
    width: 85%;
}

.w-100 {
    width: 100%;
}

.wc-header {
    background-color: rgb(166, 202, 252) !important;
    fill: rgb(166, 202, 252) !important;
}

.whitebackground {
    background-color: white;
}

.whitecolor {
    color: white;
}

.whitelink {
    color: #060948 !important;
}

.whitelink:hover {
    color: black;
    text-decoration: underline !important;
}

.yesno {
    min-width: 15em !important;
    width: 15em !important;
}

@keyframes animate {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1.0;
    }

    70% {
        opacity: 0.0;
    }

    80% {
        opacity: 0.6;
    }

    90% {
        opacity: 0.6;
    }
}

@keyframes osc {
    0% {
        opacity: 0.0;
    }

    100% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }
}

@keyframes pulse {
    50% {
        opacity: 0;
    }
}

@keyframes round {
    0% {
        transform: rotate(0deg);
        webkit-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        webkit-transform: rotate(360deg);
    }
}

@keyframes wshake {
    0% {
        transform: rotate(0deg);
        webkit-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        webkit-transform: rotate(360deg);
    }
}

.hideOnDesktop {
    display: none !important;
}

.aAsLink {
    color: #325CEB !important;
    cursor: pointer;
}

@media (max-width: 500px) {
    .hideOnMobile {
        display: none;
    }

    .hideOnDesktop {
        display: block !important;
    }

    .frontimage {
        margin-top: 0px;
        width: 70%;
    }

    .loancardmobile {
        display: flex;
        justify-content: space-between;
    }

    .ratesfilterDropdown {
        font-size: 14px !important;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }

}

@media (max-width: 500px) {
    .high-cost-modal {
        max-width: 450px !important;
        width: 90vw !important;
    }
}

@media (max-width: 500px) {
    .calcChart {
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 900px) {
    .calcChart {
        height: 85%;
        width: 85%;
    }
}

@media (max-width: 900px) {
    #firstpage {
        background-image: url("./landingbackground.png");
        background-size: 900px;
    }
}

@media (min-width: 900px) {
    .high-cost-modal {
        max-width: 700px !important;
        width: 700px !important;
    }
}

@media (min-width: 900px) {
    .myrow {
        align-items: stretch !important;
        display: flex !important;
    }
}

@media all and (max-width: 1200px) {
    .hideonphone1200 {
        display: none !important;
    }
}

@media all and (max-width: 480px) {
    .scaledimage {
        height: auto;
        min-width: 300px;
        width: 100%;
    }
}

@media all and (max-width: 520px) {
    .logo {
        margin-left: 0px !important;
        padding-left: 0px !important;
    }
}

@media all and (max-width: 520px) {
    .backimage {
        background-position: bottom 0px right 0px;
        background-size: 100%;
    }
}

@media all and (max-width: 520px) {
    .backimage_ {
        background-position: bottom 0px right 0px;
        background-size: 100%;
    }
}

@media all and (max-width: 520px) {
    .hideonphone {
        display: none !important;
    }
}

@media all and (max-width: 520px) {
    .rightmobile {
        padding-right: 4px !important;
    }
}

@media all and (max-width: 520px) {
    .viewport {
        margin-left: 6px;
        margin-right: 6px;
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
}

@media all and (max-width: 520px) {
    .incard {
        margin-bottom: 1px;
        margin-top: 1px;
        padding-bottom: 10px;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-top: 4px;
    }

    .incardRow {

        display: block !important;

    }

    .incardCol {
        width: 100% !important;
    }

    .incard .incardField-mobileAdjuster {
        padding-left: 0px;
    }
}

@media all and (max-width: 700px) {
    .hideonphone700 {
        display: none !important;
    }

    .hidesmall {
        display: none;
    }

    .mobilewide {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .mpt-2 {
        padding-top: 0em !important;
    }

    .mpt-4 {
        padding-top: 0em !important;
    }
}

@media all and (max-width: 700px) {
    .toolwrap {
        display: block;
    }

    .vertab {
        border-bottom-right-radius: 12px;
        border-right: 2px solid #e9e9e9;
        border-top-right-radius: 12px;
        margin-right: 16px !important;
        right: 0px;
        text-align: center;
        width: 50%;
    }

    .vertabactive {
        border-bottom-right-radius: 12px;
        border-right: 2px solid #e9e9e9;
        border-top-right-radius: 12px;
        margin-right: 16px !important;
        right: 0px;
        text-align: center;
        width: 50%;
    }
}

@media all and (max-width: 800px) {
    .tagline {
        font-size: 1.9em !important;
        font-weight: 500 !important;
        margin-bottom: 0px;
        margin-top: 10px !important;
        padding-bottom: 0px;
    }
}

@media all and (max-width: 800px) {
    .viewport {
        margin-left: 10px;
        margin-right: 10px;
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}

@media all and (min-width: 480px) {
    .Login {}

    .Login form {
        margin: 0 auto;
    }
}

form {
    display: contents;
}

@-webkit-keyframes osc {
    0% {
        opacity: 0.0;
    }

    100% {
        opacity: 0.0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }
}

@-webkit-keyframes round {
    0% {
        transform: rotate(0deg);
        webkit-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes rouwshakend {
    0% {
        transform: rotate(0deg);
        webkit-transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
        webkit-transform: rotate(360deg);
    }
}

body {
    height: 100% !important;
    margin-bottom: 0px;
    padding-bottom: 0px !important;
}

h1 {
    color: var(--primary-color-2) !important;
    font-family: Inter !important;
    font-size: 38px !important;
    font-weight: bold !important;
}

h2 {
    color: var(--primary-color-2) !important;
    font-family: Inter !important;
    font-size: 26px !important;
    font-weight: bold !important;
}

h3 {
    color: var(--primary-color-2) !important;
    font-family: Inter !important;
    font-size: 26px !important;
    font-weight: bold !important;
}

h4 {
    color: var(--primary-color-2) !important;
    font-family: Inter !important;
    font-size: 24px !important;
    font-weight: bold !important;
}

h5 {
    font-family: Inter !important;
    font-size: 22px !important;
    font-weight: normal !important;
}

html {
    min-height: 100% !important;
}

input.error {
    border-color: red;
}

input:checked+.slider {
    background-color: #215ef4;
}

input:checked+.slider:before {
    ms-transform: translateX(26px);
    transform: translateX(26px);
    webkit-transform: translateX(26px);
}

input:focus+.slider {
    box-shadow: 0 0 1px #215ef4;
}

input[type="checkbox"]:required:invalid~label.in-valid {
    color: red;
}

input[type="radio"]:required:invalid~label.in-valid {
    color: red;
}

p.App {
    height: 100%;
    text-align: center;
}

#subjectloans td,
th {
    padding-top: 2px;
    padding-left: 12px;
    padding-right: 12px;
}

#subjectloans th {
    background-color: #ededed;
}

#subjectloans tr:nth-child(even) {
    background-color: #fafafa;
}

.secondarycard {
    background-color: rgba(206, 216, 230, 0.5) !important;
    border-radius: 6px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 20px;
    padding-bottom: 10px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 10px;
}

.btn-outline-primary {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
}

.btn-outline-primary:hover {
    background-color: var(--primary-color-2) !important;
    border-color: var(--primary-color) !important;
    color: var(--primary-color-2-text) !important;
}

.pale-background {
    background-color: var(--primary-pale) !important;
}

.affordability-pale-background {
    background-color: rgb(163, 163, 163);
    background: linear-gradient(to right, #00BE63, #00BE63 57%, #FFAA29 57%, #FFAA29 80%, #FF2929 80%, #FF2929);
}

select.form-control.form-control-sm:focus,
input.form-control:focus,
input.form-control.form-control-sm:focus {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 5px var(--primary-color-3) !important;
    moz-box-shadow: 0 0 5px var(--primary-color-3) !important;
    webkit-box-shadow: 0 0 5px var(--primary-color-3) !important;
}

.nav-tabs .nav-link {
    color: var(--primary-color) !important;
}

#pane {
    padding-right: 10px;
}



.carousel__slide-focus-ring {
    border: none;
    outline: none !important;
}

.iframe-wrap {
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
}


@media (max-width: 480px) {
    #pane {
        background-color: white;
    }
}

.infoTip {
    background-color: #EAEFFD;
    color: #1F3988;
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;
    padding: 10px 20px 10px 20px;
}


.tooltip.show {
    opacity: 1 !important;
}

.input-group-text {
    background-color: white !important;
    /* border-radius: 0.25rem 0 0 0.25rem !important; */
}

.btn-inputgroup-dropdown {
    background-color: #325CEB !important;
    border-radius: 0 0.25rem 0.25rem 0 !important
}

.ant-picker-outlined {
    border-radius: 6px;
    height: 44px;
    border: 1px solid #ECECEC;
}

.ant-input-group-wrapper-outlined .ant-input-group-addon {
    background-color: none !important;
}

.ant-input-affix-wrapper {
    background: #FCFCFC;
    border-radius: 4px;
    height: 44px;
}

.ant-input {
    border-radius: 4px;
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    border-color: #C21818 !important;
}

.ant-btn-primary {
    background-color: #325CEB !important;
    border-radius: 4px !important;
}
.ant-btn-primary:disabled {
    background-color: #d9d9d9 !important;
}

.ant-btn-outline {
    border: 1px solid #325CEB !important;
    color: #325CEB !important;
    border-radius: 4px !important;
}

.ant-btn-default {
    border-radius: 4px !important;
}

.welcomeMessage {
    font-size: 36px;
    font-weight: 500;
    font-family: "Playfair Display" !important;
}

.myloans {
    background-color: white;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    min-height: 150px;
    /* padding: 15px; */
    /* margin-right: 20px; */
}

.applicationLabel {
    background: #E9F4EB;
    color: #2E6333;
    width: 150px;
    border-bottom-right-radius: 4px;
    padding: 3px 4px;
    text-align: center;
}

.chosenRatesLabel {
    background: #E9F4EB;
    color: #2E6333;
    width: 150px;
    font-weight: 500 !important;
    border-bottom-right-radius: 4px;
    padding: 3px 4px 3px 15px;
    /* border-bottom: 1px solid #2E6333; */
    /* border-right: 1px solid #2E6333; */
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;

}

.title-2 {
    font-size: 24px !important;
    color: #6E6E70 !important;
}

.title-4 {
    font-size: 20px !important;
    white-space: normal;
    color: #6E6E70;
}

.title-5 {
    font-size: 14px !important;
    white-space: normal;
    color: #6E6E70;
}

.header-1 {
    font-family: "Playfair Display" !important;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
}

.with-border {
    border: 1px solid #DBDCDE;
    border-radius: 6px;
}

.headline-small {
    font-size: 20px !important;
    font-weight: 500;
}

.fw-500 {
    font-weight: 500 !important;
}

.formLabel {
    color: #586570;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
    white-space: normal;
}

.fs-16 {
    font-size: 16px !important;
}

.greyBackground {
    background-color: #F5F5F7;
    border-radius: 6px;
    padding: 14px 24px;
}

.taskTips .ant-alert {
    border-radius: 4px;
    background-color: #E9F0FD;
    border: 1px solid #E9F0FD;
    font-size: 16px;
    color: #1F3988;
    font-weight: 600;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #325CEB;
}

.ant-btn-form1003AddRow {
    background: #EBF1FA;
    text-align: center;
    border-radius: 0 0 4px 4px;
    color: #325CEB;
    font-weight: 500;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
    min-height: 32px;
    white-space: nowrap;
    /* 不换行 */
    overflow: hidden;
    /* 超出隐藏 */
    text-overflow: ellipsis;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.editable-row {
    /* background-color: #FCFCFC; */
    border: 1px solid #ECECEC;
}

.editable-row .ant-table-cell-fix-right {
    background-color: #FFF !important;
}

.incomeSourcesTable {
    width: 100%;
}

.incomeSourcesTable .ant-table-thead>tr>th {
    background: #F5F5F7 !important;
    border: 1px solid #ECECEC;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #325CEB;
    border-color: #325CEB;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #325CEB;
}
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
    background-color: #325CEB;
}
.ant-select-single {
    height: 44px !important;
}

.ant-select-selector {
    height: 44px !important;
}

/* .ant-select-single .ant-select-selector {
    border-radius: 4px;
}

.ant-select-single.ant-select-lg .ant-select-selector {
    border-radius: 4px;
} */

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #ECECEC;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 40px;
}

.ant-input-number-outlined:hover {
    border-color: #000 !important;
}

.ant-input-number-outlined:focus-within {
    border-color: #325CEB !important;
}

.ant-input-outlined:focus-within {
    border-color: #325CEB !important;
}

/* .ant-input-number-outlined:focus:hover {
    border-color: #325CEB !important;
} */
/*
.ant-picker-outlined:focus:hover {
    border-color: #325CEB !important;

} */

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {

    border-color: #222;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):focus-within .ant-select-selector {
    border-color: #325CEB !important;
}

.ant-picker:hover {
    border-color: #222 !important;
}

/* .ant-picker:focus:hover{
    border-color: red !important;
} */

.ant-select-single.ant-select-lg .ant-select-selector {

    border-radius: 6px !important;
}

.ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg .ant-select-selector .ant-select-selection-placeholder {
    line-height: 42px;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #325CEB;
    border-color: #325CEB;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    height: 24px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

input:-internal-autofill-selected,
input:-internal-autofill-selected:hover,
input:-internal-autofill-selected:focus,
input:-internal-autofill-selected:active {
    transition: background-color 5000s ease-in-out 0s;
}