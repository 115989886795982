.btn-task-desc {
    height: 44px;
    border: 1px solid #ced4da !important;
    /* border-radius: 2px !important;
	color: #325CEB !important;
	font-size: 16px !important;	 */
}

.react-datepicker__input-container input {
    border: 1px solid #ced4da !important;
}

.currentChecklistWrap {
    padding: 0 15px 15px 15px;
}

.document-checklist::-webkit-scrollbar {
    display: none;
}

.currentChecklistWrap .ant-spin-nested-loading,
.currentChecklistWrap .ant-spin-nested-loading .ant-spin-container {
    height: 100%;
}

.currentChecklistWrap .ant-table .ant-table-thead .ant-table-cell-fix-right {
    background-color: #f5f5f7 !important;
    z-index: 1;
}

.currentChecklistWrap .ant-table .ant-table-tbody .ant-table-cell-fix-right {
    background-color: #fff !important;
    z-index: 1;
}

.currentChecklistWrap .checklist-title {
    font-family: Inter;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}

.currentChecklistWrap .checklist-title .checklist-title-l {
    font-size: 20px;
    color: #222;
    font-weight: 600;
}

.currentChecklistWrap .checklist-title .checklist-title-r {
    color: #1630ac;
    font-weight: 500;
    height: 30px;
    padding: 0 10px;
    border-radius: 15px;
    background-color: #e1f2e2;
    display: flex;
    align-items: center;
    gap: 10px;
}

.currentChecklistWrap .add-document-bar {
    display: flex;
    justify-content: space-between;
}

.currentChecklistWrap .add-document-bar .document-switch {
    display: flex;
    align-items: center;
    color: #325ceb;
    font-size: 13px;
    font-weight: 500;
    gap: 10px;
}

.currentChecklistWrap
    .add-document-bar
    .document-switch
    .document-aciton.ant-switch-checked {
    background-color: #325ceb;
}

.currentChecklistWrap .add-document-bar .action-bar {
    display: flex;
    gap: 12px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
}

.currentChecklistWrap .add-document-bar .action-bar .filter-btn {
    color: #325ceb;
    display: flex;
    height: 45px;
    width: 104px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    background-color: #f7f8ff;
    cursor: pointer;
}

.currentChecklistWrap .add-document-bar .action-bar .filter-btn.filter-disable {
    background-color: #dbdcde;
    color: #6e6e70;
    cursor: no-drop;
}

.currentChecklistWrap .add-document-bar .action-bar .upload-documents-btn {
    color: #fff;
    display: flex;
    height: 45px;
    width: 154px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: #325ceb;
    cursor: pointer;
}

.currentChecklistWrap .add-document-bar .action-bar .filter-btn.disable {
    color: #6e6e70;
    background-color: #dbdcde;
}

.currentChecklistWrap
    .add-document-bar
    .action-bar
    .category-select
    .ant-select-selector {
    border-radius: 6px;
    border: 1px solid #ececec;
    background: #fcfcfc;
}

.currentChecklistWrap
    .add-document-bar
    .action-bar
    .category-select
    .ant-select-selector
    .ant-select-selection-placeholder {
    color: #000;
}

.currentChecklistWrap .document-checklist {
    margin-top: 12px;
    flex: 1;
    overflow: auto;
}

.currentChecklistWrap .document-checklist .document-checklist-item {
    height: 43px;
    padding: 0 32px 0 48px;
    border-radius: 8px;
    border: 1px solid #dbdcde;
    background-color: #f5f5f7;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Inter;
    font-size: 14px;
}

.checklist-item-title {
    font-family: Inter;
    color: #222;
    font-weight: 600;
}

.checklist-item-num {
    color: #545459;
    font-weight: 500;
}

.document-table-wrap {
    border: 1px solid #dbdcde;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 12px;
    transition: all 0.3s;
}

.document-table-wrap.expand {
    height: 44px;
}

.document-table-wrap.uncategorized-expand {
    height: 51px;
}

.table-wrap .document-request .ant-table-tbody > tr > td {
    padding: 16px 0 16px 10px;
}

.table-wrap .document-request .borrower-doc-select .ant-select-selector {
    border: 1px solid #325ceb;
}

.table-wrap .document-request .borrower-doc-input {
    border: 1px solid #325ceb;
}

.document-table-wrap .document-table .ant-table-thead > tr > .ant-table-cell {
    padding: 7px 16px;
    background-color: #f5f5f7;
}

.document-table-wrap .document-table .ant-table-tbody > tr > td {
    border-bottom: none;
    background: none;
    border-top: 1px solid #dbdcde;
}

.document-table-wrap
    .document-table
    .ant-table-tbody
    .ant-table-row.hidden-document {
    display: none;
}

.document-table-wrap
    .document-table
    .ant-table-tbody
    .ant-table-row
    .ant-table-row-expand-icon-cell {
    border-left: 4px solid #fff;
}

.document-table-wrap
    .document-table
    .ant-table-tbody
    .ant-table-row.resubmit-document
    .ant-table-row-expand-icon-cell {
    border-left: 4px solid #ffc01e;
}

.document-table-wrap
    .document-table
    .ant-table-tbody
    .ant-table-row.overdue-document
    .ant-table-row-expand-icon-cell {
    border-left: 4px solid #c90000;
}

.document-table-wrap .document-table .ant-table-expanded-row {
    background-color: #f5f5f7;
}

.document-table-wrap .ant-table-expanded-row > .ant-table-cell {
    padding: 0;
}

.document-table-wrap .document-table .ant-table-expanded-row .ant-table {
    margin-block: 0 !important;
    margin-inline: 0 !important;
}

.document-table-wrap
    .document-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr
    > td {
    background-color: #f5f5f7 !important;
    padding: 0 16px;
    height: 54px;
}

.document-table-wrap
    .document-table
    .ant-table-expanded-row
    .ant-table-tbody
    > tr:first-child
    > td {
    border-top: none;
}

.document-table-wrap
    .document-table.uncategorized-doc
    .ant-table-thead
    > tr
    > .ant-table-cell {
    background-color: #f2f7ff !important;
}

.document-table-wrap
    .document-table
    .ant-table-tbody
    .ant-table-row
    > .ant-table-cell-row-hover {
    background: #fff;
}

.document-table-wrap .document-table .document-header-name {
    color: #222;
    font-weight: 600;
    margin-left: -16px;
}

.document-table-wrap .document-table .document-name-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
}

.document-table-wrap .document-table .document-name {
    color: #222;
    font-family: Inter;
    font-weight: 500;
    line-height: 20px;
    margin-left: -16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.document-table-wrap .document-table .document-name-wrap .document-name:hover {
    color: #325ceb;
    cursor: pointer;
}

.document-table-wrap .document-table .doc-processing {
    display: flex;
    align-items: center;
    gap: 16px;
}
.document-table-wrap .document-table .doc-processing .processing-text {
    color: #545459;
    font-family: Inter;
}
.document-table-wrap .document-table .document-status {
    padding: 5px 10px;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
    border-radius: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: default;
    gap: 5px;
}
.document-table-wrap .document-table .document-filename {
    color: #545459;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.document-table-wrap .document-table .document-filename:hover {
    font-weight: 500;
    color: #325ceb;
    cursor: pointer;
}
.document-table-wrap .document-table .document-action-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 14px;
}
.document-table-wrap .document-table .ant-table-column-sorters {
    justify-content: initial;
}
.document-table-wrap .document-table .ant-table-column-sorter {
    margin-inline-start: 8px;
}
.document-table-wrap .document-table .ant-table-column-title {
    flex: none;
}
.document-table-wrap .document-table .document-action-wrap .document-view {
    font-family: Inter;
    font-weight: 500;
    width: 80px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
}
.document-table-wrap
    .document-table
    .document-action-wrap
    .document-view.view-all {
    margin-right: 7px;
}
.document-table-wrap .document-table .collapse-header-arrow {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}
.document-table-wrap .document-table .collapse-header-arrow .down,
.document-table-wrap
    .document-table
    .document-action-wrap.uncategorized-wrap
    .down {
    transform: rotate(-180deg);
    transition: all 0.3s;
}
.document-table-wrap .document-table .collapse-header-arrow .up,
.document-table-wrap
    .document-table
    .document-action-wrap.uncategorized-wrap
    .up {
    transform: rotate(0);
    transition: all 0.3s;
}

.document-checklist-dropdown .ant-dropdown-menu {
    background-color: #f6f6f6;
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 4px;
}
.document-checklist-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    color: #545459;
    font-weight: 500;
}
.document-checklist-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-icon {
    margin-inline-end: 10px;
}
.document-table-wrap .document-table .document-action-wrap .document-more {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}
.document-table-wrap
    .document-table
    .document-action-wrap
    .document-more:hover {
    background-color: rgba(162, 162, 162, 0.1);
}
.document-table-wrap .document-table .document-action-wrap .document-more img {
    width: 100%;
    height: 100%;
}
.currentChecklistWrap .task-topBar {
    display: flex;
    justify-content: space-between;
}
.currentChecklistWrap .task-topBar .search-bar {
    display: flex;
    margin-bottom: 20px;
}
.currentChecklistWrap .task-topBar .search-bar .statusFilters {
    height: 42px;
    min-width: 100px;
}
.currentChecklistWrap .task-topBar .search-bar .search-task {
    height: 42px;
}
.currentChecklistWrap .task-topBar .create-task {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid #e3eafd;
    background-color: #f7f8ff;
    color: #325ceb;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    height: 42px;
    padding: 0 20px;
    cursor: pointer;
    margin-bottom: 20px;
}
.currentChecklistWrap .no-tasks {
    font-family: Inter;
    height: 236px;
    padding: 40px 0;
    border-radius: 8px;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.currentChecklistWrap .no-tasks img {
    width: 104px;
    height: auto;
}
.currentChecklistWrap .no-tasks .title-text {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
}
.currentChecklistWrap .no-tasks .sub-text {
    color: #6e6e70;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse-header {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 16px;
    color: #222;
    line-height: 20px;
}
.currentChecklistWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-type {
    width: 25%;
    font-size: 16px;
    font-weight: 500;
    color: #222;
}
.currentChecklistWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-update {
    width: 35%;
}
.currentChecklistWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-completed {
    display: flex;
    align-items: center;
    font-weight: 500;
    width: 25%;
}
.currentChecklistWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-completed
    .task-completed-num {
    margin-left: 8px;
}
.currentChecklistWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .no-tasks-text {
    color: #6e6e70;
}
.currentChecklistWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-add {
    color: #325ceb;
    font-weight: 600;
    cursor: pointer;
    width: 15%;
    text-align: right;
    padding-right: 10px;
}
.currentChecklistWrap .task-content .task-collapse {
    background-color: #fff;
    border: none;
}
.currentChecklistWrap .task-content .task-collapse .task-collapse {
    background-color: #fff;
}
.task-collapse > .ant-collapse-item {
    border: 1px solid #dbdcde;
    transition: all 0.3s;
    margin-bottom: 12px;
    border-radius: 8px !important;
}
.task-collapse > .ant-collapse-item:hover {
    box-shadow: 0px 4px 8px 0px rgba(7, 13, 61, 0.12);
}

.task-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 18px 16px;
    align-items: center;
}
.task-collapse .ant-collapse-content {
    /* padding: 12px 0 22px; */
    margin: 0 16px;
}

.create-task-modal .ant-modal-content {
    padding: 15px 0 0;
}
.create-task-modal .ant-modal-close {
    right: 42px;
    top: 42px;
}
.create-task-modal .task-modal-wrap {
    font-family: Inter;
    height: 100%;
}
.create-task-modal .task-modal-wrap .ant-spin-nested-loading {
    height: 100%;
}
.create-task-modal .task-modal-wrap .ant-spin-nested-loading .task-spin {
    max-height: 100%;
}
.ant-spin-nested-loading > div > .task-spin .ant-spin-text {
    padding-top: 26px;
    color: #6e6e70;
    font-size: 16px;
}
.create-task-modal .task-modal-wrap .create-task-title {
    color: #222;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    padding: 26px 42px;
    border-bottom: 1px solid #dbdcde;
}
.create-task-modal .task-modal-wrap .task-form {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.create-task-modal .task-modal-wrap .create-task-content {
    padding: 0 42px;
    padding-top: 45px;
    overflow: auto;
}
.create-task-modal .task-modal-wrap .create-task-content .auto-generate-btn {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    height: 60px;
    padding: 0 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #325ceb;
    background-color: #325ceb;
    cursor: pointer;
    margin: 62px auto 24px;
}
.create-task-modal .task-modal-wrap .create-task-content .manually-add {
    color: #325ceb;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
    display: inline-flex;
}
.create-task-modal .task-modal-wrap .create-task-content .select-file {
    display: inline-flex;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    padding: 13px 33px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #325ceb;
    background: #325ceb;
    cursor: pointer;
}
.create-task-modal .task-modal-wrap .create-task-content .file-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .file-list
    .upload-doc-item {
    color: #000;
    font-weight: 500;
    font-family: Inter;
    height: 45px;
    font-size: 16px;
    padding: 0 10px 0 15px;
    align-items: center;
    gap: 25px;
    border-radius: 10px;
    border: 1px solid #dbdcde;
    display: flex;
    align-items: center;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .file-list
    .upload-doc-item
    > div {
    display: flex;
    align-items: center;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .file-list
    .upload-doc-item
    .pdf-icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .file-list
    .upload-doc-item
    .delete-doc {
    cursor: pointer;
}
.create-task-modal .task-modal-wrap .create-task-content .upload-tips {
    color: #545459;
    font-size: 12px;
    line-height: 20px;
    margin: 6px 0 46px;
}
.create-task-modal .task-modal-wrap .create-task-content .form-item-label {
    padding: 8px 0;
    color: #586570;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
}
.create-task-modal .task-modal-wrap .create-task-content .document-input {
    background-color: #fcfcfc;
    border: 1px solid #ececec;
    font-size: 16px;
    border-radius: 6px;
}
.create-task-modal .task-modal-wrap .create-task-content .edit-wrap {
    font-size: 16px;
    color: #222;
    min-height: 52px;
    display: flex;
    align-items: center;
    padding-left: 20px;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-select
    .ant-select-selector {
    padding: 6px;
    height: 52px;
    background-color: #fcfcfc;
    border: 1px solid #ececec;
    font-size: 16px;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-select.ant-select-single {
    height: 52px;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-select.ant-select-single
    .ant-select-selector {
    padding: 0 20px;
    font-size: 16px;
    color: #222;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 50px;
}
.create-task-modal .task-modal-wrap .create-task-content .task-date {
    height: 52px;
    padding: 4px 20px 4px;
    background-color: #fcfcfc;
    border: 1px solid #ececec;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-date
    .ant-picker-input
    > input {
    font-size: 16px;
    color: #222;
}
.create-task-modal .task-modal-wrap .create-task-content .checkbox-title {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 2px;
}
.create-task-modal .task-modal-wrap .create-task-content .checkbox-subtitle {
    color: #6e6e70;
    font-family: Inter;
    font-size: 13px;
    line-height: normal;
    padding-left: 30px;
}
.create-task-modal .ant-modal-footer {
    height: 72px;
    padding: 0 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ececec;
    margin-top: 20px;
}
.create-task-modal .ant-modal-footer .task-btn-cancel {
    height: 42px;
    font-size: 16px;
    font-weight: 600;
    padding: 0 35px;
}
.create-task-modal .ant-modal-footer .task-btn-save {
    background-color: #325ceb;
    color: #fff;
    height: 42px;
    font-size: 16px;
    font-weight: 600;
    padding: 0 20px;
}
.create-task-modal .ant-modal-footer .task-btn-save:disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.04);
}
.ant-message .ant-message-notice.task-message .ant-message-notice-content {
    font-size: 16px;
    color: #222;
    background-color: #f7f8ff;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.18);
}
.ant-message
    .ant-message-notice.task-message
    .ant-message-notice-content
    .ant-message-custom-content {
    display: flex;
}
.ant-message
    .ant-message-notice.task-message
    .ant-message-notice-content
    .ant-message-custom-content
    .anticon
    svg {
    color: #1630ac;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .table-wrap {
    border: 1px solid #ececec;
    border-radius: 8px;
    overflow: hidden;
}
.table-wrap .document-table .message-add {
    width: 74px;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #e3eafd;
    background-color: #f7f8ff;
    font-family: Inter;
    font-size: 14px;
    color: #325ceb;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.table-wrap .document-table .ant-table-thead {
    background-color: #f5f5f7;
}
.table-wrap .document-table .ant-table-cell-fix-right.ant-table-cell {
    background-color: #fff !important;
}
.table-wrap
    .document-table
    .ant-table-thead
    .ant-table-cell-fix-right.ant-table-cell {
    background-color: #f5f5f7 !important;
}
.table-wrap .ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    padding: 10px 16px;
}
.table-wrap.ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
}
.table-wrap .document-table .document-save {
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    width: 72px;
    height: 40px;
    padding: 8px 15px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #325ceb;
    background-color: #325ceb;
    cursor: pointer;
}
.table-wrap .table-document {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    font-family: Inter;
}
.table-wrap .table-date {
    color: #222;
    font-size: 16px;
    text-decoration-line: underline;
    font-family: Inter;
    cursor: pointer;
}
.table-wrap .table-message {
    color: #4f4f4f;
    font-family: Inter;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: end;
}
.table-wrap .message-text {
    width: calc(100% - 30px);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-wrap .msg-content {
    padding-left: 6px;
    color: #222;
}
.table-wrap .table-message .message-edit {
    color: #325ceb;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}
.table-wrap .document-name-wrap {
    display: flex;
    align-items: center;
}
.table-wrap .document-name-wrap .table-document-file {
    width: 150px;
    height: 36px;
    border-radius: 4px;
    border: 1px solid #dbdcde;
    background-color: #fcfcfc;
    margin-left: 12px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    color: #222;
    cursor: pointer;
}
.table-wrap .document-name-wrap .table-document-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-wrap .document-name-wrap .table-document-file img {
    width: 22px;
    height: 22px;
    margin-right: 8px;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-document-btn {
    color: #325ceb;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: 22px 0;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-document {
    border-radius: 10px;
    border: 1px solid #ececec;
    margin-top: 26px;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-document
    .add-document-title {
    color: #222;
    font-size: 16px;
    font-weight: 500;
    padding: 0 24px;
    line-height: 20px;
    height: 54px;
    border-bottom: 1px solid #ececec;
    display: flex;
    align-items: center;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-document
    .add-document-content {
    padding: 18px 30px 20px 24px;
}
.create-task-modal .task-modal-wrap .ant-spin-container {
    height: 100%;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-document
    .add-document-operate {
    height: 72px;
    padding: 0 30px 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ececec;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-document
    .add-document-operate
    .add-document-cancel {
    height: 44px;
    width: 111px;
    font-size: 16px;
    font-weight: 600;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-document
    .add-document-operate
    .add-document-save {
    height: 44px;
    width: 111px;
    font-size: 16px;
    font-weight: 600;
    border: 1px solid #325ceb;
    color: #325ceb;
    background-color: #eaeffd;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-document
    .add-document-content
    .add-document-label {
    color: #586570;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .table-wrap
    .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-more-btn {
    height: auto;
    padding: 15px 34px;
    color: #325ceb;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}
.create-task-modal
    .task-modal-wrap
    .create-task-content
    .task-documents
    .add-more-btn:disabled {
    color: #979797;
}
.task-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 0;
}
.viewfiles-top-bar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    padding: 0 22px 0 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.viewfiles-bottom-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
}
.viewfiles-prevPage {
    position: fixed;
    left: 26px;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
}
.viewfiles-nextPage {
    position: fixed;
    right: 26px;
    top: 50%;
    margin-top: -35px;
    cursor: pointer;
}
.viewfiles-top-bar > div {
    display: flex;
    align-items: center;
}
.viewfiles-top-bar .close-icon {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    margin-right: 20px;
}
.viewfiles-top-bar .viewfiles-top-name {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 10px;
}
.viewfiles-top-bar .page-info {
    position: absolute;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    left: 50%;
    margin-left: -50px;
    justify-content: center;
}
.viewFiles-modal .viewFiles-content {
    max-height: calc(100vh - 160px);
    /* max-width: 1280px; */
    overflow: auto;
}
.viewFiles-modal .viewFiles-modal-btn {
    width: 135px;
    height: 44px;
    font-weight: 500;
    border-radius: 4px;
    padding: 0;
}
.success-wrap {
    padding: 64px 64px 120px;
    text-align: center;
}
.success-wrap img {
    height: 124px;
    width: 102px;
    margin-bottom: 32px;
}
.success-wrap .success-title {
    color: #222;
    font-family: 'Playfair Display';
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 12px;
}
.success-wrap .success-subtitle {
    color: #6e6e70;
    font-family: Inter;
    font-size: 16px;
    line-height: 22px;
}
.success-wrap .success-subtitle .email {
    color: #222;
    font-weight: 500;
    text-decoration-line: underline;
}
.signature-modal .ant-modal-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0;
}
.signature-modal .ant-modal-content .ant-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-top {
    font-size: 18px;
    color: #222;
    font-weight: 500;
    height: 60px;
    border-bottom: 1px solid #dbdcde;
    display: flex;
    align-items: center;
    padding: 0 42px;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document-wrap {
    flex: 1;
    background-color: #e8e8eb;
    position: relative;
    overflow: hidden;
}
.signature-modal .ant-modal-content .ant-modal-body .signature-document {
    height: 100%;
    overflow: auto;
    display: flex;
}
.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-document-wrap
    .document-pdf-zoom {
    width: 110px;
    height: 30px;
    border-radius: 6px;
    border: 1px solid #cfd4d9;
    display: flex;
    background-color: #fff;
    position: absolute;
    right: 42px;
    bottom: 10px;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.15));
}
.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-document-wrap
    .document-pdf-zoom
    .zoom-min,
.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-document-wrap
    .document-pdf-zoom
    .zoom-add {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6e6e70;
    font-weight: bold;
}

.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-document-wrap
    .document-pdf-zoom
    .zoom-value {
    color: #222;
    font-size: 14px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #cfd4d9;
    border-right: 1px solid #cfd4d9;
}

.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-document
    .signature-document-pdf {
    margin: 0 auto;
}

.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-document
    .signature-document-pdf
    .rectangle-sign {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(50, 92, 235, 0.5);
}

.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-document
    .signature-document-pdf
    .rectangle-sign:hover {
    border: 1px solid #325ceb;
}

.signature-modal .ant-modal-content .ant-modal-body .signature-bar {
    border-top: 1px solid #dbdcde;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 42px;
}
.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-bar
    .signature-bar-btn {
    display: flex;
    align-items: center;
    justify-content: end;
}
.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-bar
    .signature-bar-btn
    .signatures-num {
    font-size: 16px;
    color: #6e6e70;
    margin-right: 16px;
}
.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-bar
    .signature-bar-btn
    .add-signature {
    font-size: 16px;
    font-weight: 600;
    height: 46px;
    width: 90px;
    color: #325ceb;
    background-color: #eaeffd;
    border-color: #325ceb;
}
.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-bar
    .signature-bar-btn
    .divider {
    width: 1px;
    height: 46px;
    background-color: #e8e8eb;
    margin: 0 16px;
}
.signature-modal
    .ant-modal-content
    .ant-modal-body
    .signature-bar
    .signature-bar-btn
    .save-signature {
    font-size: 16px;
    font-weight: 600;
    height: 46px;
    padding: 0 32px;
}
.document-checklist-modal.action-modal .ant-modal-content {
    padding: 100px 82px;
}
.document-checklist-modal.delete-modal .ant-modal-content {
    padding: 66px 68px 48px;
}
.document-checklist-modal.delete-modal .delete-wrap .delete-title {
    color: #222;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.document-checklist-modal.delete-modal .delete-wrap .delete-content {
    color: #222;
    font-family: Inter;
    font-size: 16px;
    line-height: 24px;
    margin: 42px 0;
}
.document-checklist-modal.delete-modal .ant-modal-content .ant-modal-footer {
    display: flex;
    gap: 30px;
    justify-content: center;
}
.document-checklist-modal.delete-modal
    .ant-modal-content
    .ant-modal-footer
    .doc-btn-cancel {
    font-size: 16px;
    color: #325ceb;
    font-weight: 500;
    width: 128px;
    height: 45px;
    border-radius: 6px;
    border: 1px solid #325ceb;
}
.document-checklist-modal.delete-modal
    .ant-modal-content
    .ant-modal-footer
    .doc-btn-confirm {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    width: 128px;
    height: 45px;
    border-radius: 6px;
    background-color: #325ceb;
}
.document-checklist-modal .btn-tips {
    color: #545459;
    font-family: Inter;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
}
.document-checklist-modal .document-checklist-btn {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    height: 60px;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    border: 1px solid #325ceb;
    cursor: pointer;
}
.document-checklist-modal .document-checklist-btn.add {
    color: #fff;
    background-color: #325ceb;
}
.document-checklist-modal .document-checklist-btn.upload {
    color: #325ceb;
}
.document-checklist-modal
    .document-divider.ant-divider-horizontal.ant-divider-with-text {
    margin: 32px 0;
}
.document-filter-content {
    border-radius: 10px;
    border: 1px solid #f6f6f6;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 506px;
    max-width: 70vw;
}
.document-filter-content .filter-title {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: Inter;
    border-bottom: 1px solid #f6f6f6;
}
.document-filter-content .filter-title span {
    color: #222;
    font-weight: 600;
}
.document-filter-content .filter-title .close-filter {
    font-weight: bold;
    color: #325ceb;
    cursor: pointer;
    position: absolute;
    right: 18px;
}
.document-filter-content .filter-checkbox-wrap {
    font-family: Inter;
    padding: 20px 38px 24px;
}
.document-filter-content .filter-checkbox-wrap .filter-checkbox-label {
    font-family: Inter;
    color: #545459;
    font-weight: 500;
}
.document-filter-content .filter-action {
    font-family: Inter;
    height: 60px;
    padding: 0 38px;
    border-top: 1px solid #f6f6f6;
}
.document-filter-content .filter-action .clear-filter {
    color: #325ceb;
    font-weight: 500;
    cursor: pointer;
}
.document-filter-content .filter-action .apply-filter {
    color: #fff;
    font-weight: 500;
    width: 100%;
    height: 46px;
    border-radius: 6px;
    border: 1px solid #325ceb;
    background-color: #325ceb;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.create-task-modal.full-modal {
    height: 100vh;
}
.create-task-modal.full-modal .ant-modal-content {
    border-radius: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.create-task-modal.full-modal .ant-modal-content .ant-modal-body {
    flex: 1;
}
.ant-popover.document-popover .ant-popover-inner {
    padding: 0;
    box-shadow: 0px 2px 6px 0px rgba(7, 11, 58, 0.2);
}
.ant-popover.document-popover.initual-msg .ant-popover-inner {
    background-color: #d6e2fb;
}
.ant-popover.document-popover.re-upload .ant-popover-inner {
    background-color: #ffe6a6;
}
.ant-popover.document-popover.initual-msg .ant-popover-arrow::before {
    background-color: #d6e2fb;
}
.ant-popover.document-popover.re-upload .ant-popover-arrow::before {
    background-color: #ffe6a6;
}
.ant-popover.document-popover .ant-popover-inner .doc-popover {
    color: #545459;
    padding: 20px 10px;
    font-family: Inter;
    font-size: 12px;
    line-height: 18px;
}
.ant-popover.document-popover .ant-popover-inner .doc-popover-close {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 12px;
    color: #545459;
    cursor: pointer;
}
.ant-popover.document-popover
    .ant-popover-inner
    .doc-popover
    .doc-popover-title {
    font-weight: 600;
}
.ant-popover.document-popover
    .ant-popover-inner
    .doc-popover
    .doc-popover-content {
    max-width: 280px;
    margin-top: 6px;
    word-break: break-word;
}
@media (max-width: 575px) {
    .document-table-wrap .document-table .document-header-name,
    .document-table-wrap .document-table .document-name {
        padding-left: 0;
    }
    .checklist-item-title {
        padding-left: 16px;
    }
}
@media (max-width: 767px) {
    .currentChecklistWrap {
        padding: 24px 16px;
    }
    .currentChecklistWrap
        .task-content
        .task-collapse
        .task-collapse-header
        .task-completed
        .task-completed-num {
        display: none;
    }
    .currentChecklistWrap .task-content .task-collapse .task-collapse-header {
        font-size: 12px;
    }
    .currentChecklistWrap
        .task-content
        .task-collapse
        .task-collapse-header
        .task-type {
        width: 40%;
    }
    .currentChecklistWrap
        .task-content
        .task-collapse
        .task-collapse-header
        .task-update {
        width: 40%;
    }
    .currentChecklistWrap
        .task-content
        .task-collapse
        .task-collapse-header
        .task-completed {
        width: 20%;
    }
    .currentChecklistWrap .task-topBar {
        flex-direction: column;
    }
}
@media (max-width: 991px) {
    .signature-modal .ant-modal-content .ant-modal-body .signature-bar {
        padding: 12px 16px;
    }
    .signature-modal
        .ant-modal-content
        .ant-modal-body
        .signature-bar
        .signature-bar-btn
        .add-signature {
        padding: 0 8px;
    }
    .signature-modal
        .ant-modal-content
        .ant-modal-body
        .signature-bar
        .signature-bar-btn
        .save-signature {
        padding: 0 12px;
    }
}
