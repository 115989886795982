.small-modal .ant-modal {
    padding-bottom: 0;
}

.small-modal .ant-modal-content {
    padding: 0 !important;
    width: 100%;
    border-radius: 8px !important;
    height: auto !important;
}

.small-modal .ant-modal-header {
    margin-bottom: 0;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
}

.small-modal .ant-modal-title {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
}

.small-modal .ant-modal-body {
    margin-top: 0;
    padding: 24px;
    height: auto;
}

.small-modal .ant-modal-footer {
    margin-top: 0;
    padding: 16px 24px;
    border-top: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
}

.small-modal .ant-modal-close {
    top: 16px;
    right: 16px;
}

