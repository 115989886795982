/* DateOfBirth Calendar style */

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
  text-align: center;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  color: #222;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: white;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: white;
}
.react-calendar__tile--hasActive {
  background: var(--primary-color);
  color: var(--primary-color-text) !important;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--primary-color-2);
  color: var(--primary-color-2-text) !important;
}
.react-calendar__tile--active {
  background: var(--primary-color);
  color: var(--primary-color-text) !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: var(--primary-color-2);
  color: var(--primary-color-2-text) !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.obutton:hover {
  background-color: #215ef4 !important;
  font-size: 0.97em !important;
}
.obutton:active {
  border-color: #102693 !important;
  background-color: #102693 !important;
}

input:invalid:required.form-control + .zeitro-radio.in-valid {
  border: 2px solid #ff0000 !important;
}

.Cal__Day__root {
  display: inline-block;
  box-sizing: border-box;
  width: 40px;
  list-style: none;
  font-size: 12px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  user-select: none;
}
.Cal__Day__root.Cal__Day__hovered {
  position: relative;
}
.Cal__Day__root.Cal__Day__hovered:after {
  content: "";
  display: block;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #e7e8ec;
  z-index: -1;
}
.Cal__Day__root.Cal__Day__hovered.Cal__Day__startOfWeek,
.Cal__Day__root.Cal__Day__hovered.Cal__Day__endOfWeek {
  position: relative;
  background-color: transparent;
}
.Cal__Day__root.Cal__Day__hovered.Cal__Day__startOfWeek:before,
.Cal__Day__root.Cal__Day__hovered.Cal__Day__endOfWeek:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #e7e8ec;
  z-index: -1;
}
.Cal__Day__root.Cal__Day__hovered.Cal__Day__startOfWeek:after,
.Cal__Day__root.Cal__Day__hovered.Cal__Day__endOfWeek:after {
  width: 50%;
}
.Cal__Day__root.Cal__Day__hovered.Cal__Day__startOfWeek:after {
  right: 0;
}
.Cal__Day__root.Cal__Day__hovered.Cal__Day__endOfWeek:after {
  left: 0;
}
.Cal__Day__root.Cal__Day__hovered.Cal__Day__startOfEvenMonth:before,
.Cal__Day__root.Cal__Day__hovered.Cal__Day__startOfOddMonth:before,
.Cal__Day__root.Cal__Day__hovered.Cal__Day__endOfEvenMonth:before,
.Cal__Day__root.Cal__Day__hovered.Cal__Day__endOfOddMonth:before {
  background-color: #e7e8ec !important;
}
.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted,
.Cal__Day__root.Cal__Day__enabled:active,
.Cal__Day__root.Cal__Day__enabled:hover {
  position: relative;
  z-index: 1;
}
.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before,
.Cal__Day__root.Cal__Day__enabled:active:before,
.Cal__Day__root.Cal__Day__enabled:hover:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #e7e8ec;
  z-index: -1;
}
.Cal__Day__root.Cal__Day__enabled.Cal__Day__highlighted:before,
.Cal__Day__root.Cal__Day__enabled:active:before {
  opacity: 1;
}
.Cal__Day__root:first-child {
  position: relative;
}
.Cal__Day__root.Cal__Day__today {
  position: relative;
  z-index: 2;
}
.Cal__Day__root.Cal__Day__today > span {
  color: #0f2346;
}
.Cal__Day__root.Cal__Day__today.Cal__Day__disabled > span {
  color: #cfd3da;
}
.Cal__Day__root.Cal__Day__today:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px;
  z-index: -1;
}
.Cal__Day__root.Cal__Day__today.Cal__Day__disabled:before {
  box-shadow: inset 0 0 0 1px #c3c8d1;
}
.Cal__Day__root.Cal__Day__selected {
  position: relative;
}
.Cal__Day__root.Cal__Day__selected > .Cal__Day__month,
.Cal__Day__root.Cal__Day__selected > .Cal__Day__year {
  display: none;
}
.Cal__Day__root.Cal__Day__selected:before {
  display: none;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  line-height: 40px;
  z-index: 2;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__month {
  color: #fff;
  top: 7px;
}
.Cal__Day__root.Cal__Day__selected .Cal__Day__selection .Cal__Day__day {
  position: relative;
  top: 7px;
  font-size: 12px;
  font-weight: bold;
}
.Cal__Day__root.Cal__Day__disabled {
  color: #cfd3da;
  cursor: not-allowed;
}

.Cal__Day__month,
.Cal__Day__year {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
}

.Cal__Day__month {
  color: #647188;
  top: 2px;
}

.Cal__Day__year {
  color: #647188;
  bottom: 3px;
}

/*
   * Range selection styles
   */
.Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__day,
.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__day {
  font-weight: bold;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after,
.Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 50%;
  height: 40px;
  transform: translateY(-50%);
  background-color: #c3c8d1;
}
.Cal__Day__edge .Cal__Day__range.Cal__Day__selected.Cal__Day__start:after,
.Cal__Day__edge .Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  background-color: #0f2346;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__start.Cal__Day__endOfWeek:after,
.Cal__Day__range.Cal__Day__selected.Cal__Day__end.Cal__Day__startOfWeek:after {
  display: none;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__disabled
  .Cal__Day__selection.Cal__Day__selection {
  background-color: #c3c8d1 !important;
}
.Cal__Day__range.Cal__Day__selected.Cal__Day__disabled
  .Cal__Day__selection.Cal__Day__selection
  .Cal__Day__day,
.Cal__Day__range.Cal__Day__selected.Cal__Day__disabled
  .Cal__Day__selection.Cal__Day__selection
  .Cal__Day__month {
  color: #fff;
  font-weight: normal;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__start .Cal__Day__selection {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__start:after {
  right: 0;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__start.Cal__Day__end:after {
  display: none;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__end:after {
  left: 0;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-sizing: border-box;
}
.Cal__Day__range.Cal__Day__selected.Cal__Day__end
  .Cal__Day__selection
  .Cal__Day__day {
  top: 7px;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange
  .Cal__Day__selection {
  left: 0;
  right: 0;
  width: 100%;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  color: #0f2346 !important;
  background-color: #c3c8d1 !important;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange.Cal__Day__startOfWeek
  .Cal__Day__selection,
.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange.Cal__Day__endOfWeek
  .Cal__Day__selection {
  width: 40px;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange.Cal__Day__startOfWeek:after,
.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange.Cal__Day__endOfWeek:after {
  display: block;
  content: "";
  position: absolute;
  top: 50%;
  width: 50%;
  height: 40px;
  transform: translateY(-50%);
  background-color: #c3c8d1;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange.Cal__Day__startOfWeek
  .Cal__Day__selection {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange.Cal__Day__startOfWeek:after {
  right: 0;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange.Cal__Day__endOfWeek
  .Cal__Day__selection {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange.Cal__Day__endOfWeek:after {
  left: 0;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__day {
  top: 0;
  font-size: 12px;
  font-weight: normal;
}

.Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange .Cal__Day__month {
  display: none;
}

.Cal__Day__edge
  .Cal__Day__range.Cal__Day__selected.Cal__Day__selected
  .Cal__Day__selection {
  color: #fff !important;
  background-color: #0f2346 !important;
}

.Cal__Day__startOfMonth {
  position: relative;
  border-radius: 50% 0 0 0;
}
.Cal__Day__startOfMonth:before {
  border-radius: 50% 0 0 0;
  content: attr(data-value);
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
}

.Cal__Day__startOfEvenMonth {
  background-color: #f3f4f6 !important;
  border-radius: 0;
}
.Cal__Day__startOfEvenMonth:before {
  background-color: #fff;
}
.Cal__Day__startOfEvenMonth:hover {
  background-color: #f3f4f6 !important;
  border-radius: 0 50% 50% 50%;
}
.Cal__Day__startOfEvenMonth.Cal__Day__selected {
  background-color: #fff !important;
}

.Cal__Day__startOfOddMonth {
  background-color: #fff !important;
}
.Cal__Day__startOfOddMonth.Cal__Day__enabled {
  background-color: #f3f4f6 !important;
}
.Cal__Day__startOfOddMonth:before {
  background-color: #f3f4f6;
}
.Cal__Day__startOfOddMonth:hover {
  background-color: #f3f4f6 !important;
  border-radius: 50% 0 0 0;
}

.Cal__Day__endOfMonth {
  position: relative;
  border-radius: 0 0 50% 0;
}
.Cal__Day__endOfMonth:before {
  content: attr(data-value);
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 0 0 50% 0;
}

.Cal__Day__endOfOddMonth {
  background-color: #f3f4f6 !important;
  border-radius: 0;
}
.Cal__Day__endOfOddMonth:before {
  background-color: #fff;
}
.Cal__Day__endOfOddMonth:hover {
  background-color: #f3f4f6 !important;
  border-radius: 50% 50% 0 50%;
}
.Cal__Day__endOfOddMonth.Cal__Day__selected {
  background-color: #f3f4f6 !important;
  border-radius: 50% 50% 0 50%;
}

.Cal__Day__endOfEvenMonth {
  background-color: #fff !important;
  border-radius: 0 0 50% 0;
}
.Cal__Day__endOfEvenMonth.Cal__Day__enabled {
  background-color: #f3f4f6 !important;
}
.Cal__Day__endOfEvenMonth:before {
  background-color: #f3f4f6;
}
.Cal__Day__endOfEvenMonth:hover {
  background-color: #f3f4f6 !important;
  border-radius: 0 0 50% 0;
}

.Cal__Container__root {
  position: relative;
  display: table;
  z-index: 1;
  font: 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  outline: none;
  text-align: left;
}
.Cal__Container__root.Cal__Container__landscape {
  display: flex;
  flex-direction: row;
}
.Cal__Container__root.Cal__Container__landscape .Cal__Container__wrapper {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  z-index: 1;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.Cal__Container__listWrapper {
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.Cal__Header__root {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 98px;
  padding: 20px;
  line-height: 1.3;
  font-weight: 400;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.Cal__Header__root.Cal__Header__landscape {
  align-items: flex-start;
  min-width: 200px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 3px;
}
.Cal__Header__root.Cal__Header__landscape
  .Cal__Header__dateWrapper.Cal__Header__day {
  flex-grow: 1;
  height: 76px;
}

.Cal__Header__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
}
.Cal__Header__wrapper.Cal__Header__blank {
  height: 58px;
  line-height: 58px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  cursor: default;
}

.Cal__Header__dateWrapper {
  position: relative;
  display: block;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.5);
  transition: color 0.3s ease;
}
.Cal__Header__dateWrapper.Cal__Header__active {
  color: white;
}
.Cal__Header__dateWrapper.Cal__Header__day {
  height: 38px;
  font-size: 36px;
  line-height: 36px;
  text-transform: capitalize;
}
.Cal__Header__dateWrapper.Cal__Header__year {
  height: 20px;
  font-size: 18px;
  line-height: 18px;
}

.Cal__Header__date {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Cal__Header__range {
  display: flex;
  flex-grow: 1;
}
.Cal__Header__range .Cal__Header__dateWrapper {
  overflow: visible;
}
.Cal__Header__range .Cal__Header__wrapper:first-child:before,
.Cal__Header__range .Cal__Header__wrapper:first-child:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  margin-top: -50px;
  margin-left: -50px;
  border-top: 100px solid transparent;
  border-bottom: 100px solid transparent;
  border-left: 60px solid;
}
.Cal__Header__range .Cal__Header__wrapper:first-child:before {
  color: rgba(255, 255, 255, 0.15);
  transform: translateX(1px);
}
.Cal__Header__range .Cal__Header__wrapper:last-child {
  margin-left: 60px;
}
.Cal__Header__range .Cal__Header__wrapper .Cal__Header__date {
  white-space: nowrap;
  z-index: 1;
}

.Cal__Today__root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 6px;
  box-sizing: border-box;
  transform: translate3d(0, -100%, 0);
  font-weight: 500;
  line-height: 0;
  z-index: 10;
  cursor: pointer;
  transition: transform 0.3s ease;
  transition-delay: 0.3s;
}
.Cal__Today__root.Cal__Today__show {
  transform: translate3d(0, 0, 0);
  transition-delay: 0s;
}
.Cal__Today__root.Cal__Today__show .Cal__Today__chevron {
  transition: transform 0.3s ease;
}
.Cal__Today__root .Cal__Today__chevron {
  margin-top: 2px;
  margin-left: 5px;
  transform: rotate(270deg);
  transition: transform 0.3s ease;
}
.Cal__Today__root.Cal__Today__chevronUp .Cal__Today__chevron {
  transform: rotate(180deg);
}
.Cal__Today__root.Cal__Today__chevronDown .Cal__Today__chevron {
  transform: rotate(360deg);
}

.Cal__MonthList__root {
  width: 100% !important;
  -webkit-overflow-scrolling: touch;
}
.Cal__MonthList__root.Cal__MonthList__scrolling > div {
  pointer-events: none;
}
.Cal__MonthList__root.Cal__MonthList__scrolling label {
  opacity: 1;
}

.Cal__Weekdays__root {
  position: relative;
  z-index: 5;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  box-shadow: 0 3px 3px 0 rgba(15, 30, 70, 0.12);
  justify-content: center;
}

.Cal__Weekdays__day {
  line-height: 40px;
  flex-basis: 40px;
  flex-grow: 0;
  font-weight: normal;
  text-align: center;
  color: #647188;
}

.Cal__CurrentMonth__root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 6px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 0;
  z-index: 10;
}

.Cal__Years__root {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to bottom, rgba(15, 30, 70, 0.12) 0, #fff 3px);
  background-color: #fff;
}

.Cal__Years__list {
  box-sizing: border-box;
}
.Cal__Years__list.Cal__Years__center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Cal__Years__year {
  display: flex;
  padding: 0 20px;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  box-sizing: border-box;
}
.Cal__Years__year.Cal__Years__withMonths {
  border-bottom: 1px solid #dbdee4;
}
.Cal__Years__year.Cal__Years__withMonths label {
  height: 80px;
  line-height: 40px;
  box-sizing: border-box;
}
.Cal__Years__year label {
  flex-basis: 42%;
}
.Cal__Years__year label span {
  flex-shrink: 0;
  color: #0f2346;
}
.Cal__Years__year ol {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  min-width: 240px;
  font-size: 12px;
}
.Cal__Years__year ol li {
  display: flex;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  list-style: none;
  border-radius: 50%;
  box-sizing: border-box;
  color: #0f2346;
  font-weight: normal;
  text-transform: capitalize;
}
.Cal__Years__year ol li.Cal__Years__currentMonth {
  border: 1px solid;
}
.Cal__Years__year ol li.Cal__Years__selected {
  position: relative;
  z-index: 1;
  color: #fff;
  border: 0;
}
.Cal__Years__year ol li.Cal__Years__selected .Cal__Years__selection {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  line-height: 40px;
  z-index: 2;
}
.Cal__Years__year ol li.Cal__Years__disabled {
  cursor: not-allowed;
  color: #c3c8d1;
}
.Cal__Years__year ol li.Cal__Years__disabled:hover {
  background-color: inherit;
}
.Cal__Years__year ol li:hover {
  background-color: #e7e8ec;
}
.Cal__Years__year:hover label > span,
.Cal__Years__year.Cal__Years__active label > span {
  color: #fd1f6d;
}
.Cal__Years__year:hover,
.Cal__Years__year.Cal__Years__active {
  position: relative;
}
.Cal__Years__year.Cal__Years__active {
  font-size: 32px;
}
.Cal__Years__year.Cal__Years__currentYear {
  position: relative;
}
.Cal__Years__year.Cal__Years__currentYear label > span {
  min-width: 50px;
  padding-bottom: 5px;
  border-bottom: 3px solid;
}
.Cal__Years__year.Cal__Years__currentYear.Cal__Years__active label > span {
  min-width: 85px;
}
.Cal__Years__year.Cal__Years__first {
  padding-top: 0;
}
.Cal__Years__year.Cal__Years__last {
  padding-bottom: 0;
  border-bottom: none;
}

/*
   * Range selection styles
   */
.Cal__Years__range.Cal__Years__selected.Cal__Years__start:after,
.Cal__Years__range.Cal__Years__selected.Cal__Years__end:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 50%;
  height: 40px;
  margin-top: -20px;
  background-color: #c3c8d1;
}

.Cal__Years__range.Cal__Years__selected.Cal__Years__start
  .Cal__Years__selection,
.Cal__Years__range.Cal__Years__selected.Cal__Years__end .Cal__Years__selection {
  font-weight: bold;
  color: #fff;
  background-color: #0f2346;
  box-sizing: border-box;
}

.Cal__Years__range.Cal__Years__selected.Cal__Years__disabled {
  background-color: #c3c8d1 !important;
}
.Cal__Years__range.Cal__Years__selected.Cal__Years__disabled
  .Cal__Years__selection.Cal__Years__selection {
  background-color: #c3c8d1 !important;
  color: #fff;
}
.Cal__Years__range.Cal__Years__selected.Cal__Years__disabled:after {
  background-color: #c3c8d1 !important;
}

.Cal__Years__range.Cal__Years__selected.Cal__Years__start
  .Cal__Years__selection {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.Cal__Years__range.Cal__Years__selected.Cal__Years__start:after {
  right: 0;
}

.Cal__Years__range.Cal__Years__selected.Cal__Years__start.Cal__Years__end:after {
  display: none;
}

.Cal__Years__range.Cal__Years__selected.Cal__Years__end:after {
  left: 0;
}

.Cal__Years__range.Cal__Years__selected.Cal__Years__end .Cal__Years__selection {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.Cal__Years__range.Cal__Years__selected:nth-child(6n).Cal__Years__start:after {
  display: none;
}

.Cal__Years__range.Cal__Years__selected:nth-child(6n
    + 1).Cal__Years__end:after {
  display: none;
}

.Cal__Years__range.Cal__Years__selected.Cal__Years__betweenRange {
  border-radius: 0;
  background-color: #c3c8d1 !important;
}
.Cal__Years__range.Cal__Years__selected.Cal__Years__betweenRange
  .Cal__Years__selection {
  left: 0;
  right: 0;
  width: 100%;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0f2346;
}

.Cal__Years__range.Cal__Years__selected:nth-child(6n
    + 1).Cal__Years__betweenRange {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.Cal__Years__range.Cal__Years__selected:nth-child(6n).Cal__Years__betweenRange {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.Cal__Animation__enter {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  transition: 0.25s ease;
}

.Cal__Animation__enter.Cal__Animation__enterActive {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.Cal__Animation__leave {
  transform: translate3d(0, 0, 0);
  transition: 0.25s ease;
}
.yellow {
  color: #f0f0df;
}

.tablecontainer {
  display: flex;
  width: 100%;
  min-height: 300px;
  text-align: center !important;
  padding-left: 50px;
  padding-right: 50px;
}
@media (max-width: 800px) {
  .tablecontainer {
    display: block;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.tablerow {
  flex: 1;
  text-align: left !important;
  margin-left: 30px;
  margin-right: 30px;
  padding: 20px 20px 20px 20px;
}
@media (max-width: 800px) {
  .tablecontainer {
    display: block;
    width: 100%;
  }
  .tablerow {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.frontimage {
  margin-top: 20px;
  height: 400px;
  width: auto;
}
@media (max-width: 1200px) {
  .frontimage {
    margin-top: 40px;
    height: 300px;
  }
}
.hidesmall {
  display: block;
}
@media (max-width: 600px) {
  .frontimage {
    width: 400px;
    height: auto;
  }
  .hidesmall {
    display: none;
  }
}

@media (max-width: 500px) {
  .frontimage {
    margin-top: 0px;
    width: 70%;
  }
}

.Cal__Animation__leave.Cal__Animation__leaveActive {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.Cal__transition__enter {
  opacity: 0;
}

.Cal__transition__enterActive {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.Cal__transition__leave {
  opacity: 1;
}

.Cal__transition__leaveActive {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.Cal__Slider__root,
.Cal__Slider__slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Cal__Slider__root {
  overflow: hidden;
}

.Cal__Slider__slide {
  padding: 20px 65px;
}
.Cal__Slider__slide:first-child {
  padding-left: 20px;
}

.Cal__Slider__wrapper {
  height: 100%;
  transition: transform 0.3s ease;
}

.Cal__Slider__arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  opacity: 0.7;
  cursor: pointer;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.Cal__Slider__arrow svg {
  width: 15px;
}
.Cal__Slider__arrow:hover {
  opacity: 1;
}

.Cal__Slider__arrowRight {
  right: 0;
}

.Cal__Slider__arrowLeft {
  left: 0;
  transform: scaleX(-1);
}

.Cal__Month__root.Cal__Month__even .Cal__Month__row li {
  background-color: #fff;
}

.Cal__Month__root.Cal__Month__odd .Cal__Month__row li {
  background-color: #f3f4f6;
}

.Cal__Month__rows {
  position: relative;
}

.Cal__Month__row {
  padding: 0;
  margin: 0 auto;
  width: 280px;
}
.Cal__Month__row:first-child {
  text-align: right;
}
.Cal__Month__row.Cal__Month__partial:last-of-type li {
  position: relative;
  z-index: 1;
}

.Cal__Month__label {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  z-index: 3;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.85);
  opacity: 0;
  transition: opacity 0.3s ease;
}
.Cal__Month__label > span {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
.Cal__Month__label.Cal__Month__partialFirstRow {
  top: 40px;
}
