.LoansContent {
    padding: 90px 82px;
}

.NameBar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #1630AC;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.DropdownP {
    color: #222;
    width: 100%;
    height: 46px;
    font-weight: 500;
    /* border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC; */
    line-height: 46px;
    /* text-align: center; */
    /* padding-left: 16px; */
    cursor: pointer;
}

.LoansTitle {
    color: #222;
    font-family: Inter;
    font-size: 32px;
    font-weight: 600;

    /* 137.5% */
}

.YourLoans {
    color: #222;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;

}

.loansIntroduce {
    color: #6E6E70;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
}

.zeitro-default-button {
    height: 46px;
    border-radius: 6px;
    border: 1px solid #E3EAFD;
    background: #F7F8FF;
    color: #325CEB;
}

.Incomplete {
    display: inline-flex;
    padding: 5px 10px;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    font-size: 14px;
    color: #1630AC;
    border-radius: 15px;
    background: #D7E2FB;
}

.dataLoansText {
    margin-top: 5px;
    color: #333;
    width: 100%;
    font-size: 18px;

    font-weight: 500;

}

.borderBottom {
    padding: 8px 0;
    border-bottom: 1px solid #ECECEC;
}

.MDataLoansText {
    color: #333;
    text-align: right;

    font-size: 16px;

    font-weight: 500;

}

.faq_Collapse {
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #DBDCDE;
    padding: 3px;
    margin-top: 26px;

}

.faq_content {

    font-size: 20px;
    color: '#6B6B6B'
}

.faq_Collapse .ant-collapse-header {
    /* border-radius: 10px 10px 0px 0px; */

    color: #222;
    background: #FFF;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
}

.faq_Collapse .ant-collapse-content {


    background: #FFF !important;
}

.faq_Collapse .ant-collapse-content-box {
    color: #6B6B6B;
    text-align: left;
    font-size: 16px;

    font-weight: 400;
    line-height: 20px;
    /* 125% */
}

/* .faq_Collapse .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9 !important;
} */

@media screen and (max-width: 575px) {
    .LoansContent {
        padding: 90px 20px;
    }
}