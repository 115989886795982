.prequal-letter-history-subheader {
  padding: 2em 0em 2em 0em;
  align-items: center;
}

.prequal-letter-history-subheader-history-text span {
  font-size: 1.25em !important;
  color: #666;
}

.prequal-letter-history-subheader-buttons {
  height: 100%;
}
.prequal-letter-history-subheader-buttons img {
  height: 1.25em;
}

.prequal-letter-history-subheader-buttons .ant-btn {
  padding: 1.25em;
  border-color: #325ceb;
}

.prequal-letter-history-subheader-buttons span {
  font-size: 1.25em !important;
  color: #325ceb;
}

.prequal-letter-history-selection-container {
  height: auto;
  border-radius: 1em;
  border: 1px solid #dddddd;
  margin-top: 0em;
  margin-bottom: 12px;
}
.prequal-letter-history-download-mobile {
  width: 100%;
  display: inline-block;
}
.prequal-letter-history-download-mobile button {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 8px !important;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  color: #FFF;
  background-color: #325CEB;
}
.prequal-letter-history-download-mobile-row {
  margin-top: 24px;
  display: none;
}
@media screen and (max-width: 768px) {
  .prequal-letter-history-subheader {
    flex-direction: row-reverse;
  }
  .prequal-letter-history-subheader-buttons .prequal-letter-history-download {
    display: none;
  }
  .prequal-letter-history-download-mobile-row {
    display: block;
  }
}