@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

#zeitroframe-calhfa .modal{
    height: auto !important;
}

.font18 {
    font-size: 18px;
}

.hfaTitle {
    font-family: 'Inter' !important;
    color: #070B3A;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
}

.hfaSubTitle {
    font-size: 16px;
    font-weight: 400;
    color: #6E6E70;
    line-height: 18px;
}

.topbanner .hfaTitle{
    font-size: 48px;
    font-weight: 600;
    color: #222;
    line-height: 48px;
    font-style: normal;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.topbanner .hfaSubTitle{
    font-size: 36px;
    font-weight: 500;
    color: #6E6E70;
    line-height: 48px;
    font-style: normal;
    margin-bottom: 40px;
}

.topbanner .hfaSubText{
    font-size: 18px;
    font-weight: 400;
    color: #6E6E70;
    line-height: 24px;
    font-style: normal;
    letter-spacing: 0.2px;
    width: 700px
}

.hfaQualification {
    display: flex !important;
    gap: 18px;
}

.hfaQualificationPart.hfaQualifiedBackground {
    background-color: #D6E2FB;
    border: 1px solid #D6E2FB;
}
.hfaQualificationPart.hfaUnqualifiedBackground {
    background-color: #FBD6D6;
    border: 1px solid #FBD6D6;
}

.hfaUnqualifiedResultBackground {
    background-color: #D9D9D9 !important;
    color: black !important;
    padding: 40px 10px 0 10px !important;
    font-weight: 500;
}

.hfaQualifiedResultBackground {
    background-color: #1F3988 !important;
    color: white;
    font-weight: 500;
    color: #D6E2FB !important;
    padding: 40px 15px 0 30px !important;
}

.hfaQualificationPart {
    height: 260px;
    border-radius: 10px;
    padding: 26px 37px 35px;
    border: 1px solid #ECECEC;
    background-color: #FCFCFC;
    max-width: calc(33.333333% - 18px) !important;
}

.hfaQualification-bottom .qualification-tips {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    color: #000;
}

.hfaQualification-bottom .hfa-btn {
    height: 60px;
    width: 356px;
    max-width: 90%;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.hfaQualification-bottom .hfa-btn.apply {
    background-color: #325CEB;
    color: #FEFEFE;
    margin: 12px auto 0;
}
.hfaQualification-bottom .apply.disabled {
    background-color: #D9D9D9;
    margin: 60px auto 0;
}

.hfaQualification-bottom .hfa-btn.explore-more {
    color: #325CEB;
    border: 1px solid #325CEB;
    margin: 14px auto 0;
}

.hfaQualificationPart2 {
    height: 200px;
    width: 20%;
    clip-path: polygon(93% 0%, 100% 50%, 93% 100%, 0% 100%, 7% 50%, 0% 0%);
    padding: 50px 15px 0 30px;
}

.hfaQualificationPart3 {
    height: 200px;
    width: 20%;
    clip-path: polygon(100% 0%, 100% 100%, 93% 100%, 0% 100%, 7% 50%, 0% 0%);
    background-color: #D9D9D9;
    border-radius: 8px;
    color: white;
    padding: 85px 10px 0 30px;
    text-align: center;
}

.hfaHeader {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 42px;
    color: #000
}
.hfaTab {
    display: flex;
    font-size: 18px;
    color: #6E6E70;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 20px;
}
.hfaTab .hfaTab-active {
    color: #000;
    font-weight: 600;
}
.hfaTab div {
    height: 32px;
    line-height: 32px;
    cursor: pointer;
}
.hfaTab div:first-child {
    padding-right: 20px;
    border-right: 1px solid #D9D9D9;
}
.hfaTab div:last-child {
    padding-left: 20px;
}
.hfaLoanDetailsChartWrap {
    min-height: 260px;
}
.hfaLoanNetChartWrap {
    min-height: 400px;
}
.apexcharts-pie-label, .apexcharts-datalabels, .apexcharts-datalabel, .apexcharts-datalabel-label, .apexcharts-datalabel-value {
    cursor: default;
    pointer-events: none;
    font-size: 15px !important;
    font-weight: 500 !important;
}
.hfaCircle {
    display: flex;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin: 5px 8px 0 0;
}

.hfaInput {
    width: 60px;
    border: 0.5px solid #ECECEC;
    background: #FCFCFC;
    border-radius: 4px;
    margin: 10 8px 0 8px;
    height: 50px;
    padding-left: 10px;
    padding-top: 10px;
    max-height: none !important;
    display: inline-table;
}

.hfaInputGroup {
    width: 40px;
    border-left: 0.5px solid #ECECEC;
    border-top: 0.5px solid #ECECEC;
    border-bottom: 0.5px solid #ECECEC;
    border-right: none;
    background: #FCFCFC;
    border-radius: 4px;
    margin: 0 0 0 8px;
    height: 40px;
    padding-left: 10px;
    max-height: none !important;
}

.hfaInputSuffix {
    width: 40px;
    border-left: none;
    border-top: 0.5px solid #ECECEC;
    border-right: 0.5px solid #ECECEC;
    border-bottom: 0.5px solid #ECECEC;
    background: #FCFCFC;
    border-radius: 4px;
    margin: 0 8px 0 0;
    height: 44px;
    max-height: none !important;
}

.hfaLoanDetails {
    font-size: 16px;
    border-bottom: 1px solid #ECECEC;
}

.overAffordText{
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        color:#586570;
}

.hfaLable {
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
}

.hfaBigLable {
    font-style: normal;
    font-weight: 600;
    font-size: 24px !important;
}

.hfaBigText {
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
}

.hfaSubText {
    font-weight: 300;
    font-size: 16px;
}

.hfaQualificationPart .hfaInputLabel {
    /* height: 60px; */
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #586570;
    line-height: 20px;
    font-weight: 500;
    margin-top: 10px;
}
.hfaSelect {
    width: 90%;
}

.hfaResult {
    flex: 1;
    border-radius: 8px;
    background-color: #F5F5F7;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 330px;

}

.calchfaContainer {
    display: flex;
    padding: 0 40px 0 40px;
    font-size: 18px;
}

.calchfa-subtitle {
    font-size: 18px;
    line-height: 19px;
    color: #6E6E70;
    padding: 18px 0 20px 0;

}

.calchfaContainer .form-control {
    height: 2.75rem;
}


.collapse-wrap {
    /* max-height: 540px; */
    overflow-y: scroll;
    overflow-x: inherit;
}

.collapse-wrap .collapse-item {
    border: 1px solid #CFD4D9;
    border-radius: 8px;
    margin-bottom: 20px;
}

.collapse-wrap .collapse-item .collapse-header {
    height: 66px;
    padding: 0 30px 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.collapse-wrap .collapse-item .collapse-body {
    padding: 0 40px 0 40px;
}

.collapse-wrap .collapse-item .collapse-body .checkMore-group {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.collapse-wrap .collapse-item .collapse-body .checkMore-btn {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #325CEB;
    text-decoration: underline;
    cursor: pointer;
}

.collapse-wrap .collapse-item .collapse-body .check-group-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.collapse-wrap .collapse-item .collapse-body .check-group-box {
    width: 240px;
}

.collapse-wrap .collapse-item .collapse-header .title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

.collapse-wrap .collapse-item .collapse-header .open {
    transform: rotate(180deg);
    transition: all .3s;
}

.collapse-wrap .collapse-item .collapse-header .close {
    transition: all .3s;
}

.collapse-wrap .input-group {
    border-radius: 4px;
}

/* always how scroll bar */

.collapse-wrap::-webkit-scrollbar {
    -webkit-appearance: none;
}

.collapse-wrap::-webkit-scrollbar:vertical {
    width: 11px;
}

.collapse-wrap::-webkit-scrollbar:horizontal {
    height: 1px;
}

.collapse-wrap::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.333);
}

.hfaNetSheet {
    background-color: #F3F5F8;
    border-radius: 12px;
    max-width: 512px;
    padding: 40px;
}

.topbanner{
    background-color: #F8F9FE;
    display: flex;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
    border-bottom: 1px solid #ECECEC;
}

.hightlightWord{
    color: #1630AC;
}

.bannerContentWraper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* width: 60%; */

}

.bannerIconWraper{
    display: flex;
    justify-content: center;
    align-items: center;
}

.hfaBodyTitle{
    font-size: 24px;
    line-height: 20px;
    color: #000;
    font-weight: 600;
}

.hfaBodyText{
    font-size: 16px;
    line-height: 22px;
    color: #6E6E70;
    font-weight: 400;
}

.steps-list li{
    counter-increment: step-counter;
    font-size: 16px;
    line-height: 20px;
    color: #333;
    font-weight: 400;
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid #DBDCDE;   
}

.steps-list li::marker{
    color: #6E6E70;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    /* content: counter(step-counter); */
    margin-top: 5px;
}

.polishBackground{
    background-color: #F8F9FE;
}

.finePrints{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #6E6E70;
}

.hfaLoanDetails .hfa-btn {
    height: 60px;
    width: 356px;
    max-width: 90%;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.hfa-btn-whatsnext{
    height: 60px;
    width: 300px;
    max-width: 90%;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #325CEB !important;
    color: #FEFEFE;
    border: 1px solid 
}

.hfaBreakdownText{
    font-size: 20px;
    line-height: 18px;
    color: #586570;
    font-weight: 500;
    font-style: normal;
}

.hfaBreakdownSubText{
    font-size: 18px;
    line-height: 18px;
    color: #586570;
    font-weight: 500;
    font-style: normal;
}

.breakDownCard{
    background-color: #F8F9FE;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;

}

.inputLink{
    color: #325CEB;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    text-decoration: underline;  
}

.inputLinkText{
    font-size: 14px;
    line-height: 22px;
    color: #6E6E70;
    font-weight: 400;
}

.appreciationInputWraper{
    display: flex;
    border: 1px solid #ECECEC;
    padding: 10px 20px 10px 20px;
    border-radius: 8px;
    background-color: #FCFCFC;
}

.editables{
    font-size: 16px;
    line-height: 20px;
    color: #325CEB;
    font-weight: 500;
    font-style: normal;
}

.hfaLable .scroll-bar{
    background-color: #FFF;
}

.affordabilityDisclaimer{
    background-color: #D6E2FB;
    border: 1px solid #D6E2FB;
    border-radius: 6px;
    padding: 20px;
    width: calc(100% - 15px);
}
.affordabilityDisclaimerText{
    font-size: 14px;
    line-height: normal;
    color: #1F3988;
    font-weight: 400;
    font-style: normal;
}

.heavyText{
    font-weight: 600;
}

.callbackForm {
    background-color: white;

    max-width: 414px;
    max-height: 417px;
    position: relative;
    left: 35vw;
    padding: 30px;
}

.callbackFormInModal {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.callbackTitle {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    color: #222
}

.callbackSubTitle{
    width:350px;
    font-size: 16px;
    font-weight: 400;
    color: #6E6E70;
    line-height: normal;
}

.callbackDoneTitle{
    font-size: 16px;
    font-weight: 400;
    color: #6E6E70;
    line-height: normal;
}

.callbackInput {
    width: 350px;
    height: 44px;
    background: #FCFCFC;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    margin-bottom: 20px;
}

.callbackButton {
    width: 100%;
    height: 44px;
    background-color: #325CEB !important;
    border-radius: 6px;
    color: white;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px !important;
    border: 1px solid #325CEB !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
}


#contactmeModal .modal-content .modal-header .close{
    margin-right: 20px;
}

#contactmeModal .modal-content{
    border: none;
}

#contactDoneModal .modal-footer{
    border: none
}

.connectionForm{
    background-color: #F8F9FE;
}

.connectionFormTitle{
    font-size: 36px;
    font-weight: 600;
    color: #000;
    line-height: 42px;
    font-style: normal;
}

.connectionSubTitle{
    font-size: 16px;
    font-weight: 400;
    color: #222;
    line-height: normal;
    font-style: normal;
    width: 400px;
    text-align: left;
}

.connectionFormWraper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 40px 0;

}

.homebuyerImage{
    width: 100%;
    height: 609px;
}

.edtableComponents .countyEditable{
    width: 400px;
}

.homeSoldValueBreakdown .ant-collapse-header{
    padding: 0 !important;
}

.homeSoldValueBreakdown .ant-collapse{
    padding: 0 !important;
}

.homeInitialValueBreakdown .ant-collapse-header{
    padding: 0 !important;
}

.homeInitialValueBreakdown .ant-collapse{
    padding: 0 !important;
}

.homeInitialValueBreakdown{
    width: 45%;
}
.homeSoldValueBreakdown{
    width: 45%;
}

#stackedBarChart{
    width: 100%;
    height: 240px;
    background-color: #F8F9FE;
    margin-bottom: 20px;
    margin-top: 20px;
}

.chartHeader{
    font-size: 20px;
    font-weight: 500;
    color: #6E6E70;
    line-height: normal;
    font-style: normal;
}

.chartHeader .chartHeaderValue{
    font-size: 24px;
    font-weight: 600;
    color: #333;
    line-height: normal;
    font-style: normal;
}

.chartDetailsValue{
    font-size: 16px;
    font-weight: 500;
    color: #222;
    line-height: normal;
    font-style: normal;
}

.chartDetailsSub{
    font-size: 12px;
    font-weight: 500;
    color: #6E6E70;
    line-height: normal;
    font-style: normal;

}

.affordabilityHeader .hfaSubTitle{
    width: 750px;
    line-height: 26px;
    margin-top: 30px;
}

.steps-list b{
    font-weight: 600;
}

.educationLinks{
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}

.affordabilityScrollBars{
    padding-left: 0;
    margin-top: 5px;
}

.zeitroDisclaimer{
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    color:#6E6E70;
    font-weight: 400;
}

.hfaLogo{
    margin-left: 60px;
}
.calhfa-GMCCFooter{
    padding: 0px 30px 20px 30px;
}

@media (max-width: 768px) {
    .hfaQualification {
        display: block;
        padding: 0 30px;
    }
    .hfaQualificationPart1 {
        height: 150px;
        width: 100%;
        clip-path: polygon(0% 0%, 100% 0%, 100% 93%, 50% 100%, 0% 93%);
        border-radius: 8px;
        padding: 15px;
    }
    .hfaQualificationPart2 {
        height: 150px;
        width: 100%;
        clip-path: polygon(0% 0%, 50% 7%, 100% 0%, 100% 93%, 50% 100%, 0% 93%);
        padding: 15px;
    }
    
    .hfaQualificationPart3 {
        height: 150px;
        width: 100%;
        clip-path: polygon(0% 0%, 50% 7%, 100% 0%, 100% 100%, 0% 100%);
        background-color: #D9D9D9;
        border-radius: 8px;
        color: white;
        padding: 65px 10px 0 30px;
        text-align: center;
    }
    .hfaSelect {
        width: 100%;
    }
    .hfaTitle {
        font-size: 30px;
        line-height: 45px;
    }
    .hfaLoanDetailsChartWrap {
        display: flex;
        justify-self: center;
        min-height: 230px;
    }
    .hfaLoanNetChartWrap {
        display: flex;
        min-height: 300px;
        justify-content: center;
    }
    .hfaNetSheet {
        padding: 25px 18px;
        font-size: 13px !important;
    }
    .hfaSubText {
        font-size: 12px;
    }
    
    .connectionFormTitle{
        font-size: 20px;
    }
    
    .connectionSubTitle{
        font-size: 14px;
    }

    .topbanner .hfaSubText{
        width: 100%;
        font-size: 16px;
    }

    .bannerContentWraper{
        flex-direction: column;
        width: auto;
    }

    .affordabilityHeader .hfaSubTitle{
        width: 100%;
        line-height: 30px;
        
    }

    .loansDetailsWraper{
        flex-direction: column;
    }

    .homeValueWraper{
        flex-direction: column;
    }

    .breakDownEditables{
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .topbanner{
        background-color: #F6F6F6;
        padding: 40px 40px 0px 40px
    }

    .topbanner .hfaSubTitle{
        margin-bottom: 20px;
    }

    .topbanner .hfaSubText{
        margin-bottom: 20px;
    }

    .bannerIconWraper{
        display: none;
    }

    .hfaQualificationPart{
       max-width: 100% !important;
    }

    .hfaQualification{
        flex-direction: column;
    }

    .affordabilityDisclaimer{
        margin-bottom: 20px;
    }

    .affordabilityScrollBars{
        padding: 0;
        margin-top: 10px;
    }
    
    .hfaTab div:first-child{
        padding-left: 20px;
        border-right: none;
    }

    .hfaTab{
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .homeInitialValueBreakdown{
        width:auto;
        margin-bottom: 60px;
    }
    .homeSoldValueBreakdown{
        width:auto;
    }
    .callbackInput {
        width: 100%;
    }

    .callbackSubTitle{
        width: 100%;
    }

    .callbackButton {
        width: 100%;
    }


    .hfaBreakdownSubText{
        font-size: 12px;
        line-height: 18px;
        color: #586570;
        font-weight: 500;
        font-style: normal;
    }

    .hfaBreakdownSubDetails{
        font-size: 12px;
    }

    .overAffordText{
        margin-bottom: 20px;
    }

}

@media (max-width: 1040px){
    .hfaLogo{
        display: none;
    }

}