.application-form .experience-add-btn {
    font-size: 16px;
    color: #325CEB;
    font-weight: bold;
    display: flex;
    align-items: center;
    height: 56px;
    line-height: 1;
    padding: 0 42px;
    border-radius: 8px !important;
}
.application-form .experience-add-btn .add-icon{
    font-size: 16px;
    margin-right: 14px;
}
.experience-collapse {
    border-radius: 8px;
    border: 1px solid #CFD4D9;
    padding: 0 36px 36px;
    margin-bottom: 12px;
}
.experience-collapse.collapse-open {
    height: auto;
}
.experience-collapse.collapse-open .experience-collapse-title {
    color: #325CEB;
}
.experience-collapse.collapse-open .experience-collapse-title .experience-collapse-icon {
    transform: rotate(-180deg);
    color: #1F3988;
}
.experience-collapse.collapse-close {
    height: 56px;
    overflow: hidden;
}
.experience-collapse-title {
    color: #1F3988;
    padding: 18px 0;
    display: flex;
    justify-content: space-between;
}
.experience-collapse .item-btn {
    width: 188px;
    height: 44px;
    font-size: 16px;
    font-weight: bold;
}
.experience-collapse .item-btn.delete {
    color: #325CEB;
    border: 1px solid #325CEB;
    margin-right: 20px;
    border-radius: 4px;
    background-color: white;
}
.experience-collapse .item-btn.done {
    background-color: #325CEB;
    border-radius: 4px;
}
.experience-collapse-title span {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
}
.experience-collapse-title .experience-collapse-icon{

}
.experience-modal .ant-modal-header {
    margin-bottom: 12px;
}
.experience-modal .ant-modal-header .ant-modal-title {
    color: #6E6E70;
    font-size: 20px;
}
.experience-modal .ant-modal-content{
    padding: 44px 52px 36px;
}
.experience-modal .ant-modal-footer{
    margin-top: 80px;
    text-align: left;
}
.experience-modal .ant-modal-footer .add-select {
    width: 188px;
    height: 44px;
    font-size: 16px;
    color: #FFF;
    font-weight: bold;
    background-color: #325CEB;
}
.experience-modal .ant-modal-footer .skip-step {
    width: 184px;
    font-size: 16px;
    font-weight: bold;
    color: #325CEB;
}
.experience-modal .ant-modal-content .ant-modal-close {
    display: none;
}
.experience-modal .experience-modal-content .content-title{
    font-size: 24px;
    font-weight: bold;
    color: #000;
    line-height: normal;
    margin-bottom: 11px;
}
.experience-modal .experience-modal-content .content-sub-title{
    color: #6E6E70;
    font-size: 14px;
    margin-bottom: 35px;
}
.experience-modal-card-list {
    max-height: 410px;
    overflow: auto;
}
.experience-modal-card-list .experience-modal-card-item {
    display: flex;
    padding: 18px 34px 15px 18px;
    width: 60%;
    border-radius: 4px;
    border: 1px solid #ECECEC;
    background: #FFF;
    cursor: pointer;
}
.experience-modal-card-list .experience-modal-card-item.select {
    border: 1px solid #325CEB;
}
.experience-modal-card-list .experience-modal-card-item.select .loan-check {
    background-color: #325CEB;
}
.experience-modal-card-list .experience-modal-card-item:not(:last-child) {
    margin-bottom: 16px;
}
.experience-modal-card-list .experience-modal-card-item .loan-check {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #ECECEC;
    margin-right: 26px;
}
.experience-modal-card-list .experience-modal-card-item .loan-content {
    flex: 1;
}
.experience-modal-card-list .experience-modal-card-item .loan-amount{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #333;
    margin-top: 12px;
}

input[disabled] {
    pointer-events: none;
}
