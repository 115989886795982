/* @font-face {
    font-family: 'Smooch';
    src: url(../../fonts/Smooch-Regular.ttf) format('truetype');
} */

.pdf-preview-main {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10;
}
.close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 20px;
    width: 200x;
}
.top-nav {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #000;
    z-index: 11;
}

.bottom-nav {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #000;
    z-index: 11;
    gap: 40px;
}

.download-btn,
.submit-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    width: 200px;
    height: 40px;
    cursor: pointer;
}

.pdf-container {
    position: fixed;
    width: 100%;
    top: 60px;
    height: calc(100% - 120px);
    overflow-y: scroll;
    z-index: 12;
}

.pdf-container .spin-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    color: white;
}

.page-sign-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.sign-mark, .sign-mark-date {
    display: flex;
    align-items: center;
    color: darkblue;
}

.sign-mark {
    font-size: 26px;
}

.sign-mark:hover, .sign-mark-date:hover {
    background: lightblue;
    opacity: 0.3;
    cursor: pointer;
}

/* .sign-pad {
    z-index: 100;
    bottom: 0;
    background: #f6f6f6;
    padding: 23px;
} */

.cancel-button,
.insert-button {
    width: 148px;
    height: 44px;
    border-radius: 4px;
    font-weight: 600;
}
.cancel-button {
    border: 1px solid #325ceb;
    color: #325ceb;
}
.sign-pad .action-button-group {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.insert-button {
    margin-left: 10px;
}
.sign-thumb-wrapper {
    display: flex;
}
.sign-thumb {
    background: #325ceb;
    cursor: pointer;
    color: white;
    width: 100px;
    height: 40px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;

}
.sign-thumb-triangle {
    cursor: pointer;
    background: #325ceb;
    width: 40px;
    height: 40px;
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    margin-left: -20px;
}
.signature-terms-modal .ant-modal-footer {
    text-align: center;
}
.sign-pad .signature-terms-link {
    text-decoration: underline;
}

.request-confirm-wrap{
    font-size: 16px;
}

.request-confirm-title{
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 4rem !important;
    gap: 20px;
}

.request-confirm-modal{
    width: 920px !important;
    height: 100%;
    position: static;
    padding-bottom: 0;
}

.request-confirm-modal > div[tabindex='-1']{
    height: 100%;
}

.request-confirm-modal .ant-modal-content{
    padding: 5rem 8rem 0rem 8rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.bottomSpaceAdjuster{
    margin-bottom: 2rem;
}

.request-confirm-modal .ant-checkbox-wrapper{
    font-size: 14px;
    font-weight: 500;
}

.verification-code{
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 10px;
}

.confirmBtn{
    font-size: 16px;
    color: white !important;
    width: 220px;
    height: 45px;
}

.confirmBtnDisabled{
    width: 220px;
    height: 45px;
    font-size: 16px;
    color: white !important;
    background-color: #ccc !important;
    border: 1px solid #ccc !important;
}