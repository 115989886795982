
@media (max-width: 767px) {
    .loan-rate-calculator .calc-tabs .ant-tabs-tab-btn {
      font-size: 16px !important;
    }
}

.loan-rate-calculator .ant-tabs .ant-tabs-nav{
    margin: 0;
}
.loan-rate-calculator .calc-tabs .ant-tabs-nav-list {
    width: 100%;
}
.loan-rate-calculator .calc-tabs .ant-tabs-tab {
    flex: 1;
    display: flex;
    justify-content: center;
    background-color: #F5F5F7 ;
}
.loan-rate-calculator .calc-tabs >.ant-tabs-nav .ant-tabs-nav-operations {
    display: none !important;
}
.loan-rate-calculator .calc-tabs .ant-tabs-tab-btn {
    font-size: 16px;
    color: #1F3988;
    font-weight: 600;
}
.loan-rate-calculator .calc-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #21272A;
}
.loan-rate-calculator .calc-tabs .ant-tabs-tab-active {
    background: #EAEFFD;
    border-bottom-color: #EAEFFD !important;
}
.loan-rate-calculator .form-wrap {
    overflow: hidden;
}
.loan-rate-calculator .form-wrap .addr-input {
    width: 100%;
    padding: 7px 11px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    transition: all 0.2s;
    height: 44px;
}
.loan-rate-calculator .form-wrap .addr-input:hover {
    border-color: #4096ff;
    border-inline-end-width: 1px;
}
.loan-rate-calculator .form-wrap .addr-input:focus {
    border-color: #4096ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    border-inline-end-width: 1px;
    outline: 0;
}
.loan-rate-calculator .form-wrap .ant-form >.ant-row {
    padding: 0 50px;
}
.loan-rate-calculator .form-wrap .ant-form >.ant-row:nth-child(odd) {
    background-color: #EAEFFD;

}
.loan-rate-calculator .form-wrap .ant-form >.ant-row:nth-child(even) {
    background-color: #EAEFFD;
}
.loan-rate-calculator .form-wrap .ant-form >.ant-row:nth-child(2) {
    border-bottom: 1px solid #A4B7F8;
}
.loan-rate-calculator .form-wrap .ant-form >.ant-row:nth-child(4) {
    border-bottom: 1px solid #A4B7F8;
    padding-bottom: 16px;
}
.loan-rate-calculator .form-wrap .ant-form >.ant-row:nth-child(3) {
    padding-top: 16px;
}
.loan-rate-calculator .form-wrap .ant-form >.ant-row:nth-child(5) {
    padding-top: 16px;
}
.loan-rate-calculator .form-wrap .ant-form .ant-form-item-label {
    padding: 0;
}
.loan-rate-calculator .form-wrap .ant-form .ant-form-item-label >label {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    height: 46px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.loan-rate-calculator .ant-input-number-affix-wrapper-lg {
    border-radius: 4px;
}


.loan-rate-calculator .form-wrap .ant-form .tooltip-item .ant-form-item-label label{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.loan-rate-calculator .form-wrap .ant-form .ant-form-item .ant-form-item-label >label .ant-form-item-tooltip {
    cursor: auto;
}
.loan-rate-calculator .form-wrap .ant-form .tooltip-item .ant-form-item-label label::after {
    display: none;
}
.loan-rate-calculator .form-wrap .tips-icon {
    font-size: 16px;
    color: #697077;;
}
.loan-rate-calculator .form-wrap .check-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    font-size: 16px;
    color: #000;
    font-weight: bold;
}
.loan-rate-calculator .form-wrap .score-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}
.loan-rate-calculator .form-wrap .score-item .label {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    margin-right: 16px;
}
.loan-rate-calculator .form-wrap .score-item .ant-form-item {
    margin-bottom: 0;
    flex: 1;
}
.loan-rate-calculator .form-wrap .score-item .num {
    font-size: 16px;
    color: #21272A;
}
.loan-rate-calculator .form-wrap .score-slider {
    flex: 1;
    margin: 0 12px;
}

.loan-rate-calculator .form-wrap .ant-slider .ant-slider-handle::before {
    inset-inline-start: -5px;
    inset-block-start: -5px;
    width: 20px;
    height: 20px;
}
.loan-rate-calculator .form-wrap .ant-slider .ant-slider-handle::after {
    box-shadow: 0 0 0 4px #0F62FE;
    width: 12px;
    height: 12px;
    inset-inline-start: -1px;
    inset-block-start: -1px;
    background-color: #0F62FE;
}
.loan-rate-calculator .form-wrap .closing-date {
    width: 100%;
}
.loan-rate-calculator .form-wrap .calc-btn {
    height: 44px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    color: #0F62FE;
    border: 2px solid #0F62FE;
    margin-top: 10px;
}