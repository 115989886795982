.prequalInterviewTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.2px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #333333;
    white-space: normal
    
}
.hardmoneyloaninterview {
    background-color: #F6F6F6;
    height: 100vh !important
}

.hardmoneyloaninterview .sidenavPrequal{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
}

.hardmoneyloaninterviewpane {
    padding: 30px 80px 30px 40px;
    background-color: white;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.ant-input-number {
    border-radius: 4px;
}
.ant-input-number-group-addon {
    border-radius: 4px;
}
:where(.css-dev-only-do-not-override-sk7ap8).ant-input-number-group-wrapper-lg .ant-input-number-group-addon {
    border-radius: 4px;
}
.addAddress {
    background: #F7F8FC;
    border-radius: 8px;
    padding: 0 15px 15px 15px;

}

.prequalInterviewSubTitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #6E6E70;
}

.prequalInterviewH3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}


.prequalInterviewDisclaimer {
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    white-space: pre-wrap;

}

@media (max-width: 480px) {
	.prequalInterviewDisclaimer {
		font-size: 12px;

	}
}