.fix-container {
    width: 100%;
    background-color: #F5F5F7;
    padding: 0.75rem 0.625rem 0.5rem 1.125rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #D2D2D4;
}

.left-dec {
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1rem;
}

.left-price {
    margin-top: 0.5rem;
    font-family: Inter;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.44rem;
}

.btn-preapprvoal {
    padding: 0.75rem 1.125rem !important;
    border: none !important;
    border-radius: 2px !important;
    color: #fff !important;
    background-color: #325CEB !important;
    font-weight: 500 !important;
    line-height: 1.1875rem !important;
}

.btn-preapprvoal:hover {
    background: #284ABC !important;
    color: #fff !important;
}

.fix-main {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0 0 1.125rem;
    background-color: #F5F5F7;
    /* border-bottom: 1px solid #D2D2D4; */
}

.main-left {
    display: flex;
    width: 18.625rem;
    flex-direction: column;
}

.left-list {
    display: flex;
    justify-content: space-between;
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
}

.list-price {
    font-weight: 500;
}

.right-icon {
    /* width: 50px;
    height: 40px;
    text-align: center; */
    position: absolute;
    /* left: 95.53%; */
    right: 5%;
}

.min-main {
    display: flex;
    align-items: center;
    padding: 0 1.125rem;
    height: 2.5rem;
    font-size: 0.875rem;
    background-color: #F5F5F7;
    border-bottom: 1px solid #D2D2D4;
}

.border-bottom {
    border-bottom: 1px solid #D2D2D4;
}

.min-main-icon {
    float: right;
    margin-right: 1.875rem;
}

.sticky {
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    z-index: 9999;
}

.newSlider {
    background-color: #F5F5F7 !important;
    padding: 1rem 2.1875rem !important;
    margin-top: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid #D2D2D4 !important;
}

.sliderBorder {
    border: 1px solid #CFD4D9;
    border-radius: 8px;
    padding: 0 10px;
    background-color: #fff;
}

.header-get-started {
    background-color: #325CEB !important;
    border: 1px solid #325CEB !important;
    border-radius: 4px;
    color: white;
   
    padding-top: 5px;
}