.currentTasksWrap .ant-collapse .ant-collapse-item:last-child {
    border-bottom: 1px solid #dbdcde !important;
}
.currentTasksWrap .ant-table .ant-table-cell-fix-right {
    background-color: #fff !important;
    z-index: 0;
}
.currentTasksWrap
    .task-table
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
}
.currentTasksWrap .task-table .ant-table-thead > tr > .ant-table-cell {
    padding: 12px 16px;
    color: #545459;
    background-color: #fff;
    border-bottom: 2px solid #6e6e70;
}
.currentTasksWrap
    .task-table
    .ant-table-tbody
    .ant-table-expanded-row
    .ant-table-cell {
    border-top: none;
    background-color: #f7f8fc;
}
.currentTasksWrap .task-status {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: 500;
    padding: 0 12px;
    border-radius: 15px;
}
.currentTasksWrap .task-status.pending {
    background-color: #fff2c5;
    color: #8d5013;
}
.currentTasksWrap .task-status.closed {
    background-color: #e9f4eb;
    color: #2e6333;
}
.currentTasksWrap .expanded-content .submit-btn {
    width: auto;
    background-color: #325ceb;
    color: #fff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    padding: 0 28px;
}
.currentTasksWrap .expanded-content .item-table .document-item {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #cfd4d9;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.currentTasksWrap .expanded-content .item-table .document-item .document-info {
    width: 76%;
}
.currentTasksWrap .expanded-content .item-table .sign-document {
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #cfd4d9;
    display: flex;
    align-items: center;
    padding: 16px 20px 16px 22px;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .sign-document:not(:last-child) {
    margin-bottom: 8px;
}
.currentTasksWrap .expanded-content .item-table .sign-document .sign-name {
    width: 50%;
}
.currentTasksWrap .expanded-content .item-table .sign-document .sign-duedate {
    width: 20%;
    font-size: 16px;
    color: #222;
}
.currentTasksWrap .expanded-content .item-table .sign-document .sign-file {
    width: 30%;
    display: flex;
    justify-content: end;
}
.currentTasksWrap .expanded-content .item-table .sign-document .sign-btn {
    width: 86px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    color: #325ceb;
    background-color: #f7f8ff;
    border: 1px solid #e3eafd;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 14px;
}
.currentTasksWrap .expanded-content .item-table .upload-file {
    width: 150px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    font-weight: 600;
    padding: 0 5px;
    background-color: #fcfcfc;
    border: 1px solid #ececec;
    border-radius: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.currentTasksWrap .expanded-content .item-table .upload-file img {
    width: 22px;
    height: 22px;
    margin-right: 6px;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .document-item
    .document-info
    .info-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .document-item
    .document-info
    .info-top
    > div {
    flex: 1;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .document-item
    .document-info
    .info-top
    > span {
    font-size: 16px;
    font-weight: 500;
    color: #6e6e70;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .document-item
    .document-info
    .doc-file {
    padding: 10px 12px;
    background-color: #fcfcfc;
    border: 1px solid #ececec;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .document-item
    .document-info
    .doc-file
    .file {
    display: flex;
    align-items: flex-start;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .document-item
    .document-info
    .doc-file
    .file
    .file-info {
    margin-left: 12px;
    font-size: 14px;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .document-item
    .document-info
    .doc-file
    .file
    .file-info
    .file-name {
    font-weight: 600;
    color: #222;
    margin-bottom: 2px;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .document-item
    .document-info
    .doc-file
    .file
    .file-info
    .file-size {
    color: #6e6e70;
}
.currentTasksWrap
    .expanded-content
    .item-table
    .document-item:not(:last-child) {
    margin-bottom: 8px;
}
.upload-file-modal .upload-modal-title {
    font-family: 'Playfair Display' !important;
    font-size: 24px;
    font-weight: 500;
}
.upload-file-modal .upload-file-list {
    padding: 36px 40px 160px;
}
.upload-file-modal .upload-file-list .upload-file-item {
    padding: 10px 16px 10px 12px;
    background-color: #fcfcfc;
    border: 1px solid #ececec;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.upload-file-modal .upload-file-list .upload-file-item .file {
    display: flex;
    align-items: flex-start;
}
.upload-file-modal .upload-file-list .upload-file-item .file .file-info {
    margin-left: 12px;
    font-size: 14px;
}
.upload-file-modal
    .upload-file-list
    .upload-file-item
    .file
    .file-info
    .file-name {
    font-weight: 600;
    color: #222;
    margin-bottom: 2px;
    line-height: 20px;
}
.upload-file-modal
    .upload-file-list
    .upload-file-item
    .file
    .file-info
    .file-size {
    color: #6e6e70;
    line-height: 20px;
}
.upload-file-modal .file-upload .ant-upload-drag {
    background-color: #fcfcfc;
    border: 1px dashed #ececec;
}
.upload-file-modal .ant-upload-text {
    margin-bottom: 10px;
}
.upload-file-modal .ant-upload-hint {
    font-size: 16px;
    color: #222;
}
.upload-file-modal .ant-modal-content {
    padding: 0;
}
.upload-file-modal .ant-modal-content .ant-modal-footer {
    padding: 0 40px 22px;
}
.upload-file-modal .upload-btn-cancel {
    font-size: 16px;
    font-weight: 700;
    width: 130px;
    height: 44px;
    color: #325ceb;
    border: 1px solid #325ceb;
}
.upload-file-modal .upload-btn-save {
    font-size: 16px;
    font-weight: 700;
    width: 130px;
    height: 44px;
    color: #fff;
}
.upload-file-modal .upload-btn-save:disabled {
    border-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: rgba(0, 0, 0, 0.04) !important;
}
.file-icon {
    width: 24px;
    height: 24px;
}
.currentTasksWrap .task-content .task-collapse .task-collapse-header {
    display: flex;
    align-items: center;
    font-family: Inter;
    font-size: 16px;
    color: #222;
    line-height: 20px;
}
.currentTasksWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-type {
    width: 25%;
    font-size: 16px;
    font-weight: 500;
    color: #222;
}
.currentTasksWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-update {
    width: 35%;
}
.currentTasksWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-completed {
    display: flex;
    align-items: center;
    font-weight: 500;
    width: 25%;
}
.currentTasksWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-completed
    .task-progress
    .ant-progress-inner {
    font-size: 14px !important;
    width: 36px !important;
    height: 36px !important;
}
.currentTasksWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .task-completed
    .task-completed-num {
    margin-left: 8px;
}
.currentTasksWrap
    .task-content
    .task-collapse
    .task-collapse-header
    .no-tasks-text {
    color: #6e6e70;
}
.currentTasksWrap .task-content .task-collapse .task-collapse-header .task-add {
    color: #325ceb;
    font-weight: 600;
    cursor: pointer;
    width: 15%;
    text-align: right;
    padding-right: 10px;
}
.currentTasksWrap .task-content .task-collapse {
    background-color: #fff;
    border: none;
}
.currentTasksWrap .task-content .task-collapse .task-collapse {
    background-color: #fff;
}
.task-collapse > .ant-collapse-item {
    border: 1px solid #dbdcde;
    transition: all 0.3s;
    margin-bottom: 12px;
    border-radius: 8px !important;
    overflow: hidden;
}
.task-collapse > .ant-collapse-item:hover {
    box-shadow: 0px 4px 8px 0px rgba(7, 13, 61, 0.12);
}

.task-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 18px 16px;
    align-items: center;
}
.task-collapse .ant-collapse-content {
    background-color: #f7f8fc;
    padding: 0 16px;
}
.task-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px 0;
}

.taskPageWrap {
    padding: 45px;
}

@media (max-width: 575px) {
    .taskPageWrap {
        margin: -20px !important;
    }
}
@media (max-width: 767px) {
    .currentTasksWrap
        .task-content
        .task-collapse
        .task-collapse-header
        .task-completed
        .task-completed-num {
        display: none;
    }
    .currentTasksWrap .task-content .task-collapse .task-collapse-header {
        font-size: 12px;
    }
    .currentTasksWrap
        .task-content
        .task-collapse
        .task-collapse-header
        .task-type {
        width: 40%;
    }
    .currentTasksWrap
        .task-content
        .task-collapse
        .task-collapse-header
        .task-update {
        width: 40%;
    }
    .currentTasksWrap
        .task-content
        .task-collapse
        .task-collapse-header
        .task-completed {
        width: 20%;
    }
}
