.applicationSidebarWrap {
    width: 64px;
    border-right: 1px solid #CFD4D9;
}

.application-content {
    min-height: auto !important;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.income-application .income-title {
    color: #6E6E70;
    font-weight: 500;
    line-height: 20px;
}

.money-input-container .form-control,
.money-input-container .form-control:focus,
.money-input-container .form-control:hover,
.money-input-container .form-control:active,
.money-input-container .form-control.is-invalid,
.money-input-container .form-control:focus.is-invalid,
.money-input-container .form-control:focus-visible,
.money-input-container .form-control:focus-within,
.money-input-container .form-control:focus:hover,
.money-input-container .form-control:focus-within:hover,
.money-input-container .form-control:active:hover {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
}

.applicant-tab {
    display: flex;
    height: 48px;
    padding: 0 30px;
    background: #F6F6F6;
    margin: 18px -30px 20px -30px;
}

.applicant-tab .applicant-tab-active {
    border-bottom: 2px solid #325CEB;
}

.applicant-tab .applicant-tab-active .applicant-tab-name {
    color: #325CEB;
    font-weight: 600;
}

.applicant-tab>div {
    margin-right: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}

.applicant-tab .applicant-tab-title {
    font-size: 12px;
    color: #6E6E70;
    font-family: Roboto !important;
    line-height: 20px;
}

.applicant-tab .applicant-tab-name {
    font-size: 16px;
    color: #222;
    font-family: Roboto !important;
    height: 20px;
    line-height: 20px;
}

.pac-container {
    z-index: 999999;
}

.income-after-unit {
    font-size: 16px;
    color: #6E6E70;
    position: absolute;
    right: 32px;
    top: 10px;
}

.employment-list {
    margin-bottom: 12px;
}

.employment-list .employment-item {
    padding: 0 0 15px 0;
    border-radius: 8px;
    border: 1px solid #DBDCDE;
    margin-top: 10px;
    position: relative;
}

.employment-list .income-item {
    /* padding: 16px 24px 15px 13px; */
    border-radius: 8px;
    border: 1px solid #ECECEC;
    margin-top: 10px;

}

.employment-list .employment-item .employment-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* flex-wrap: wrap; */
    font-size: 16px;
    padding: 15px 21px 12px;
    border-radius: 8px 8px 0px 0px;
}

.employment-list .employment-item .employment-top>div {
    display: flex;
    align-items: center;
}

.employment-top .employment-icon {
    padding: 2px;
    border-radius: 6px;
    background: #F5F5F7;
    margin-right: 11px;
}
.employment-top .employment-top-l {
    flex: 1;
}
.employment-top .employment-top-l .employment-name {
    color: #6E6E70;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.primary-employment-tag {
    display: flex;
    align-items: center;
    height: 24px;
    font-family: Inter;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px; 
    padding: 0px 6px;
    color: #13358D;
    background: #D8EAFF;
    border-radius: 6px 0px 0px 6px;
    position: absolute;
    right: 0;
    top: 15px;
}
.employment-top .employment-top-r .primary-employment-tag-mobile {
    display: none;
}

.employment-edit span {
    color: #325CEB;
    border-bottom: 1px solid #325CEB;
}

.employment-delete span {
    color: #EB3232;
    border-bottom: 1px solid #EB3232;
}

.employment-form {
    padding-left: 22px;
}

.employment-form .employment-form-item {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
}

.employment-form .employment-form-item .employment-form-label {
    width: 150px;
    color: #62707C;
    font-size: 16px;
    font-weight: 400;
}

.employment-form .employment-form-item .employment-form-value {
    color: #222222;
    flex: 1;
    min-width: 0;
    white-space: initial;
    word-break: break-word;
    font-size: 16px;
    font-weight: 400;
}

.add-job {
    cursor: pointer;
    color: #325CEB;
    font-size: 16px;
    display: flex;
    align-items: center;
}

.add-job span {
    margin-left: 8px;
    font-weight: 500;
    line-height: 20px;
}

.employment-modal .modal-dialog {
    max-width: 860px;
    margin: 0 auto !important;
}

.employment-input .input-group-prepend .input-group-text,
.income-input .input-group-prepend .input-group-text {
    background-color: #FCFCFC !important;
    border: 1px solid #ECECEC;
}

.employment-input .input-group>.form-control {
    border-left: none;
    border-right: none;
    border-radius: 0;
    background-color: #FCFCFC;
    border-color: #ECECEC;
}

.income-input .input-group>.form-control {
    border-left: none;
    border-radius: 0;
    background-color: #FCFCFC;
    border-color: #ECECEC;
}

.employment-input .form-control:hover,
.income-input .form-control:hover {
    border: 1px solid #ECECEC !important;
    border-left: none !important;
    border-radius: 0 !important;
}

.incdropdown .btn-dropdown {
    background-color: #FCFCFC !important;
    color: #6E6E70 !important;
    border-color: #ECECEC;
}

.income-type-select {
    background-color: #FCFCFC;
    border-color: #ECECEC;
    /* border-right: none; */
}

.income-save-btn {
    border-radius: 6px !important;
}

.income-input .form-control:focus {
    background-color: transparent !important;
}

.employment-label {
    color: #62707C;
    font-weight: 500;
    margin-bottom: 6px;
}

.employment-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #FCFCFC;
    border: 1px solid #ECECEC;
}

.employment-autocomplete label.form-label {
    color: #62707C;
    font-weight: 500;
    margin-bottom: 6px;
}

.application-review .review-status {
    padding: 6px 15px 10px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #E9F0FD;
    background-color: #F4F7FE;
    margin-bottom: 26px;
}

.application-review .review-status img {
    width: 44px;
    height: auto;
    margin-right: 20px;
}

.application-review .review-status span {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #1F3988;
}

.application-review .review-collapse {
    padding: 10px 24px 0;
    border-radius: 8px;
    border: 1px solid #ECECEC;
    margin-bottom: 32px;
    overflow: hidden;
}

.application-review .review-collapse .review-collapse-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.application-review .review-collapse .review-collapse-header .header-logo {
    width: 44px;
    height: 44px;
    border-radius: 8px;
    background: #F5F5F7;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
}

.application-review .review-collapse .review-collapse-header .header-content {
    flex: 1;
    padding: 11px 0;
    display: flex;
    justify-content: space-between;
}

.application-review .review-collapse .review-collapse-header .header-content.expand {
    border-bottom: 1px solid #ECECEC;
}

.application-review .review-collapse .review-collapse-header .header-content .review-type {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2px;
}

.application-review .review-collapse .review-collapse-header .header-content .review-addr {
    color: #6E6E70;
    font-size: 16px;
    line-height: 24px;
}

.application-review .review-collapse .review-collapse-header .header-content .review-application-status {
    color: #6E6E70;
    text-align: right;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 6px;
}

.application-review .review-collapse .review-collapse-header .header-content .review-editTime {
    color: #6E6E70;
    font-size: 14px;
    line-height: normal;
}

.application-review .review-collapse .review-collapse-header .header-content .header-content-r {
    display: flex;
    align-items: center;
}

.application-review .review-collapse .review-collapse-header .header-content .header-content-r img {
    width: 20px;
    height: 20px;
    margin-left: 12px;
    transition: all .3s;
}

.application-review .review-collapse .review-collapse-header .header-content .header-content-r .iconExpand {
    transform: rotate(-180deg);
}

.application-review .review-collapse .review-collapse-content {
    padding: 0 0 0 56px;
    height: 0;
    transition: all .3s;
}

.application-review .review-collapse .review-collapse-content.expand {
    height: 192px;
    margin-top: 18px;
}

.review-collapse-content .review-list .review-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.review-collapse-content .review-list .review-list-item:not(:last-child) {
    margin-bottom: 16px;
}

.review-collapse-content .review-list .review-list-item img {
    width: 12px;
    height: 12px;
    margin-right: 8px;
}

.review-collapse-content .review-list .review-list-item>div {
    display: flex;
    align-items: center;
}

.review-collapse-content .review-list .review-list-item .review-name {
    color: #333;
    font-size: 16px;
    line-height: normal;
}

.review-collapse-content .review-list .review-list-item .review-btn {
    cursor: pointer;
    color: #325CEB;
    font-size: 16px;
    line-height: normal;
    border-bottom: 1px solid #325CEB;
}

.application-review .status-list .status-list-title {
    color: #6E6E70;
    font-size: 20px;
    margin-bottom: 20px;
}

.application-review .status-list .status-list-item {
    padding-left: 24px;
}

.application-review .status-list .status-list-item-top {
    display: flex;
    margin-bottom: 12px;
}

.application-review .status-list .status-list-item .status-order {
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: #325CEB;
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 13px;
}

.application-review .status-list .status-list-item-top .status-order.cur {
    border: 1px solid #325CEB;
    background-color: #E0E7FF;
    color: #325CEB;
}

.application-review .status-list .status-list-item .status-info {
    flex: 1;
}

.application-review .status-list .status-list-item .name {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin-right: 34px;
}

.application-review .status-list .status-list-item .status {
    color: #6E6E70;
    font-size: 14px;
}

.application-review .status-list .status-list-item .desc {
    color: #6E6E70;
    font-size: 14px;
    line-height: 20px;
}

.application-review .status-list .status-list-item .status-line {
    height: 33px;
    width: 2px;
    background-color: #325CEB;
    margin-bottom: 12px;
    margin-left: 21px;
}

.applicant-tab.residence {
    margin: 18px -45px 20px -45px;
}

.state-dropdown-menu button {
    overflow: hidden;
}

.notice-card {
    position: fixed;
    bottom: 66px;
    left: 40%;
    transform: translateX(-50%);
    width: calc(100% - 48px);
    max-width: 500px;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    padding: 12px 20px;
    z-index: 1000;
}

.notice-link-btn {
    color: #325CEB !important;
    height: 40px !important;
    padding: 8px 24px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border-radius: 6px !important;
    text-decoration: none !important;
}

.notice-link-btn:hover {
    color: #5277FF !important;
}

.notice-skip-btn {
    background-color: #EAEFFD !important;
    color: #325CEB !important;
    border: none !important;
    padding: 8px 24px !important;
    height: 40px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border-radius: 6px !important;
}

.notice-skip-btn:hover {
    background-color: #E8EDFF !important;
}

.notice-primary-btn {
    background-color: #325CEB !important;
    border: none !important;
    padding: 8px 24px !important;
    height: 40px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border-radius: 6px !important;
}

.notice-primary-btn:hover {
    background-color: #5277FF !important;
}

.property-info-content {
    display: flex;
    flex-direction: column;
}
.property-section {
    padding: 16px 16px 16px 50px;
}
.property-section-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    color: #6E6E70;
    margin-bottom: 12px;
}

.property-info-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 8px;
    white-space: wrap !important;
}

.property-info-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #62707C;
    margin-right: 12px;
}

.property-info-value {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #222222;
}

.property-section-divider {
    height: 1px;
    background-color: #DBDCDE;
    margin: 16px 0;
}

.mortgage-loan-card {
    background: #F6F6F6;
    border-radius: 8px;
    border: 1px solid #DBDCDE;
    padding: 12px 20px;
    margin-bottom: 16px;
    white-space: wrap !important;
}

.mortgage-loan-title {
    font-size: 16px;
    font-weight: 500;
    color: #222222;
    margin-bottom: 12px;
    line-height: 20px;
}

.mortgage-info-grid {
    display: grid;
    grid-template-columns: 300px 300px;
    margin-right: 38px;
    justify-content: space-between;
}
.mortgage-loan-info {
    margin-right: -15px !important;
    margin-left: -15px !important;
}
.mortgage-info-item {
    display: flex;
    margin-bottom: 10px;
}

.mortgage-info-item:last-child {
    margin-bottom: 0;
}

.mortgage-info-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #62707C;
    margin-right: 12px;
    min-width: 126px;
}

.mortgage-info-value {
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #222222;
    text-align: left;
}

.property-modal-title {
    font-size: 20px;
    font-weight: 600;
    color: #222222;
}

.property-section-title {
    font-size: 18px;
    font-weight: 500;
    color: #6E6E70;
    white-space: wrap !important;
}

.property-mortgage-header {
    background: #F5F5F7;
    padding: 9px 16px;
    border-bottom: 1px solid #DBDCDE;
    display: flex;
    align-items: center;
}

.add-additional-mortgage-btn {
    color: #325CEB;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px;
}

.mortgage-checkbox .form-check-label {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #222222;
}

.property-edit-button {
    border: 1px solid #325CEB;
    border-radius: 6px;
    padding: 9px 18px;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #325CEB;
    background-color: #FFFFFF;
}

/* 针对WebKit内核的浏览器 (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 12px;  /* 滚动条的宽度 */
}

::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #f1f1f1;  /* 滚动条轨道背景 */
}

::-webkit-scrollbar-thumb {
    background-color: #888;  /* 滚动条的颜色 */
    border-radius: 6px;  /* 滚动条圆角 */
    border: 3px solid #f1f1f1;  /* 滚动条的边框，颜色为轨道的背景色 */
}

::-webkit-scrollbar-thumb:hover {
    background-color: #555;  /* 滚动条悬停时的颜色 */
}
.property-address.row {
    margin-left: -15px !important;
}
.stepApplication-bar {
    width: 100%;
    position: absolute;
    font-size: 20px;
    font-family: Inter;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 18px;
    background-color: #FFF;
    border-bottom: 1px solid #D9D9D9;
    z-index: 999;
    top: 55px;
}
.stepApplication-bar-icon {
    pointer-events: auto;
    transition: all .3s;
}
.stepApplication-bar-icon .icon-expand {
    transform: rotate(-180deg);
    transition: all .3s;
}
.stepApplication-bar-icon .icon-collapse {
    transform: rotate(0deg);
    transition: all .3s;
}
.topbar-menu {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -15px;
    top: 4px;
    pointer-events: auto;
}
.stepApplication-bar-drawer .ant-drawer-content-wrapper {
    top: 115px;
}
.sidebar-drawer .ant-drawer-content-wrapper {
    top: 55px;
}
.sidebar-drawer .ant-drawer-content-wrapper .ant-drawer-body {
    overflow: hidden;
}
.menu-drawer .ant-drawer-content-wrapper .ant-drawer-body {
    padding: 0;
}
.employment-list .employment-item .employment-bottom {
    display: flex;
    justify-content: space-between;
    margin: 16px 21px 0 21px;
    padding-top: 16px;
    border-top: 1px solid #D9D9D9;
}
.employment-list .employment-item .employment-bottom .ant-btn {
    height: 40px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 6px !important;
}
.employment-list .employment-item .employment-bottom .employment-bottom-left {
    display: flex;
    gap: 8px;
}
.employment-list .employment-item .employment-bottom .employment-bottom-left .employment-edit-btn {
    background-color: #F7F8FF;
    color: #325CEB;
    border: 1px solid #A9C7FC;
    padding: 0 16px;
}
.employment-list .employment-item .employment-bottom .employment-bottom-left .employment-remove-btn {
    color: #EB3232;
    padding: 0 16px;
}
.employment-list .employment-item .employment-bottom .employment-primary-btn {
    color: #325CEB;
    padding: 0 6px;
}
.employment-list .employment-item .employment-bottom .employment-primary-btn:disabled {
    color: #DBDCDE;
}
@media (max-width: 575px) {
    .application-review .review-collapse .review-collapse-content {
        padding: 0;
    }

    .application-review .review-collapse .review-collapse-header .header-logo {
        display: none;
    }

    .applicant-tab.residence {
        margin: 18px -15px 20px -15px;
    }

    .employment-form {
        padding-left: 0
    }

    .employment-form-label {
        min-width: 120px;
        width: auto !important;
    }

    .finances-type {
        padding-right: 15px;
    }

    .addressMobile {
        display: flex;
    }

    .finances-type-mobile {
        display: block;
    }
    .employment-top .employment-top-r .primary-employment-tag {
        display: none;
    }
    .loan-button-mobile {
        height: 48px;
        width: 100%;
        font-family: Inter;
        font-size: 18px;
        font-weight: 500;
        line-height: 20px;
        border-radius: 8px !important;
    }
    .employment-top .employment-top-r .primary-employment-tag-mobile {
        display: flex;
        align-items: center;
        justify-content: space-between0;
        height: 24px;
        border-radius: 12px;
        padding: 0 8px;
        background-color: #D8EAFF;
        color: #13358D;
        font-family: Inter;
        font-size: 12px;
        font-weight: 600;
    }
    .employment-list .employment-item .employment-bottom {
        margin: 16px 16px 0 16px;
    }
    .employment-list .employment-item .employment-top {
        padding: 15px 12px 12px;
    }
    .employment-form .employment-form-item {
        margin-left: 0;
        margin-right: 0;
        flex-direction: column;
    }
    .employment-form .employment-form-item  .employment-form-label-colon {
        display: none;
    }
    .property-info-row {
        flex-direction: column;
    }
    .mortgage-info-item {
        flex-direction: column;
    }
}
@media (max-width: 768px) {
    .property-section {
        padding: 16px;
    }
    .notice-card {
        left: auto;
        transform: none;
    }
    .notice-card-btn {
        flex-direction: column;
    }
}