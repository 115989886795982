.check-group-wrap {
    display: inline-flex;
    gap: 20px;
}

.check-group-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ECECEC;
    border-radius: 6px;
    padding: 0.812rem 10px 0.812rem 0;
    /* margin-bottom: 10px; */
    background: #FCFCFC;
    /* margin-top: 1rem; */
    
}

.check-group-box.flex-box {
    padding: 12px;
}

.check-group-box.flex-box .check-group-content-flex {
    display: flex;
}

.check-group-box.flex-box .check-group-content-flex .circle {
    margin-left: 0;
}

.check-group-box.flex-box .check-group-content-flex .dec {
    flex: 1;
}

.check-group-box-l {
    display: flex;
    align-items: center;
}
.ant-input-outlined:hover{
    border: 1px solid #222;

}
.check-group-box:hover {
    border: 1px solid #222;
    cursor: pointer;
}

.circle.check {
    background: #325CEB;
    border: none;
}

.check-group-box-with-image {
    width: 100%;
    background-color: #FCFCFC;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    padding: 0.812rem 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.check-group-box-with-image:hover {
    border: 1px solid #325CE9;
    cursor: pointer;
}

.circle {
    margin-left: 0.812rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    line-height: 1.25rem;
    border: 1px solid #d9d9d9;
    background-color: #fff;
}

.dec {
 
    color: #333;
    font-weight: 400;
    font-size: 1rem;
    margin-left: 7px;
    line-height: 1.25rem;
    /* width: 100%; */
    white-space: normal;
}
@media (max-width: 768px) {
    .check-group-wrap {
        flex-direction: column;
        gap: 8px;
    }
}
@media (max-width: 800px) {
    .check-group-box-with-image {
        flex-direction: row-reverse !important;
        padding: 15px 20px !important;
    }
    .check-group-box-with-image .dec-wrap {
        flex-direction: initial !important;
    }
    .check-group-box-with-image .dec-wrap .circle {
        width: 24px;
        height: 24px;
    }
    .check-group-box-with-image .dec-wrap .dec {
        width: auto !important;
        margin-left: 12px;
    }
}
@media (min-width: 800px) {
    .check-group-box-with-image .dec-wrap .circle {
        display: none;
    }
}
