/* Input style */
.floating-label-input-container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.floating-label-input-container:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: #0a53e4;
}

.floating-label-input-container .filled {
    transform: translate(0, 12px) scale(0.8);
}

.floating-label-input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #6f81a5;
    font-size: 16px;
    line-height: 1;
    left: 16px;
}

.floating-label-input-container input {
    height: 64px;
    border-radius: 4px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.floating-label-input-container input:focus {
    box-shadow: 0 0 0 2px #79b1ff;
}