@font-face {
    font-family: 'Playfair';
    src: url(../../fonts/PlayfairDisplay.ttf) format('truetype');
}

.submitted-banner {
    background: #EAEFFD;
    border-radius: 8px;
    display: flex;
    padding: 25px;
}

.submitted-banner .bannerright {
    margin-left: 25px;
}

.submitted-banner .title {
    font-family: Playfair !important;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0px;
    text-align: left;
}
.submitted-banner .subtitle {
    margin-top: 18px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
}

.over-view {

}
.over-view .over-view-content {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
}
.over-view .over-view-content .application-status {

}


.over-view .over-view-content .application-status .status-title a {
    margin-left: 10px;
    color: #1677ff;
    font-weight: 400;
}

.over-view .over-view-content .application-status .status-list {

}

.application-status .status-list .status-item .status-tag {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #DDE1E6;
    position: absolute;
    left: -8px;
    top: 0;
}

.over-view .over-view-content .my-loan-terms {
    width: 390px;
    border-radius: 8px;
    background: #F4F7FE;
    padding: 0 15px;
}
.over-view .over-view-content .my-loan-terms .loan-terms-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding: 0 10px;
    border-bottom: 1px solid #FFF;
}
.over-view .over-view-content .my-loan-terms .loan-terms-title span:first-child {
    font-weight: 500;
}
.over-view .over-view-content .my-loan-terms .loan-terms {
    padding: 14px 0;
    border-bottom: 1px solid #FFF;
}
.over-view .over-view-content .my-loan-terms .loan-terms .loan-terms-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    padding: 0 10px;
}
.over-view .over-view-content .my-loan-terms .loan-terms .loan-terms-item span:last-child {
    font-weight: 500;
}
.over-view .over-view-content .my-loan-terms .loan-terms:last-child {
    border-bottom: none;
}
.signStatusWrap{
    display: flex;
    align-items: center;
    background-color: #EFF4FF;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 12px;
    color: #1F3988;
    font-weight: 500;
    margin-bottom: 10px;
}
.resendSignRequest{
    color: #325CEB;
    cursor: pointer;
    font-weight: 500;
    text-decoration: underline;
}
.cancelModalBtn{
    background-color: white !important;
    border: 1px solid #325CEB;
    color: #325CEB;
    width: 180px;
    height: 45px;
    padding: 13px 15px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 15px;
}
.cancelModalBtn:hover{
    color: #325CEB !important;
}
.cosignedStatusWrap{
    display: flex;
    align-items: center;
    background-color: #E9F4EB;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 12px;
    color: #2E6333;
    font-weight: 500;
    margin-bottom: 10px;
}
.signedStatusWrap{
    display: flex;
    align-items: center;
    background-color: #D8EAFF;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 12px;
    color: #2E6333;
    font-weight: 500;
    margin-bottom: 10px;
}
