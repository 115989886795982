.status-banner {
  background-color: #F4F7FE;
  border: 1px solid #E9F0FD;
  padding: 16px 24px;
  border-radius: 8px;
  margin: 16px 0;
}

.status-banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.status-banner-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon-container {
  background-color: #F8F9FF;
  display: flex;
  align-items: center;
}

.import-button {
  color: #325CEB;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 8px;
}

.import-button:hover {
  text-decoration: underline;
}
