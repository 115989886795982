.currentTasksWrap .ant-table-thead > tr > th {
    border-bottom: 1px solid #000000;
}
.currentTasksWrap .myUpload-table .ant-table-cell-fix-right.ant-table-cell {
  background-color: #FFF !important;
}
.currentTasksWrap .myUpload-table .ant-table-container table>thead>tr:first-child >*:last-child {
  background-color: #FFF !important;
}
.borrower-document-wrap {
  display: flex;
  /* margin:-30px; */
  /* height: 100vh; */
}