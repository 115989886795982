.application-form .question-list {

}
.application-form .question-list .question-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}
.application-form .question-list .sub-select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
}
.application-form .question-list .sub-select .sub-select-text {
    font-size: 15px;
    line-height: 20px;
    color: #222;
}
.application-form .question-list .question-item:not(:first-child) {
    border-top: 1px solid #D9D9D9;
}
.application-form .question-list .question-item .question-content {
    font-size: 15px;
    line-height: 20px;
    color: #222;
    flex: 1;
    white-space: normal;
}
.application-form .question-list .question-item .question-options {
    display: flex;
    margin-left: 35px;
}
.application-form .question-list .question-item .question-options .question-btn{
    display: flex;
    align-items: center;
    width: 100px;
    height: 44px;
    border-radius: 4px;
    border: 0.5px solid #ECECEC;
    background: #FCFCFC;
    padding-left: 13px;
    cursor: pointer;
}
.application-form .question-list .question-item .question-options .question-btn:first-child {
    margin-right: 12px;
}
.application-form .question-list .question-item .question-options .question-btn.select {
    border: 1px solid #325CEB;
}
.application-form .question-list .question-item .question-options .question-btn .radio {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #ECECEC;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}
.application-form .question-list .question-item .question-options .question-btn .radio .circle-center {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #325CE9;
}
.application-form .question-list .question-item .question-options .question-btn.select .radio {
    border: 1px solid #325CE9;
}
.application-form .question-list .question-item .question-options .question-btn .radio-text {
    font-size: 16px;
    line-height: 20px;
    color: #222;
}