@import url('https://fonts.googleapis.com/css?family=Inter');


.preappTitle {
    font-family: 'Inter' !important;
    text-align: center;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
}

.preAppSelection {
    border: 1px solid #CFD4D9;
    border-radius: 4px;
    padding: 27px;
    text-align: left;
    width: 410px;
    height: 141px;
    margin: 10px;
}

.preAppSelection:hover {
    cursor: pointer;
}

.preAppSelection img {
    max-height: 38px;
}

.preAppSelection div {
    margin-top: 20px;
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
}

.preappSelections {
    display: flex;
    text-align: center;
    justify-content: center;
}

.slide___3-Nqo {
    height: 1000px !important;
}
.goBackSign {
    max-width: 30px;
    margin-left: 70px;
    margin-top: 20px;
}


.h-1000 {
    height: 1100px;
}

.h-1500 {
    height: 1400px;
}

.preappSection1 {
    margin-top: 130px;
    margin-bottom: 40px;
}

.preappSection1 .backBtn {
    cursor: pointer;
    position: absolute;
    left: 15px;
    color: #4B70ED;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}

.preappPreapproval {
    background: #F4F7FE;
    border: 1px solid #E9F0FD;
    border-radius: 4px;
    padding: 20px 10px;
    margin-top: 20px;
}
.preappLabel {
    font-family: 'Inter' !important;
    color: #62707C;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 10px;
}
.preappCheckgroupWithImage {
    display: flex;
    gap: 20px;
}

.preappTipsCard {
    color: #000;
    font-family: 'Inter' !important;
    font-size: 14px;
    padding: 12px;
    border-radius: 6px;
    border: 0.5px solid #ECECEC;
    background-color: #F5F5F8;
}
.preappTipsCard .TipsCardTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.preappTipsCard .TipsCardTitle span {
    font-weight: 500;
    letter-spacing: 0.2px;
}

.preappTipsCard .TipsCardTitle img {
    cursor: pointer;
}

.preappTipsCard .TipsCardContent {
    font-weight: 400;
    letter-spacing: 0.2px;
}

.homeValueText {
    color: #222;
    font-family: 'Inter' !important;
}
.preQualifiedModal {
    max-width: 80%;
}
.preQualifiedModal .ant-modal-close {
    inset-inline-end: inherit;
    top: 22px;
}
.preQualifiedModal .ant-modal-close:hover {
    background-color: #FFF;
}
.preQualifiedModal .ant-modal-close-x {
    font-size: 22px;
}
.preQualifiedModal .modalTitle {
    font-family: "Playfair Display" !important;
    color: #000;
    font-size: 24px;
    font-weight: 600;
}
.preQualifiedModal .modalContent {
    font-family: 'Inter' !important;
    color: #6E6E70;
    font-size: 14px;
    font-weight: 400;
}
.preQualifiedModal .ant-modal-content {
    padding: 66px 48px 58px;
}

.preQualifiedModal .ant-modal-footer {
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 52px;
}

.preQualifiedModal .ant-modal-footer .preBtn {
    width: 248px;
    height: 46px;
    padding: 0 !important;
    font-size: 16px !important;
    font-weight: 600;
    font-family: 'Inter' !important;
    border-radius: 6px !important;
}

.preQualifiedModal .ant-modal-footer .continueBtn {
    color: #325CEB !important;
    background-color: #F7F8FF !important;
    border: 1px solid #E3EAFD !important;
}

.preQualifiedModal .ant-modal-footer .gotoBtn {
    background-color: #325CEB !important;
}
.saveProgressTitle {
    color: #000;
    font-family: "Playfair Display" !important;
    font-size: 26px;
    font-weight: 700;
}
.saveProgressBtn {
    display: block;
    width: 100%;
    border-radius: 6px !important;
}
.saveProgressBottom {
    font-family: Inter;
    font-size: 16px;
    color: #222;
    letter-spacing: 0.2px;
    line-height: 22px;
    margin-top: 15px;
}
.saveProgressBottom span {
    cursor: pointer;
    color: #325CEB;
    font-weight: 600;
    margin-left: 10px;
}

@media (max-width: 800px) {
    .preappCheckgroupWithImage {
        display: block;
    }
    .preappTitle {
        text-align: left;
        font-size: 24px;
        line-height: normal;
    }
    .preapp-btn-pc {
        display: none;
    }
    .preapp-btn-mobile {
        width: 100%;
        margin-bottom: 30px;
        display: block;
    }
    .preappTitleRow {
        text-align: left !important;
        align-items: flex-start !important;
    }
    .saveProgressTitle {
        color: #000;
        font-family: Inter !important;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
    }
}
@media (min-width: 800px) {
    .preapp-btn-pc {
        display: block;
    }
    .preapp-btn-mobile {
        display: none;
    }
}
@media (max-width: 991px) {
    .preappSection1 .preappTitleRow {
        flex-direction: column;
    }
    .preappSection1 .backBtn {
        position: static;
        margin-top: 24px;
    }
}
@media (max-width: 575px) {
    .preQualifiedModal .ant-modal-content {
        padding: 66px 32px 58px;
    }
    .preQualifiedModal .ant-modal-footer .preBtn {
        width: 100%;
    }
    .preappSection1 {
        margin-top: 100px;
        margin-bottom: 40px;
    }
    .preappSelections {
        display: block;
    }
    .preAppSelection {
        width: auto;
        margin: 10px 20px 10px 20px;
    }
    .preappTitle {
        font-size: 28px;
        line-height: 30px;
    }
    .buttonNext {
        width: 100%;
    }
    .goBackSign {
        max-width: 30px;
        margin-left: 33px;
        margin-top: 15px;
        margin-bottom: 20px;
    }
    .preAppSelectionText {
        font-size: 17px !important;
    }
    
    .h-1500 {
        height: 1500px;
    }
}