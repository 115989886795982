.creditCheck-alert {
    border-radius: 6px;
    background-color: #FAE1BD;
    display: flex;
    align-items: center;
    padding: 8px 9px;
}
.creditCheck-alert span {
    color: #222;
    font-size: 12px;
}
.creditCheck-fail {
    margin: 27px auto;
    text-align: center;
    max-width: 360px;
}
.creditCheck-success {
    margin: 0 auto;
    text-align: center;
    max-width: 262px;
}
.creditCheck-fail img {
    width: 44px;
    height: auto;
    margin-bottom: 12px;
}
.creditCheck-success img {
    width: 75px;
    height: auto;
}
.creditCheck-fail .creditCheck-fail-title {
    color: #333;
    font-size: 26px;
    font-weight: 600;
    font-family: 'Playfair Display' !important;
    margin-bottom: 11px;
    line-height: normal;
}
.creditCheck-fail-msg {
    color: #333;
    font-size: 16px;
    line-height: normal;
}
.creditCheck-success .creditCheck-success-title {
    color: #333;
    font-size: 26px;
    font-weight: 700;
    font-family: 'Playfair Display' !important;
    line-height: normal;
}
.creditCheck-fail-operate {
    color: #6E6E70;
    font-size: 14px;
}
.creditCheck-success .creditCheck-success-subtitle {
    color: #6E6E70;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
}
.creditCheck-fail-operate span {
    font-family: 'Inter' !important;
    cursor: pointer;
    font-weight: 500;
    color: #325CEB;
    text-decoration-line: underline;
}
.creditCheck-success .creditCheck-success-btn {
    border-radius: 6px !important;
}
.credit-info-box {
    margin-top: 26px;
}
.credit-info {
    margin-bottom: 26px;
}
.credit-title {
    margin-bottom: 25px;
}
.credit-info-box .credit-info > div {
    display: flex;
    /* justify-content: space-between; */
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 20px;
    flex-wrap: wrap;
}
.credit-info-box .credit-info .credit-info-lable {
    color: #6E6E70;
}
.credit-info-box .credit-info .credit-info-value {
    color: #222;
    margin-left: 10px;
}
.credit-info-box .creditCheck-select {
    max-width: 600px;
}
.creditCheck-select .warning-msg {
    font-size: 14px;
    color: #EB3232;
    font-weight: 500;
    margin-top: 4px;
} 
.warning-msg {
    font-size: 14px;
    color: #EB3232;
    font-weight: 500;
    margin-top: 4px;
} 
.credit-info-box .credit-checkbox .form-check-label {
    white-space: normal;
}
.ssn-input[type="password"] {
    color: #CFD4D9;
}
.creditCheck-statement {
    color: #6E6E70;
    line-height: normal;
    margin-bottom: 26px;
}
.creditCheck-slider-wrap {
    height: 46px;
    padding: 0 27px 0 8px;
    border-radius: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #CFD4D9;
}
.creditCheck-slider-wrap .creditCheck-input {
    width: 100%;
    font-size: 16px;
    color: #222;
}
.creditCheck-slider-wrap .creditCheck-slider {
    width: 60%;
}

.creditCheck-slider-wrap .creditCheck-slider .ant-slider-handle::before {
    inset-inline-start: -4px;
    inset-block-start: -4px;
    width: 18px;
    height: 18px;
}
.creditCheck-slider-wrap .creditCheck-slider .ant-slider-handle::after {
    box-shadow: 0 0 0 3px #0F62FE;
    width: 12px;
    height: 12px;
    inset-inline-start: -1px;
    inset-block-start: -1px;
}
.creditCheck-modal .modal-dialog {
    max-width: 670px;
    margin: 0 auto !important;
}
.creditCheck-label {
    /* color: #62707C; */
    font-weight: 500;
}
.creditCheck-recommend {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 500;
    color: #FFF;
    width: 78px;
    height: 22px;
    background: #1F3988;
    border-radius: 6px;
    margin-right: 8px;
}
.report-error {
    padding: 12px;
    border-radius: 6px;
    background-color: #FAE1BD;
    display: flex;
    margin-bottom: 16px;
}
.report-error .report-error-title {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    line-height: normal;
}
.report-error .report-error-title div:first-child {
    margin-bottom: 2px;
}
.report-error .report-error-title div:last-child {
    color: #325CEB;
    font-size: 16px;
    font-weight: 500;
    text-decoration-line: underline;
    cursor: pointer;
    margin-bottom: 6px;
}
.showSSN-btn {
    position: absolute;
    right: 34px;
    top: 10px;
    z-index: 999;
}
@media (min-width: 576px) {
    .credit-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
@media (min-width: 1200px) {
    .report-error .report-error-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}