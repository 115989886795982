.google-autocomplete {
    width: 100%;
    padding: 9px 11px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.88);
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    transition: all 0.2s;
}
.google-autocomplete:hover {
    border-color: #4096ff;
    border-inline-end-width: 1px;
}
.google-autocomplete:focus {
    border-color: #4096ff;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    border-inline-end-width: 1px;
    outline: 0;
}
.google-autocomplete[disabled] {
    pointer-events:none;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9;
    box-shadow: none;
    cursor: not-allowed;
    opacity: 1;
}
.ant-form-item-has-error .google-autocomplete, .ant-form-item-has-error .google-autocomplete:hover, .ant-form-item-has-error .google-autocomplete:focus {
    border-color: #ff4d4f;
}
.ant-form-item-has-error .google-autocomplete:focus{
    box-shadow: 0 0 0 2px rgba(255, 38, 5, 0.06);
}
