/* Fixed sidenavPrequal, full height */


  .sidenav-card {
    text-align: left;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ECECEC;
    background: #FFF;
    padding: 12px 16px;
    margin: 0 15px 12px;
  }
  .sidenav-card > div:first-child {
    color: #000;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .sidenav-card > div:last-child {
    color: #1F3988;
  }
  .hardMoneyLoan-menu {
    color: #000;
    background-color: #F6F6F6;
  }
  .hardMoneyLoan-menu.ant-menu-inline .ant-menu-item {
    padding-left: 12px !important;
  }
  .hardMoneyLoan-menu.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
    padding-left: 12px !important;
    text-align: left;
  }
  .hardMoneyLoan-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background-color: #F6F6F6;
  }
  .hardMoneyLoan-menu .ant-menu-item .ant-menu-item-icon {
    min-width: 12px;
    width: 12px;
    height: 12px;
    margin-left: 4px;
  }
  .hardMoneyLoan-menu .ant-menu-item-selected {
    color: #325CEB;
    background-color: transparent;
    font-weight: bold;
  }
  .hardMoneyLoan-menu .ant-menu-submenu-selected >.ant-menu-submenu-title {
    color: #000;
    background-color: #D6E2FB;
  }
  .sidenavPrequal .ant-menu {
    position: sticky;
    top: 91px;
  }
  .sidenavPrequal .ant-menu-sub .ant-menu-item {
    color: #1F3988;
  }
  .sidenavPrequal .ant-menu-item{
      text-align: left;
  }
  .navselected {
    background-color:#1F3988 !important;
    color: white !important;
  }
  .sidesection {
    padding: 4px 4px 14px 30px;
    margin-left : 3px;
    text-align: left;
    color: rgba(255,255,255,1);
  }
  /* Style the sidenavPrequal links and the dropdown button */
  .sidenavPrequal a, .side-dropdown-btn {
    padding: 5px 7px 5px 8px;
    text-decoration: none;
    font-size: 17px;
    color: rgba(255,255,255,1);
    display: block;
    border: none;
    background: none;
    width:100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    color: var(--primary-color-text) !important;
  }
.side-dropdown-btn {
  padding: 6px 2px 6px 8px
}
.fa-caret-down {
  margin-top: 0px !important;
}
.green  {
  color: rgb(155, 237, 176);
}
.sidenavPrequal .notalink {
  padding: 8px 13px 8px 25px;
  text-decoration: none;
  font-size: 17px;
  color: #333333;
  display: block;
  border: none;
  background: none;
  width:100%;
  height: 53px;
  text-align: left;
  cursor: not-allowed;
  outline: none;
}

/* On mouse-over */
/* .sidenavPrequal  a:hover, .side-dropdown-btn:hover {
  color: var(--primary-pale-text) !important;
  background-color: var(--primary-pale) !important;
} */





/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container, .side-dropdown-btn:focus {
  display: none;
  background-color: var(--primary-color-2);
  color: var(--primary-color-2-text) !important;
  outline: none;
  border: none;
}
.prequalInterviewNav {
  color: black;
  display: flex;
  align-items: baseline;
  gap: 6px;
  padding: 5px 15px;
}

.prequalInterviewNavText .title {
  color: black !important;
  font-weight: 500;
  font-size: 16px;
}
.prequalInterviewNavText .unselected {
  color: #333333 !important;
  font-weight: 500;
  font-size: 16px;
}
.prequalInterviewNavText .etc {
  color: #6E6E70 !important;
  font-weight: 400;
  font-size: 12px;
  margin-top: -10px;
}
.unselected {
  background-color: #F7F8FC;
}
.selectpad {
  margin-top: '2px' !important;
  padding-left: 0px !important;
  padding-right: 28px !important;

}
.selectsignWrap {
  padding: 4px 0 8px !important;
}
.selectsignWrap img {
  width: 12px;
}

.selectsigntransparent {
  padding-left: 0px !important;
  padding-right: 8px !important;
}
.navselection {
padding-left: 0px !important;
padding-top: 0px !important;
padding-right: 0px !important;
padding-bottom: 0px !important;
margin-top: 0px !important;
margin-bottom: 0px !important;
}
.navselection:hover {
  background-color: #D6E2FB;
}
/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}
.sidesection strong{
  color: white !important;
}
.sidebartext {
  font-size: 13px !important;
  margin-left: 0.5em;
}

.sidebardisabletext {
  font-size: 13px !important;
  margin-left: 0.5em;
  color: #D9D9D9;
  padding: 10px;
}

.subsidebar {
  margin-left: 13px;
}

.icon {
  font-size: 12px;
  margin-left: 3px;
  margin-right: 4px;
}

.applicationSubTitle {
  font-size: 10px;
  font-weight: bold;
  color: rgb(219, 220, 222);
}



.prequal-dropdown-container {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  letter-spacing: 0.2px;
  font-feature-settings: 'pnum' on, 'lnum' on;

  color: #333333;
}

.sideBarApplicationInfo {
  font-size: 14px;
  padding: 14px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
  margin: 0 10px 10px 10px;
}

.sidebarCompanyLogo {
  color: #000;
  font-weight: 500;
  font-size: 22px;
  padding-bottom: 30px;
}
