.loan-rate-result {
    display: flex;
    justify-content: center;
    height: 100%;
    font-size: 16px;
    padding: 0 0 80px 0;
    background-color: #F2F4F8;
}
.loan-rate-result .text-blue {
    color: #0F62FE;
}
.loan-rate-result .bold {
    font-weight: bold;
}
.loan-rate-result .center {
    text-align: center;
}
.loan-rate-result .title {
    color: #1F3988;
    padding: 24px 18px;
    font-weight: 500;
}
.loan-rate-result .title .bold {
    font-weight: bold;
}
.loan-rate-result .result-card {
    margin: 0 30px;
    background-color: #FFF;
    border-radius: 15px;
    padding: 46px 36px 0 36px;
    margin-bottom: 40px;
    position: relative;
}
.loan-rate-result .result-card .tips-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 8px;
    bottom: 12px;
}
.loan-rate-result .result-card .card-item {
    margin-bottom: 40px;
}
.loan-rate-result .result-card .card-item .result-title {
    font-size: 14px;
    color: #000;
    margin-bottom: 20px;
}
.loan-rate-result .result-card .card-item .result-value {
    font-size: 30px;
    color: #325CEB;
    font-weight: 600;
}
.loan-rate-result .describe {
    font-size: 16px;
    color: #21272A;
}
.loan-rate-result .info-first {
    padding: 14px;
    margin-bottom: 14px;
}
.loan-rate-result .info-second {
    padding: 0 36px;
    margin-bottom: 36px;
}
.loan-rate-result .btn-wrap {
    margin-bottom: 30px;
}
.loan-rate-result .sign-btn {
    padding: 16px 56px;
    font-size: 20px;
    font-weight: bold;
    height: auto;
    line-height: 1;
    background-color: #0F62FE;
}
.loan-rate-result .login{
    color: #000;
    font-weight: bold;
    margin-bottom: 16px;
}
.loan-rate-result .privacy {
    font-size: 14px;
    color: #697077;
}
.loan-rate-result .creat-account {
    padding: 50px 30px;
    text-align: center;
    color: #333;
}
.loan-rate-result .creat-account .creat-title {
    font-size: 40px;
    font-weight: bolder;
    margin-bottom: 40px;
}
.loan-rate-result .creat-account .creat-subtitle {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 30px;
}
.loan-rate-result .creat-account .continue-btn{
    width: 200px;
    height: 44px;
    color: #FFF;
    font-size: 16px;
    border-radius: 4px;
    background-color: #325CEB;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto 30px;
}
.loan-rate-result .creat-account .to-login span {
    font-weight: bold;
}
@media (max-width: 767px) {
    .loan-rate-result .creat-account .creat-title {
        font-size: 32px;
    }
}