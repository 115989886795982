
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  src: local('Inter Regular'), url('./fonts/Inter-Regular.woff') format('woff');
}


@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: normal;
  src: local('Inter Italic'), url('./fonts/Inter-Italic.woff') format('woff');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  src: local('Inter Medium'), url('./fonts/Inter-Medium.ttf') format('woff');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  src: local('Inter Semi Bold'), url('./fonts/Inter-SemiBold.ttf') format('woff');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 700;
  src: local('Inter Bold'), url('./fonts/Inter-Bold.ttf') format('woff');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  src: local('Inter Extra Bold'), url('./fonts/Inter-ExtraBold.ttf') format('woff');
}
@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 900;
  src: local('Inter Black'), url('./fonts/Inter-Black.ttf') format('woff');
}
@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 500;
  src: local('Inter Bold Italic'), url('./fonts/Inter-BoldItalic.woff') format('woff');
}


@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 100;
  src: local('Inter Thin'), url('./fonts/Inter-Thin.woff') format('woff');
}


@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 100;
  src: local('Inter Thin Italic'), url('./fonts/Inter-ThinItalic.woff') format('woff');
}


@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  src: local('Inter Light'), url('./fonts/Inter-Light.woff') format('woff');
}


@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: 300;
  src: local('Inter Light Italic'), url('./fonts/Inter-LightItalic.woff') format('woff');
}


@font-face {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  src: local('Inter Medium'), url('./fonts/Inter-Medium.woff') format('woff');
}


@font-face {
  font-family: Inter;
  font-style: italic;
  font-weight: bold;
  src: local('Inter Medium Italic'), url('./fonts/Inter-MediumItalic.woff') format('woff');
}


body,
div {
  font-family: Inter;
}