.application-form .form-title {
    color: #6E6E70;
    font-size: 20px;
    margin-bottom: 30px;
}

.application-form .form-sub-title {
    color: #6E6E70;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
}

.application-form .ant-form-item {
    margin-bottom: 48px;
}

.application-form .ant-form-item-label {
    padding: 0 0 10px;
}

.application-form .ant-form-item-label>label {
    height: 20px !important;
    color: #222;
    font-weight: bold;
}

.application-form .common-btn {
    width: 188px;
    height: 44px;
    font-size: 16px;
    color: #FFF;
    font-weight: bold;
    background-color: #325CEB;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 6px !important;
}

.application-form .secondary-btn {
    width: 188px;
    height: 44px;
    font-size: 16px;
    color: #325CEB;
    background-color: #F7F8FF;
    font-weight: bold;
    border: 1px solid #325CEB;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 6px !important;
}



.ant-input:hover,
.ant-input:focus {
    border-color: #325CEB;
}

.ant-input-number:hover,
.ant-input-number:focus {
    border-color: #325CEB;
}

.application-form .secondary-btn:hover {
    background-color: #F7F8FF !important;
}

.application-form .common-btn.disabled {
    background-color: #D9D9D9;
}

.ant-input-lg {
    padding: 9px 11px;
    border-radius: 6px;
}

.ant-picker-large {
    padding: 10.5px 11px 6.5px;
    border-radius: 6px;
}

.ant-picker-outlined:hover {
    border-color: #222
}

.ant-picker-outlined:focus-within {
    border-color: #325CEB !important
}

.ant-picker-outlined:focus-within:focus-within {
    border-color: #325CEB !important
}

.ant-input-number-lg input.ant-input-number-input {
    height: 42px;
    background-color: #FCFCFC;
}

.ant-input-number-affix-wrapper {
    overflow: hidden;
    border-radius: 6px;
    border: 1px solid #ececec;
    background-color: #FCFCFC;
}

.ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.04);

}

.ant-input-number-disabled input[disabled] {
    background: transparent;
    color: rgba(0, 0, 0, 0.25);
}

.ant-input-number-affix-wrapper-disabled .ant-input-number-disabled {
    background: transparent;
}