.over-view {

}
.over-view .over-view-content {
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
}
.over-view .over-view-content .application-status {

}
.over-view .over-view-content .application-status .status-title {
    font-size: 24px;
    line-height: 20px;
    color: #222;
    font-weight: 500;
    margin-bottom: 25px;
}

.over-view .over-view-content .application-status .status-title a {
    font-size: 16px;
    margin-left: 10px;
    color: #1677ff;
    font-weight: 400;
}

.over-view .over-view-content .application-status .status-list {

}
.over-view .over-view-content .application-status .status-list .status-item {
    font-size: 16px;
    padding: 0 0 18px 38px;
    border-left: 1px solid #DDE1E6;
    position: relative;
    line-height: 1.1;
}
.over-view .over-view-content .application-status .status-list .status-item .operate {
    font-size: 13px;
    padding: 10px 0 4px 0;
}
.over-view .over-view-content .application-status .status-list .status-item .operate span {
    padding: 0 8px;
    cursor: pointer;
}
.over-view .over-view-content .application-status .status-list .status-item .operate span:not(:last-child) {
    border-right: 1px solid #325CEB;
}
.application-status .status-list .status-item .status-tag {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #DDE1E6;
    position: absolute;
    left: -8px;
    top: 0;
}
.over-view .over-view-content .application-status .status-list .status-item:last-child {
    padding: 0 0 0 38px;
}
.over-view .over-view-content .my-loan-terms {
    width: 390px;
    border-radius: 8px;
    background: #F4F7FE;
    padding: 0 15px;
}
.over-view .over-view-content .my-loan-terms .loan-terms-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding: 0 10px;
    border-bottom: 1px solid #FFF;
}
.over-view .over-view-content .my-loan-terms .loan-terms-title span:first-child {
    font-weight: 500;
}
.over-view .over-view-content .my-loan-terms .loan-terms {
    padding: 14px 0;
    border-bottom: 1px solid #FFF;
}
.over-view .over-view-content .my-loan-terms .loan-terms .loan-terms-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 24px;
    color: #333;
    padding: 0 10px;
}
.over-view .over-view-content .my-loan-terms .loan-terms .loan-terms-item span:last-child {
    font-weight: 500;
}
.over-view .over-view-content .my-loan-terms .loan-terms:last-child {
    border-bottom: none;
}