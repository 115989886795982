@import url('https://fonts.googleapis.com/css?family=Inter');

.housePriceCard {
    padding: 28px 28px 22px 28px;
}

.housePriceCard * {
    font-family: 'Inter' !important;
}

.affordabilityPlusSign {
    margin-left: 0.5rem;
    width: 0.875rem;
}

.housePriceTitle {
    color: #333;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
}

.housePricePrice {
    color: #333;
    font-weight: 800;
    font-size: 40px;
}

.housePriceDetails {
    color: #333;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
}
.housePriceDetails .value {
    font-weight: 700;
}
.dtiSlider {
    margin-top: 20px;
    background-color: white;
    border: 1px solid #CFD4D9;
    border-radius: 8px;
    padding: 5px 15px 5px 15px;
    position: relative;
    overflow: hidden;
}

.dtiSliderTipTitle {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
}
.dtiSliderTipText {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 10px;
}


.ltvAlert {
    background: #EAEFFD;
    padding-top: 10px;
    padding-bottom: 10px;
}

.ltvAlertRow {
    flex-wrap: nowrap;
}

.paymentRow {
    height: 20px;
}

.inputLabel {
    margin-bottom: 0;
}

.affordabilityContainer {
    display: flex;
}


.affordabilityContainer .form-control {
    height: 2.75rem;
}

.affordability-title {
    font-size: 1.8rem;
    line-height: 2.3rem;
    font-weight: bold;
    color: #333;
    text-align: center;
}

.describe-mobile {
    color: #333;
    padding: 1.5rem 1rem 2rem 1rem;
    word-break: break-all;
}

.affordabilityResult {
    margin-right: 50px;
    border-radius: 8px;
    background-color: #F5F5F7;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* margin-bottom: 232px; */
    max-width: 500px;
}


.affordabilityForm-pc {
    /* max-height: 640px;
    overflow-y: scroll;
    overflow-x:inherit; */
}
.affordabilityForm-pc .collapse-item {
    border: 1px solid #CFD4D9;
    border-radius: 8px;
    margin-bottom: 20px;
}
.affordabilityForm-pc .collapse-item .collapse-header {
    height: 66px;
    padding: 0 30px 0 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.affordabilityForm-pc .collapse-item .collapse-body {
    padding: 0 40px 0 40px;
}
.affordabilityForm-pc .collapse-item .collapse-body .checkMore-group {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}
.affordabilityForm-pc .collapse-item .collapse-body .checkMore-btn {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: #325CEB;
    text-decoration: underline;
    cursor: pointer;
}
.affordabilityForm-pc .collapse-item .collapse-body .check-group-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.affordabilityForm-pc .collapse-item .collapse-body .check-group-box {
    width: 240px;
}
.affordabilityForm-pc .collapse-item .collapse-header .title {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}
.affordabilityForm-pc .collapse-item .collapse-header .open {
    transform:rotate(180deg);
    transition: all .3s;
}
.affordabilityForm-pc .collapse-item .collapse-header .close {
    transition: all .3s;
}
.affordabilityInput-pc .input-group {
    border-radius: 4px;
}
/* always how scroll bar */

.affordabilityForm-pc::-webkit-scrollbar {
    -webkit-appearance: none;
}

.affordabilityForm-pc::-webkit-scrollbar:vertical {
    width: 11px;
}

.affordabilityForm-pc::-webkit-scrollbar:horizontal {
    height: 1px;
}

.affordabilityForm-pc::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.333);
}


.affordabilityForm  * {
    font-family: 'Inter' !important;
}

.affordabilityFormTitle1 {
    font-weight: 400;
    font-size: 20px;
    color: #6E6E70;
}

.affordabilityInputLabel {
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #62707c;
}

.affordabilityInputLabel .tips-img {
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 0.375rem;
    cursor: pointer;
}

@media (max-width: 480px) {
    .alert{
        position: absolute;
        top: 20%
    }
    .resetbutton{
        margin-top: 60px;
        margin-left: 68px;
    }
    .affordabilityContainer {
        display: block;
    }
    .affordabilityResult {
        min-height: 340px;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .housePriceCard {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 10px;
    }
    .affordabilityForm {
        overflow-y: visible;
        max-height: none;
    }
    .affordabilityForm-pc {
        display: none;
    }
    .is-sticky {
        position: sticky;
        top: 0;
        z-index: 999;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        box-shadow: none;
        border-radius: 0;
      }
    .housePriceDetailsSticky {
        font-size: 13px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        margin-top: 2px;
    }
    .dtiSliderTipTitle {
        font-weight: 600;
        font-size: 14px;
    }
    .dtiSliderTipText {
        font-weight: 400;
        font-size: 12px;
    }
    .housePriceTitle {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
    }
    .describe-pc {
        display: none;
    }
    .navigation-bar .btn {
        margin: 0.4rem 1rem;
    }
    .main-heading{
        display: none;
    }
    .mobile-monthpay-result{
        padding-left: 0;
    }
}
@media (min-width: 481px) {
    .affordabilityContainer {
        flex-direction: row-reverse;
    }
    .affordability-title {
        font-size: 48px;
        line-height: 44px;
        margin-top: 18px;
    }
    .affordabilityForm-pc {
        width: 590px;
    }
    .affordabilityForm {
        display: none;
    }
    .affordabilityFormTitle1 {
        line-height: 20px;
        margin: 32px 0 20px 0;
    }
    .affordabilityInputLabel {
        margin-bottom: 10px;
    }
    .affordabilityResult {
        width: 550px;
    }
    .housePricePrice {
        line-height: 37px;
        margin: 10px 0 12px 0;
    }
    .housePriceBox {
        border-top: 1px solid #CCC;
    }
    .housePriceDetails {
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px;
    }
    .dtiSlider {
        margin-top: 14px;
    }
    .preapprvoal-wrap {
        display: none;
    }
    .describe-pc {
        width: 610px;
        font-size: 16px;
        line-height: 19px;
        color: #6E6E70;
        padding: 18px 0 60px 0;
        word-break: break-all;
    }
    .describe-mobile {
        display: none;
    }
    .header-fixed {
        display: none;
    }
}
.navigation-bar {
    position: fixed;
    width: 100%;
    z-index: 1002;
}
.navigation-bolck {
    height: 4rem;
}
.check-more {
    color: #325CEB !important;
    border-color: #F5F5F7 !important;
    font-weight: bold;
    height: 2.75rem;
    background-color: #F5F5F7 !important;
}

.preapprvoal-wrap {
    padding-top: 2rem;
    border-top: 1px solid #CCC;
}

.preapprvoal-wrap .title {
    font-size: 1.25rem;
    text-align: center;
}

.preapprvoal-wrap .sub-title {
    font-size: 1.25rem;
    text-align: center;
    font-weight: bold;
}
.preapprvoal-wrap .tips {
    font-size: 1rem;
	margin: 1.5rem 0;
}

.taxses-switch {
    --height: 2rem;
    --border-width: 1px;
    --checked-color: #325CEB;
}

.taxses-switch .adm-switch-handle {
    height: 1.375rem;
    width: 1.375rem;
    background: #CFD4D9;
    top: 4px;
    left: 5px;
}
.taxses-switch .adm-switch-checkbox {
    background: #CFD4D9;
}
.taxses-switch.adm-switch-checked .adm-switch-handle {
    background: #FFF;
    left: calc(100% - (var(--height) - var(--border-width) + -4px));
}
.taxses-switch .adm-switch-inner {
    font-size: 0.875rem;
}

.radioCircle{
    margin-left: 0.812rem;
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
    line-height: 1.25rem;
    border: 1px solid #CFD4D9;
    background-color: #F1F1F1;
}
.radio-group-box {
    width: 100%;
    display: flex;
    border: 1px solid #CFD4D9;
    border-radius: 0.25rem;
    padding: 0.812rem 0;
    margin-bottom: 10px;
}

.radio-group-box:last-child {
  margin-right: 0;
}

.radio-group-box:hover {
    border: 1px solid #325CEB;
    cursor: pointer;
}

.collapse-header{
    display: flex;
    justify-content: space-between
}

.affordability-get-started {
    background-color: #325CEB !important;
    border: 1px solid #325CEB !important;
    border-radius: 4px;
    color: white;
    width: 100%;
    padding-top: 4px;
    height: 40px !important; 
}
.affordability-get-started:hover {
    color: white;
}
#landingAffordability > div:nth-child(2) > div.affordabilityForm-pc.mx-3 > form > div:nth-child(2) > div > div > div > input{
    background-color: #FaFaFa;
}

#landingAffordability > div:nth-child(2) > div.affordabilityForm-pc.mx-3 > form > div:nth-child(3) > div > div > div > input{
    background-color: #FAFAFA
}

#landingAffordability > div:nth-child(2) > div.affordabilityForm-pc.mx-3 > form > div:nth-child(10) > div > div > div > input{
    background-color: #FAFAFA;
}

#landingAffordability .scroll-bar{
    background-color: #FAFAFA
} 

.affordability-form-inputs, .affordability-form-inputs-usage, .affordability-form-inputs-type{
    background-color:#FAFAFA !important
}

.input-group-text{
    background-color: #FAFAFA !important;
}

.main-value{
    font-weight: 800;
    font-size: 20px;
}

.sub-value{
    font-weight: 700;
    font-size: 14px;
}

.main-text{
    color: #62707c !important
}

#landingAffordability > div:nth-child(3) > div.affordabilityForm.mx-3 > form > div:nth-child(1) > div > div > div > input{
    background-color: #FAFAFA;
}

#landingAffordability > div:nth-child(3) > div.affordabilityForm.mx-3 > form > div:nth-child(2) > div > div > div > input{
    background-color: #FAFAFA;
}

#landingAffordability > div:nth-child(3) > div.affordabilityForm.mx-3 > form > div.mt-3.form-row > div > div > div > input{
    background-color: #FAFAFA;
}

.inputSection .row .affordability-form-inputs:nth-child(1){
    margin-left: 0px !important;
}
.inputSection .row .affordability-form-inputs:nth-child(4){
    margin-left: 0px !important;
}

.inputSection .row .affordability-form-inputs-usage:nth-child(1){
    margin-left: 0px !important;
}
.inputSection .row .affordability-form-inputs-usage:nth-child(3){
    margin-left: 0px !important;
}

.inputSection .row .affordability-form-inputs-type:nth-child(1){
    margin-left: 0px !important;
}
.inputSection .row .affordability-form-inputs-type:nth-child(3){
    margin-left: 0px !important;
}

#dti-tip > div.tooltip-inner{
	padding: 0.25rem 0.5rem;
    text-align: left;
	color: white ;
	border-radius: 8px;
	max-width: 380px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #333;
}

#fico-tip > div.tooltip-inner{
    padding: 0.25rem 0.5rem;
    text-align: left;
    color: white ;
    border-radius: 8px;
    max-width: 380px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #333;
}

.affordabilityForm-pc .scroll-bar .input-text{
    background-color: #FAFAFA;
}