.prequalCheckText {
    color: #222;
    font-family: 'Playfair Display' !important;
    font-size: 26px;
    font-weight: 700;
    line-height: normal;
}
.pastLoansModal .ant-modal-content {
    padding: 50px 44px;
    display: flex;
    flex-direction: column;
}
.pastLoansModal .ant-modal-content .ant-modal-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.pastLoansModal .ant-modal-content .ant-modal-header {
    margin-bottom: 10px;
}
.pastLoansModal .ant-modal-content .ant-modal-header .ant-modal-title {
    color: #6E6E70;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
}
.pastLoansModal .pastLoansModal-title {
    font-family: 'Playfair Display' !important;
    color: #000;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 12px;
}
.pastLoansModal .ant-modal-footer {
    text-align: left;
}
@media (max-width: 800px) {
    .pastLoansModal .ant-modal-content {
        padding: 30px 20px;
        height: calc(100vh - 18px) !important;
    }
    .pastLoansModal .ant-modal-content .pastLoansModal-btn-pc {
        display: none;
    }
    .pastLoansModal .ant-modal-content .pastLoansModal-btn-mobile {
        display: block;
    }
}
@media (min-width: 800px) {
    .pastLoansModal .ant-modal-content .pastLoansModal-btn-pc {
        display: block;
    }
    .pastLoansModal .ant-modal-content .pastLoansModal-btn-mobile {
        display: none;
    }
}
.email-verificaition-simple .email-input {
    border-radius: 8px 0 0 8px !important;
    border-right: none;
  }
  
  .email-verificaition-simple .ant-btn-suffix {
    height: 42px;
    background-color: rgb(252, 252, 252);
    border-top: 1px solid rgb(236, 236, 236);
    border-right: 1px solid rgb(236, 236, 236);
    border-bottom: 1px solid rgb(236, 236, 236);
    border-left: none;
    border-image: initial;
    color: rgb(50, 92, 235);
    box-shadow: none;
    border-radius: 0 8px 8px 0 !important;
  }
  .email-verificaition-simple .ant-btn-suffix:disabled {
    color: #ccc;
  }

  .email-verificaition-simple .code-area {
    margin-left: 20px;
    min-width: 180px;
  }