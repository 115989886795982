/* Fixed sidenavPrequal, full height */

.sidenav-application {
    /* height: calc(100vh - 91px) !important; */
    width: 100%;
    max-width: 218px;
    position: sticky;
    z-index: 1;
    /* top: 91px; */
    left: 0;
    background-color: #F6F6F6;
    color: black;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 16px;
}

.navselected {
    background-color: #1F3988 !important;
    color: white !important;
}

.sidesection {
    padding: 4px 4px 14px 30px;
    margin-left: 3px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
}

/* Style the sidenavPrequal links and the dropdown button */
.sidenav-application a,
.side-dropdown-btn {
    padding: 5px 7px 5px 8px;
    text-decoration: none;
    font-size: 17px;
    color: rgba(255, 255, 255, 1);
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
    color: var(--primary-color-text) !important;
}

.side-dropdown-btn {
    padding: 6px 2px 6px 8px
}

.fa-caret-down {
    margin-top: 0px !important;
}

.green {
    color: rgb(155, 237, 176);
}

.sidenav-application .notalink {
    padding: 8px 13px 8px 25px;
    text-decoration: none;
    font-size: 17px;
    color: #333333;
    display: block;
    border: none;
    background: none;
    width: 100%;
    height: 53px;
    text-align: left;
    cursor: not-allowed;
    outline: none;
}

/* On mouse-over */
/* .sidenavPrequal  a:hover, .side-dropdown-btn:hover {
    color: var(--primary-pale-text) !important;
    background-color: var(--primary-pale) !important;
  } */





/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container,
.side-dropdown-btn:focus {
    display: none;
    background-color: var(--primary-color-2);
    color: var(--primary-color-2-text) !important;
    outline: none;
    border: none;
}

.prequalInterviewNav {
    color: black;
    display: flex;
    align-items: baseline;
    gap: 6px;
    padding: 5px 15px;
}

.prequalInterviewNavText {
    flex: 1;
}

.prequalInterviewNavText .title {
    color: black !important;
    font-weight: 500;
    font-size: 16px;
}

.prequalInterviewNavText .unselected {
    color: #333333 !important;
    font-weight: 500;
    font-size: 16px;
}

.prequalInterviewNavText .etc {
    color: #6E6E70 !important;
    font-weight: 400;
    font-size: 12px;
    margin-top: -10px;
}

.unselected {
    background-color: #F7F8FC;
}

.selectpad {
    margin-top: '2px' !important;
    padding-left: 0px !important;
    padding-right: 28px !important;

}

.selectsignWrap {
    padding: 4px 0 8px !important;
}

.selectsignWrap img {
    width: 12px;
}

.selectsigntransparent {
    padding-left: 0px !important;
    padding-right: 8px !important;
}

.navselection {
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.navselection:hover {
    background-color: #D6E2FB;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
    float: right;
    padding-right: 8px;
}

.sidesection strong {
    color: white !important;
}

.sidebartext {
    font-size: 13px !important;
    margin-left: 0.5em;
}

.sidebarDisable-app {
    height: 36px;
    padding: 0 5px 0 26px !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    cursor: auto;
}

.sidebar-app {
    height: 36px;
    padding: 0 5px 0 26px !important;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    color: #6E6E70;
}

.sidebar-app:hover {
    background-color: #D6E2FB;
}

.sidebartext-app {
    font-size: 14px !important;
    font-weight: 600;
    text-transform: capitalize !
}

.sidebardisabletext-app {
    color: #6E6E70;

    font-size: 14px;
    font-style: normal;
    /* font-weight: 600; */
    line-height: 24px;
    /* 150% */
}

.selectbar-text {
    color: #325CEB;
    background-color: #D6E2FB;
}

.subsidebar {
    margin-left: 13px;
}

.icon {
    font-size: 12px;
    margin-left: 3px;
    margin-right: 4px;
}

.applicationSubTitle {
    font-size: 10px;
    font-weight: bold;
    color: rgb(219, 220, 222);
}



.prequal-dropdown-container {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    letter-spacing: 0.2px;
    font-feature-settings: 'pnum' on, 'lnum' on;

    color: #333333;
}

.sideBarApplicationInfo {
    font-size: 14px;
    padding: 14px;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background-color: white;
    margin: 0 10px 10px 10px;
}

.prequalInterviewNavProgress {
    margin-left: 8px;
}

.sidebar-menu {
    width: 100%;
    color: #000;
    height: 36px;
    border: none;
}

.sidebar-menu .menuExpandIcon {
    transform: rotate(180deg);
    transition: all .3s;
}
.menuExpandIcon {
    transform: rotate(180deg);
    transition: all .3s;
}
.sidebar-menu .menuExpandIcon.expand {
    transform: rotate(360deg);
}
.menuExpandIcon.expand {
    transform: rotate(360deg);
}
.sidebar-menu-selected {
    background-color: #D6E2FB;
    color: #325CEB;
}

.sidebar-menu-item {
    overflow: hidden;
    transition: all .3s;
}

.sidenav-application .sidenav-sub {
    /* height: 32px; */
    line-height: 32px;
    padding-left: 9px;
    margin-left: 32px;
    position: relative;
}

.sidenav-application .sidenav-sub>a {
    color: #6E6E70 !important;
    font-size: 14px;
    padding: 0;
}

.sidenav-application .sidenav-sub .sub-acitve {
    color: #325CEB;
    font-weight: 500;
}

.sidenav-application .sidenav-sub:not(:last-child) {
    margin-bottom: 6px;
}

.sidenav-application .sidenav-sub .link {
    position: absolute;
    left: -16px;
    top: -8px;
}

.sidenav-application .sidenav-sub .line {
    position: absolute;
    left: -16px;
    top: 12px;
    height: 20px;
    width: 1px;
    background-color: #CFD4D9;
}

.document-sidebar .document-sidebar-children-item {
    text-align: left;
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 12px;
    color: #474849;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-top: 4px;
    cursor: pointer;
    transition: all .2s;
    margin-top: 15px;
}
.document-sidebar-children-item {
    text-align: left;
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 6px;
    color: #474849;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-top: 4px;
    cursor: pointer;
    transition: all .2s;
  
}
.document-sidebar .document-sidebar-children-item:hover {
    background-color: #D6E2FB;
}

.document-sidebar .document-sidebar-children-item.children-active {
    color: #325CEB;
    font-weight: 600;
    background-color: #D6E2FB;
}
.document-sidebar-children-item.children-active {
    color: #325CEB;
    font-weight: 600;
    /* background-color: #D6E2FB; */
}
.document-sidebar .menuExpandIcon {
    transition: all .3s;
}

.document-sidebar .menuExpandIcon.expand {
    transform: rotate(-180deg);
}