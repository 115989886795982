.divider-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
  
  .divider-line {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
  }
  
  .divider-text {
    padding: 0 10px;
    font-size: 14px;
    color: #ccc;
  }
  