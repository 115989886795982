.ratesTips {
	border-radius: 4px;
	background-color: #E9F0FD;
    padding: 15px;
    white-space: wrap !important;
}
.ratesTips .text-wrap {
    white-space: initial;
    word-break: break-word;
}
.loSelectedRateCardWrap {
    border-radius: 12px;
    border: 1px solid #ECECEC;
    padding: 15px 25px;
    margin-bottom: 10px;
}
.ant-btn.unchosenRateButton  {
    border-radius: 14px !important;
    display: flex;
    align-items: center;
    color: #ECECEC;
    border: 1px solid #ECECEC;
    background-color: #FCFCFC;
}
.chosenRateIcon {
    border-radius: 14px 0 0 14px;
    background-color: #4C70ED;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 4px;
}
.ant-btn.chosenRateButton  {
    border-radius: 0 14px 14px 0 !important;
    display: flex;
    align-items: center;
    color: white;
    background-color: #4C70ED;
    border:none;
}
.ant-btn.chosenRateButton:hover  {
    color: white;
}
.rate-num-tips {
    display: flex;
    white-space: wrap !important;
}
.rate-num-tips img {
    margin-right: 10px;
    height: 18px;
    width: 21px;
    margin-top: 2px;
}
.rate-num-tips div {
    flex: 1;
}
.my-rates-card {
    margin-top: 16px;
}
.my-rates-card-grey {
    margin-top: 8px;
}
.selected-rate-button-xs-wrap {
    display: none;
}
@media (max-width: 576px) {
    .my-rates-card {
        flex-direction: column;
        gap: 12px;
        margin-top: 12px;
    }
    .my-rates-card-grey {
        flex-direction: column;
        gap: 16px;
        margin-top: 20px;
    }
    .my-rates-card > div {
        display: flex;
        justify-content: space-between;
        line-height: 24px;
    }
    .my-rates-card-grey > div {
        display: flex;
        justify-content: space-between;
        line-height: normal;
    }
    .selected-rate-button {
        display: none !important;
    }
    .selected-rate-button-xs-wrap {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}