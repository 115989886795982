@media (max-width: 767px) {
    .loan-rate-title {
      font-size: 24px !important;
    }
}
.loan-rate-title {
    height: 100px;
    font-family: "Playfair Display" !important;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
}