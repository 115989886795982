@font-face {
    font-family: 'Playfair';
    src: url(../../fonts/PlayfairDisplay.ttf) format('truetype');
}
.title-doc {
    color: #6e6e70;
}
.header {
    display: flex;
    justify-content: space-between;
}
.title {
    text-align: left;
}
.title-search {
    width: 250px;
    height: 44px;
}
.upload-modal .ant-modal-title {
    font-family: 'Playfair' !important;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: left;

}
