.document-preview-modal {
    top: 0;
    padding-bottom: 0;
}
.document-preview-modal .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100vh;
    top: 0;
    background-color: #DBDCDE;
    border-radius: 0;
}
.document-preview-modal.check-list .document-preview-content {
    height: calc(100% - 8px);
    margin: 8px 8px 0 8px;
    overflow: auto;
    max-height: calc(100vh - 113px);
}
.document-preview-modal.check-list .ant-modal-content .ant-modal-body {
    flex: 1 1;
    display: flex;
    flex-direction: column;
}
.document-preview-modal.check-list .document-preview-content .preview-box {
    max-height: calc(100vh - 114px);
    margin: 0 auto;
    position: relative;
}
.document-preview-modal.check-list .document-preview-content .preview-box .pdf-anchor {
    margin-bottom: 12px;
}
.document-preview-modal.check-list .document-preview-content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
.document-preview-modal.check-list .document-preview-content::-webkit-scrollbar-track {
    background: transparent; 
}
.document-preview-modal.check-list .document-preview-content::-webkit-scrollbar-thumb {
    background: #979797;
    border-radius: 5px;
}
.document-preview-modal .preview-modal-top {
    height: 60px;
    line-height: 60px;
    background-color: #FFF;
    color: #222;
    font-size: 18px;
    font-weight: 500;
    padding-left: 46px;
}
.document-preview-modal .file-action {
    height: 45px;
    border-top: 1px solid #DBDCDE;
    border-bottom: 1px solid #DBDCDE;
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.document-preview-modal.check-list .file-action .download-directory {
    font-family: Inter;
    color: #1F2890;
    font-size: 16px;
    font-weight: 500;
    width: 33.33%;
}
.document-preview-modal.check-list .file-action .download-directory > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.document-preview-modal .file-action .change-file {
    padding: 4px 10px;
    border-radius: 10px;
    border: 1px solid #B1B2B7;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    gap: 25px;
    width: 33.33%;
}
.document-preview-modal .file-action .download-file {
    position: absolute;
    right: 24px;
}
.document-preview-modal .file-action .change-file .prev-file, .document-preview-modal .file-action .change-file .next-file {
    cursor: pointer;
}
.document-preview-modal .file-action .change-file .flie-total {
    display: flex;
    align-items: center;
    color: #545459;
    font-size: 16px;
}
.document-preview-modal .file-action .change-file .flie-total span {
    font-weight: 500;
    color: #325CEB;
    margin-left: 12px;
}
.document-preview-modal .file-action .change-file .separate {
    height: 28px;
    width: 1px;
    background-color: #ECECEC;
}
.document-preview-modal .file-action .change-file .file-name {
    color: #545459;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 6px;
    flex: 1 1;
    overflow: hidden;
}
.document-preview-modal.check-list .file-action .change-file .file-name div {
    flex: 1 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.document-preview-modal.check-list .file-action .right-action {
    width: 33.33%;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    grid-gap: 24px;
    gap: 24px;
}
.document-preview-modal .document-preview-top-bar {
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color: #FFF;
    background: transparent;
    padding: 0 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.document-preview-modal .document-preview-bottom-bar {
    height: 48px;
    width: 330px;
    border-radius: 8px;
    color: #FFF;
    background: rgba(0, 0, 0, 0.80);
    position: absolute;
    bottom: 20px;
    left: 50%;
    margin-left: -165px;
    display: flex;
    align-items: center;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination {
    flex: 1;
    padding: 9px 12px;
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid #FFF;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination-prev button, .document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination-next button {
    color: #FFF;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination {
    color: #FFF;
    display: flex;
    align-items: center;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination .ant-pagination-slash {
    margin-inline-start: 0;
    margin-inline-end: 0;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination.ant-pagination-simple .ant-pagination-simple-pager input {
    background-color: rgba(0, 0, 0, 0.90);
    color: #FFF !important;
    width: 40px;
    border: 1px solid rgba(0, 0, 0, 0.90);
    margin-right: 8px;
    font-size: 14px !important;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-pagination .ant-pagination.ant-pagination-mini .ant-pagination-simple-pager {
    height: 30px;
    display: flex;
    align-items: center;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-scale {
    flex: 1;
    display: flex;
    gap: 8px;
    padding: 9px 12px;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-scale .zoom-min, .document-preview-modal .document-preview-bottom-bar .document-preview-scale .zoom-add {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.document-preview-modal .document-preview-bottom-bar .document-preview-scale .zoom-value {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.document-preview-modal .document-preview-top-bar .document-preview-top-bar-l {
    display: flex;
    align-items: center;
}
.document-preview-modal .document-preview-top-bar .document-preview-top-bar-l .document-preview-verify {
    width: 98px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #FFF;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    cursor: pointer;
}
.document-preview-modal .document-preview-top-bar .document-preview-top-name {
    font-size: 18px;
    font-weight: 500;
    margin-left: 12px;
}
.document-preview-modal.check-list .move-content {
    height: 100%;
    background-color: #FFF;
    padding: 68px 36px 24px 24px;
    font-family: Inter;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: end;
}
.document-preview-modal.check-list .move-content .file-name {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #222;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
}
.document-preview-modal.check-list .move-content .move-btn-confirm {
    width: 100px;
    height: 46px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px !important;
    margin-top: 12px;
}
.document-preview-modal.check-list .move-content .tips {
    color: #6E6E70;
    line-height: normal;
    margin-bottom: 30px;
}
.document-preview-modal.check-list .move-content .move-form-item {
    margin-bottom: 30px;
}
.document-preview-modal.check-list .move-content .move-form-item .move-form-label {
    color: #474849;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 14px;
}
.document-preview-modal.check-list .move-content .move-form-item .moveFile-select {
    height: 50px;
}
.document-preview-modal.check-list .move-content .move-form-item .moveFile-select .ant-select-selector {
    height: 50px !important;
}
.document-preview-modal.check-list .move-content .move-form-item .moveFile-select .ant-select-selector {
    background-color: #FCFCFC;
    color: #545459;
    font-family: Inter;
    font-size: 16px;
}