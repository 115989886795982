.navbar-prequalNav {
    background-color: #FFF;
}
.quick-view {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-application {
    display: flex;
    align-items: center;
    padding: 1px 0;
}
.nav-application .info-item {
    display: flex;
    margin-left: 20px;
}
.nav-application .info-item:not(:last-child) {
    margin-left: 24px;
}
.nav-application .info-item .info-item-label {
    font-size: 14px;
    color: #6E6E70;
    font-weight: 400;
    line-height: normal;
    margin-right: 6px;
}
.nav-application .info-item .info-item-value {
    font-size: 14px;
    color: #000;
    font-weight: 500;
    line-height: normal;
}
.nav-exit-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #325CEB;
    font-size: 16px;
    width: 88px;
    height: 44px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #325CEB;
    margin-left: 32px;
}
.new-navbar .navbar {
    border-bottom: 1px solid #DBDCDE;
    position: relative;
}
.new-navbar .asklogin-bar {
    padding: 12.5px 3vw !important;
}
.new-navbar .login-bar {
    padding: 5px 1rem !important;
    min-height: 55px;
}
.new-navbar .navbar .menubutton {
    margin-top: -65px;
}
.new-navbar .quick-view {
    height: 36px;
    border-bottom: 1px solid #DBDCDE;
}
.new-navbar .header-back {
    font-size: 16px;
    color: #373737;
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.new-navbar .companyName {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 0 !important;
    margin-right: 0 !important;
}
@media (max-width: 768px) {
    .new-navbar .sign-text {
        display: none;
    }
}
@media (max-width: 992px) {
    .nav-application {
        display: none;
    }
}
@media (max-width: 1000px) {
    .nav-exit-btn {
        display: none;
    }
}