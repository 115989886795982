@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

h1.closing-header {
    font-family: 'Playfair Display', serif !important;
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
    color: #222222;
}

.closing-message {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #222222;
    white-space: pre-wrap;
    max-width: 100%;
}

.closing-contact-header {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    color: #6E6E70;
}

.officer-cards-container {
    display: flex;
    gap: 20px;
    margin-top: 1rem;
}

.officer-card {
    flex: 1;
    background-color: #F5F5F7;
    border-radius: 20px;
    padding: 18px 32px;
    max-width: 366px;
}
