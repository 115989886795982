.borrower-form {

}
.borrower-form .question-icon{
    font-size: 14px;
    margin-left: 10px;
    margin-bottom: 30px;
    color: #545459;
}
.borrower-form .co-borrower {
    display: flex;
    align-items: center;
}
.borrower-form .co-borrower .co-borrower-item {
    margin-bottom: 0;
    margin-right: 18px;
}
.borrower-form .co-borrower .co-borrower-text {
    color: #222;
    font-size: 14px;
    font-weight: bold;
}
.borrower-modal .ant-modal-header {
    margin-bottom: 16px;
}
.borrower-modal .ant-modal-header .ant-modal-title {
    font-size: 24px;
    color: #000;
    font-weight: bold;
}
.borrower-modal .borrower-modal-content {
    padding: 32px 0;
    font-size: 16px;
    color: #000;
}
.borrower-modal .ant-modal-content{
    padding: 44px 46px 38px;
}
.borrower-modal .ant-modal-footer .go-btn {
    width: 188px;
    height: 44px;
    font-size: 16px;
    color: #FFF;
    font-weight: bold;
    background-color: #325CEB;
}
.borrower-modal .ant-modal-content .ant-modal-close {
    display: none;
}

input[disabled] {
    pointer-events: none;
}
