.loan-amount {
    font-size: 16px;
}
.loan-amount-value {
    font-weight: bold;
}
.view-current-label {
    position: absolute;
    top: -30px;
    left: 0;
    background: #D7E2FB;
    font-size: 15px;
    line-height: 30px;
    padding: 0 15px;
}
.view-current-label-triangle {
    position: absolute;
    left: 20px;
    top: 0;
    height: 10px;
    width: 20px;
    clip-path: polygon(100% 0, 0 0, 50% 100%);
    background: #D7E2FB;
}
.myhardmoneyloan {
	background-color: white;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	min-height: 150px;
    cursor: pointer;
    position: relative;
}
.delete-menu-btn {
    color: #ff4d4f !important;
}
