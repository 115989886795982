.nonqm-qualifier {
  padding: 20px 200px;
}

.nonqm-qualifier .ant-radio-wrapper {
    margin-bottom: 14px !important;
}
.nonqm-qualifier .ant-checkbox-wrapper {
  margin-bottom: 14px !important;
}
.nonqm-qualifier .ant-otp .ant-otp-input {
  padding-inline: 11px;
}

.nonqm-qualifier .ant-input {
  border-radius: 8px;
  border: 1px solid #ECECEC;
  background: #FCFCFC;
  height: 42px;
}



  
  .program-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  
  .program-card {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px 25px 15px 25px;
    max-width: 280px;
    height: 200px;
    border: 1px #ececec solid;
    background-color: #fcfcfc;
    line-height: 2;
    text-align: left;
    min-width: 260px;
  }
  
  .program-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  
  .learn-more {
    color: #4285f4;
    text-decoration: none;
  }
  
  .loan-officer-info {
    background-color: #f0f4ff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .send-info-btn {
    background-color: #4285f4;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }

  .nonqm-result {
    text-align: center;
  }
  


  .email-verificaition-simple .email-input {
    border-radius: 8px 0 0 8px !important;
    border-right: none;
  }
  
  .email-verificaition-simple .ant-btn-suffix {
    height: 42px;
    background-color: rgb(252, 252, 252);
    border-top: 1px solid rgb(236, 236, 236);
    border-right: 1px solid rgb(236, 236, 236);
    border-bottom: 1px solid rgb(236, 236, 236);
    border-left: none;
    border-image: initial;
    color: rgb(50, 92, 235);
    box-shadow: none;
    border-radius: 0 8px 8px 0 !important;
  }
  .email-verificaition-simple .ant-btn-suffix:disabled {
    color: #ccc;
  }

  .email-verificaition-simple .code-area {
    margin-left: 20px;
    min-width: 180px;
  }

  @media (max-width: 1100px) {
    .nonqm-qualifier {
      padding: 20px 120px;
    }

  }

  @media (max-width: 500px) {
    .nonqm-qualifier {
      padding: 0 20px;
    }

  }