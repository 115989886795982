.currentTasksWrap .ant-table-thead > tr > th {
  border-bottom: 1px solid #000000;
  background-color: transparent !important;
}
.currentTasksWrap .ant-table .ant-table-cell-fix-right {
  background-color: #fff !important;
  z-index: 0;
}
@media (max-width: 575px) {
  .taskPageWrap {
    margin: -20px !important;
  }
}
.expand-row-wrapper {
  background: #f7f8fc;
}
.expand-row-wrapper .pdfIcon {
  flex-grow: 0;
}
.expand-row-wrapper .info {
  flex-grow: 1;
}
.deleteBtn {
  flex-grow: 0;
}
.expand-row-wrapper .name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.expand-row-wrapper .body{
  margin-top: 10px;
}
.expand-row-wrapper .header-title{
  color: #6E6E70;
  margin-top: 10px;
}
.expand-row-wrapper .header-right {
  display: flex;

}
.expand-row-wrapper .submit-btn{
  width: 110px;
}
.expand-row-wrapper .upload-btn{
  margin-right: 10px;
}
.ant-upload-list-item-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-modal .ant-modal-close-x {
  display: flex;
  justify-content: center;
  align-items: center;
}
.status-tag {
  width: 122px;
  height: 30px;
  border-radius: 14.5px 15px 15px 14.5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;

}

.pdfIcon {
  width: 22px;
  height: 22px;
}
.file-slab {
  display: flex;
  position: relative;
  height: 60px;
  border-radius: 4px;
  border: 1px;
  background: #ececec;
  padding: 10px;
  border: 1px transparent solid;
}
.file-slab:hover {
  border: 1px #222 solid;
}
.body {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}
.item {
  border: 1px solid black;
}

.body .info {
  margin-left: 12px;
}
.body .name {
  min-width: 140px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.body .size {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}
.body .deleteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}
.submit-time {
  color: #6E6E70;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
