.display-container,
.display-container:hover {
  height: 100%;
  width: 100%;
  padding: 1em 1.5em 1em 0.5em;
  align-items: center;
  margin: 0em !important;
}

.display-container:first-child {
  border-radius: 1em 1em 0em 0em;
}

.display-container:last-child {
  border-radius: 0em 0em 1em 1em;
}

.display-container:hover {
  background-color: #f0f0f0;
}

.display-container-active {
  background-color: #e0e7ff !important;
}

.pdf-icon-container {
  height: 100%;
}

.text-container {
  white-space: pre;
}

.text-container .main-text-container span {
  font-size: 16px;
}

.text-container .sub-text-container span {
  font-size: 14px;
  color: #666;
}
