
.signPage-Body{
    height: 100%;
    width: 100%;
}

.codeVerifyPane{
    height: 100%;
    width: 100%;
}

.codeVerifyHeader{
    border-bottom: 1px solid #D9D9D9;
    height: 72px;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 40px;
}

.codeVerifyBody{
    height: calc(100% - 72px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.codeVerifyWrap{
    font-size: 16px;
    width: 493px;
    height: 546px;
    border: 1px solid #F6F6F6;
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.codeVerifyTittle{
    margin-bottom: 40px;
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
}

.codeVerifyBodyText{
    margin-bottom: 50px;
}

.noValidCode{
    font-size: 14px;
    margin-top: 40px;
    cursor: pointer;
    color: #325CEB
}

.noValidMessageTitle{
    margin-bottom: 40px;
    width: 100%;
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    color: #325CEB;
}

.noValidMessageText{
    margin-bottom: 40px;
    width: 100%;
    text-align: left;
    font-size: 16px;
}

.closeNoValidMessage{
    font-size: 16px;
    color: white;
    background-color: #325CEB;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}

.closeNoValidMessage:hover{
    background-color: #325CEB !important;
    cursor: pointer;
    color: white !important;
    border: 1px solid black !important;
}

.verifyCodeButton{
    font-size: 16px;
    color: white;
    background-color: #325CEB;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin-top: 50px;
}

.verifyCodeButton:hover{
    background-color: #325CEB !important;
    cursor: pointer;
    color: white !important;
    border: 1px solid black !important;
}

.PDFPage-pane{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #D9D9D9;
    z-index: 10;
}

.PDFPage-topnav{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    color: #545459;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: white;
    z-index: 11;
    gap: 40px;
    border-bottom: 1px solid #D9D9D9;
}

.PDFPage-bottomnav{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    font-weight: 700;
    font-size: 16px;
    color: white;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    z-index: 11;
    border-top: 1px solid #D9D9D9;
}

.PDFsign-mark{
    font-size: 26px;
}

.declineSign{
    position: absolute;
    left: 40px;
    font-size: 16px;
    font-weight: 600;
}

.declineSign:hover{
    color: black !important;
}

.authorizeSign{
    position: absolute;
    right: 40px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    background-color: #325CEB;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
}

.authorizeSign:hover{
    border: 1px solid black !important;
    background-color: #325CEB !important;
    color: black !important;
    cursor: pointer;
}

.signatureStatus{
    width: 182px;
    height: 45px;
    padding: 5px 10px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.signSuccessModal{
    width: 540px !important;
    height: 381px !important;
}

.signSuccessModal .ant-modal-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px
}

.signDeclineModal{
    width: 540px !important;
    height: 381px !important;
}

.signDeclineModal .ant-modal-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px
}

.confirmModalTitle{
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: black;
    margin-bottom: 30px;
}

.confirmModalBodyText{
    width: 100%;
    text-align: left;
    font-size: 16px;
    margin-bottom: 15px;
}

.confirmModalBtn{
    margin-top: 15px;
    width: 220px;
    height: 45px;
    color: white;
    background-color: #325CEB;
    padding: 13px 15px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}