@import url('https://fonts.googleapis.com/css?family=Inter');

body,
div {
    font-family: 'Inter' !important;
}

#alterra-chat-button {
    background-color: rgba(33, 93, 244, 0.773) !important;
    border-radius: 15px;
    bottom: 1em !important;
    right: 0.9em !important;
}

#chartcontainer div {
    width: 100% !important;
}

#clearsources {
    height: 2.1em !important;
}

#contain {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding-top: 20px;
}

.zeitro-checkbox {

    margin-bottom: 0 !important;
}

.FormALabel {
    white-space: nowrap !important;
}

/* .nameBar .ant-tooltip-content{
    width: 400px!important
} */
/* .ant-tooltip-content {
    width: 400px;
    text-align: center;
} */

.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before {
    border-bottom-color: #EAEFFD;
}

@media (max-width: 820px) {
    .tooltip-inner {
        max-width: 350px;
    }
}

.bs-tooltip-auto[x-placement^=right] .arrow::before,
.bs-tooltip-right .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #EAEFFD;
}

/* .input-groups>.form-control {
    height: 2.75rem !important;
} */
.form-control:hover,
input.form-control:focus {
    border: 1px solid #0d99ff !important;
    border-radius: 4px !important;
}

label.form-label {
    color: #586570;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    white-space: normal;
}


.btn-selections:not(:disabled):not(.disabled):hover {
    border: 1px solid #325CEB !important;
    background-color: #fff !important;
    color: #000000 !important;
}

.btn-selections:not(:disabled):not(.disabled) {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    padding: 0.812rem 1rem 0.812rem 0.812rem !important;
    border: 1px solid #CFD4D9 !important;
    background-color: #fff !important;
    color: #222222 !important;
    border-radius: 0.25rem !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
    line-height: unset !important;
}

.btn-selections:not(:disabled):not(.disabled).active {
    box-shadow: unset !important;
    padding: 0.812rem 1rem 0.812rem 0.812rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    border: 1px solid #325CEB !important;
    background-color: #fff !important;
    color: #222222 !important;
    border-radius: 0.25rem !important;
}



input[type=checkbox]:checked {
    background: url(./Common/icon/check.svg) no-repeat -0.1875rem -0.1875rem;
    background-size: 1.5rem 1.5rem;
}

input[type=checkbox] {
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid #CFD4D9;
    box-sizing: border-box;
    margin-top: 0.125rem;
    /* position: relative; */
    border-radius: 0.25rem;
    appearance: none;
    /* background: url(./Common/icon/unCheck.svg) no-repeat -0.1875rem -0.1875rem; */
    background-size: 1.5rem 1.5rem;
    overflow: hidden;
}

/* @media (max-width: 480px) {
	input[type=checkbo.x] {
		width: 58px;
	}
} */

input[type=checkbox]:hover {
    border: 1px solid #325CEB;
}

.zeitro-radio {
    font-size: 14px;
}

.yes-no-selection-selected {
    border: 1px solid #325CEB;
    border-radius: 4px;
    height: 44px;
    width: 180px;
    padding-top: 8px;
    padding-left: 13px;
}

.yes-no-selection-unselected {
    border: 1px solid #CFD4D9;
    border-radius: 4px;
    height: 44px;
    width: 180px;
    padding-top: 8px;
    padding-left: 13px;
}

.ssn {
    --ReactInputVerificationCode-itemWidth: 57px;
    --ReactInputVerificationCode-itemHeight: 70px;
}

@media (max-width: 480px) {
    .ssn {
        margin-top: 9px;
        --ReactInputVerificationCode-itemSpacing: 0.4rem;
        --ReactInputVerificationCode-itemWidth: 28px;
        --ReactInputVerificationCode-itemHeight: 40px;
    }

}

@media (max-width: 480px) {
    .loginEmailVerify {
        --ReactInputVerificationCode-itemSpacing: 0.4rem;
    }
}

.btn-zeitro-primary {
    height: 44px;
    min-width: 8rem;
    border: none !important;
    border-radius: 4px !important;
    color: #fff !important;
    background-color: #325CEB !important;
    font-size: 16px !important;
}

.btn-zeitro-outline {
    height: 44px;
    min-width: 8rem;
    border: none !important;
    border-radius: 4px !important;
    color: #325CEB !important;
    border: 1px solid #325CEB !important;
    font-size: 16px !important;
}

.btn-zeitro-primary:disabled {
    background-color: #D9D9D9 !important;
}

.prequalBigText {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
}

.prequalSmallText {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.prequalCard {
    background: #F5F5F7;
    border-radius: 4px;
    padding: 15px 40px 15px 40px;
    font-size: 14px;
}

@media (max-width: 480px) {
    .prequalCard {
        padding: 10px;
    }
}





.ratesHeader {
    font-weight: 600;
    font-size: 20px;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 10px;
}

.filterDropdown {
    width: 400px;
    height: 500px !important;
    max-height: 600px !important;
}

.prequalRates {
    /* border-bottom: 1px solid #D9D9D9; */
    font-size: 16px;
}

.prequalRatesCard {
    background: #F5F5F7;
    border-radius: 4px;
    padding: 14px 38px 14px 38px;
}

.chosenRatesCard {
    background: #F5F5F7;
    border-radius: 4px;
    padding: 14px 38px 14px 38px;
    margin: 10px 20px !important;
}

.ratesDropdown {
    display: flex;
    justify-content: space-between;
    width: 156px;
    border: 1px solid #CFD4D9;
    border-radius: 4px;
    padding: 6px 10px 6px 12px;
    font-weight: 400;
    font-size: 16px;
}

.displayButtons {
    display: flex;
    justify-content: flex-end;
}

.displayFilters {
    display: flex;
}

@media (max-width: 480px) {
    .ratesDropdown {
        /* width: 100%; */
    }

    .displayButtons {
        display: block;
    }

    .displayFilters {
        justify-content: center;
        margin-top: 8px;
    }

    .yourRatesTitle {
        text-align: center;
        font-family: 'Inter' !important;
        font-style: normal;
        font-weight: 700;
        font-size: 30px !important;
        line-height: 37px;
        margin-bottom: 20px;
    }
}

.sidebar-button {
    border: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #1F3988;
    padding: 10px;
    width: 100%;
    text-align: left;
}

.sidebar-button:hover {
    cursor: pointer;
    background-color: #8095d0;
}


.sidebar-button-selected {
    border: none;
    background-color: #1F3988;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: white;
    padding: 10px;
    width: 100%;
    text-align: left;
}

.w-600px {
    max-width: 600px;
}

#envelope {
    padding: 30px;
}

.preappModalTitle {
    font-weight: 500;
    font-size: 24px;
}

@media (max-width: 480px) {
    #envelope {
        padding: 10px;
    }

    .preappModalTitle {
        font-weight: 500;
        font-size: 20px;
    }
}

@media (max-width: 480px) {
    .fixCol {
        margin-top: 10px;
    }
}

.mainSidebarWrap {
    width: 52px;
    background-color: white;
}

.hidemainSidebar {
    display: none;
}

.prequalInterviewPane {
    /* padding: 40px; */
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    width: 100%;
    margin-right: 40px;
    margin-bottom: 50px;
    min-height: 500px;
}

.interviewEmailLabel {
    font-size: 20px !important;
    font-family: 'Inter' !important;
}

@media (max-width: 800px) {
    .prequalInterviewPane {
        background: #FFFFFF;
        border: none;
        border-radius: 8px;
        width: 100%;
        margin-right: 0px;
        margin-bottom: 50px;
        padding-left: 10px;
    }
}

.prequalPane {
    padding: 40px;
    background: #FFFFFF;
    margin-bottom: 50px;
    min-height: 800px;
}


.siderbarpage {
    width: 100%;
    height: 100vh;
    background: #fdfdfd;

    letter-spacing: .03em;
    color: #212121;
}


#nav-container {
    position: fixed;
    height: 100vh;
    width: 100%;
    pointer-events: none;
    z-index: 2;
}

#nav-container .bg {
    /* z-index: 1000; */
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: .3s;
    background: #000;
}

.nbg {
    visibility: visible !important;
    opacity: .6 !important;
}

#nav-container * {
    visibility: visible;
}

.menubutton {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    border-radius: 0;
    width: 30px;
    cursor: pointer;
    pointer-events: auto;
    margin-left: 25px;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    right: 0;
    margin-top: -37px;
    margin-right: 20px;
}

.icon-bar {
    display: block;
    width: 100%;
    height: 3px;
    background: #aaa;
    transition: .3s;
}

.icon-bar+.icon-bar {
    margin-top: 5px;
}

.oneIcon {
    /* margin-top: 0px; */
    transform: translate3d(0, 8px, 0) rotate(45deg);

}

.twoIcon {
    opacity: 0;
}

.threeIcon {
    /* margin-top: 0px; */
    transform: translate3d(0, -8px, 0) rotate(-45deg);

}

#nav-container:focus-within .menubutton {
    pointer-events: none;
}

/* #nav-container:focus-within .icon-bar:nth-of-type(1) {
}

#nav-container:focus-within .icon-bar:nth-of-type(2) {
    opacity: 0;
}

#nav-container:focus-within .icon-bar:nth-of-type(3) {
    transform: translate3d(0, -8px, 0) rotate(-45deg);
} */

#nav-content {
    position: absolute;
    top: -60px;
    right: 0;
    width: 100%;
    background: #ececec;
    pointer-events: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateY(-100%);
    transition: transform .3s;
    will-change: transform;
    contain: paint;
}

#focusNav {
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    background: #ececec;
    pointer-events: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateY(0);
    transition: transform .3s;
    will-change: transform;
    contain: paint;
}

/* #nav-container:focus-within #nav-content {
    transform: none;
} */


.navbar-fixed-top {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1002;
    background: white;
}

.navbar-fixed-top.cbp-af-header-shrink {
    padding: 10px 0;
}

.form-control {
    font-size: 1rem;
    height: 2.75rem !important;
}

.interviewpanes * {
    pointer-events: none;
}

.antSpinWrapper {
    height: 100%;
}

.antSpinWrapper .ant-spin-container {
    height: 100%;
}

.antSpinWrapper .sidenav-application {
    height: 100% !important;
}

.iframeHeightAdjuster {
    height: 100% !important;
}

.iframeFieldset {
    height: calc(100% - 80px);
}

.full-height-modal .ant-modal {
    padding-bottom: 0px;
    top: 0 !important;
}

.full-height-modal .ant-modal-body {
    margin-top: 45px;
}

.full-height-modal .ant-modal .ant-modal-content {
    height: 100vh;
    border-radius: 0px;
}

.full-height-modal .ant-modal .ant-modal-title {
    font-size: 20px;
}