.home-card {
    display: flex;
    flex-shrink: 0;
    background-color: #f5f5f7;
    border-radius: 10px;
    padding: 21px 36px 21px 23px;
    min-height: 159px;
}

.loan-tracker {
    display: flex;
    flex-direction: column;
    padding: 21px 36px 21px 23px;
    border: 1px solid #dbdcde;
    border-radius: 10px;
    min-height: 159px;
}

.loan-summary-table {
    border: 1px solid #dbdcde;
    border-radius: 10px;
    padding: 21px 36px 21px 23px;
}

.next-step {
    border: 1px solid #dbdcde;
    border-radius: 10px;
    padding: 30px 24px 24px 23px;
}
.loan-tracker .loan-tracker-content {
    display: flex;
    align-items: center;
}
.loan-tracker .loan-tracker-content .loan-tracker-step {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    width: 100%;
}
.loan-tracker .loan-tracker-content .loan-tracker-step-line {
    width: 26px;
    height: 1px;
    background-color: #D9D9D9;
    margin: 0;
    position: relative;
    top: 1px;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps {
    width: 100%;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-icon {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #325CEB;
    border-color: #325CEB;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon {
    color: #FFF;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color: #E0E7FF;
    border-color: #325CEB;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon {
    color: #325CEB;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-wait .ant-steps-item-icon {
    background-color: #FFF;
    border-color: #D9D9D9;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon {
    color: #6E6E70;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title,
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
    color: #325CEB;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #325CEB;
}
.loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps .ant-steps-item-tail::after {
    height: 2px;
    background-color: #D9D9D9;
}
.loan-tracker .loan-tracker-content .loan-tracker-step.mobile-loan-tracker-step {
    display: none;
}
.loan-tracker .loan-tracker-content .loan-tracker-step.desktop-loan-tracker-step {
    display: flex;
}
@media (max-width: 400px) {
    .loan-tracker .loan-tracker-content .loan-tracker-step .overview-steps {
        margin-left: -18px;
    }
}
@media (max-width: 768px) {
    .loan-tracker {
        padding: 20px 16px;
    }
    .loan-tracker .loan-tracker-content {
        flex-direction: column;
        align-items: flex-start;
    }
    .loan-tracker .loan-tracker-content .loan-tracker-step {
        flex-direction: column;
    }
    .loan-tracker .loan-tracker-content .loan-tracker-step .loan-tracker-step-line {
        width: 1px;
        height: 26px;
    }
    .loan-tracker .loan-tracker-content .loan-tracker-step.mobile-loan-tracker-step {
        display: block;
    }
    .loan-tracker .loan-tracker-content .loan-tracker-step.desktop-loan-tracker-step {
        display: none;
    }
}