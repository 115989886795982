.side-bar-container {
    width: 302px;
    height: 100%;
    min-width: 266px;
    max-width: 302px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #ffffff;
    color: black;
    overflow: hidden;
    border-right: 1px solid #dbdcde;
    padding-left: 14px;
    padding-right: 12px;
    z-index: 1;
}

.application-overview {
    padding: 15px 12px;
    border-radius: 10px;
}

.sidebar-footer {
    position: relative;
    padding-top: 1px;
    margin-top: -1px;
}

.sidebar-footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: -14px;
    right: -12px;
    height: 1px;
    background-color: #DBDCDE;
}

.sidebar-footer-divider {
    position: absolute;
    top: 0;
    left: -14px;
    right: -12px;
    height: 1px;
    background-color: #DBDCDE;
}

.sidebar-footer-content {
    padding: 0 12px;
}

.overflow-h {
    width: 120px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar-steps {
    position: relative;
    margin-top: 10px;
}

.custom-step {
    padding: 8px 0 8px 12px;
    position: relative;
}

.step-header {
    display: flex;
    align-items: center;
}

.step-number {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin-right: 8px;
    background-color: #D9D9D9;
    color: #ffffff;
}

.step-number.active {
    background-color: #081265;
    color: #fcfcfc;
}

.step-title {
    font-size: 14px;
    font-weight: 600;
    color: #6e6e70;
}

.step-title.active {
    color: #081265;
}

.step-content {
    margin-left: 12px;
    margin-top: 8px;
    padding-bottom: 2px;
}

.step-content::before {
    content: '';
    position: absolute;
    left: 22px;
    top: 40px;
    bottom: 12px;
    width: 2px;
    background-color: #D9D9D9;
    border-radius: 4px;
}

.step-content.active::before {
    background-color: #081265;
}

.sub-step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 9px;
    margin-bottom: 4px;
    height: 40px;
    padding: 8px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #222222;
}

.custom-step.disabled .step-content::before {
    display: none;
}

.sub-step.active {
    background-color: #E0E7FF;
    border-radius: 6px;
    color: #325CEB;
}

.sub-step.disabled {
    color: #6E6E70;
}

.progress-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin-left: auto;
}

.sub-step .ant-progress-circle {
    width: 100% !important;
    height: 100% !important;
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8px;
    font-weight: 700;
    color: #222222;
    z-index: 2;
    line-height: 1;
    text-align: center;
}

.sub-step .ant-progress-text {
    display: none !important;
}

.progress {
    font-size: 12px;
    color: #325ceb;
}

.sub-step-icon {
    width: 28px;
    height: 28px;
}

.sub-step.active {
    background-color: #E0E7FF;
    border-radius: 6px;
    color: #325CEB;
}

.step-icon {
    width: 24px;
    height: 24px;
}

.sub-step-icon {
    width: 28px;
    height: 28px;
}

.step-menu-container {
    background: #FFFFFF;
    height: 100vh;
    min-width: 220px;
    overflow-y: auto;
    padding: 33px 33px 0 0;
}

.step-menu-item {
    display: flex;
    height: 20px;
    align-items: center;
    cursor: pointer;
}

.step-menu-item:has(+ .sub-menu-container) {
    margin-bottom: 16px;
}

.step-menu-text {
    font-size: 16px;
    font-weight: 500;
}

.step-gray-circle {
    width: 20px;
    height: 20px;
    border: 2px solid #D9D9D9;
    border-radius: 50%;
}

.step-blue-circle {
    width: 20px;
    height: 20px;
    border: 2px solid #325CEB;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step-center-dot {
    width: 8px;
    height: 8px;
    background: #325CEB;
    border-radius: 50%;
}

.step-green-circle {
    width: 20px;
    height: 20px;
    border: 2px solid #2E605A;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub-menu-container {
    text-align: left;
    margin-left: 32px;
    margin-bottom: 20px;
}

.sub-menu-item {
    display: flex;
    align-items: center;
}

.user-full-name {
    width: 100%;
    min-width: 0;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-email {
    width: 100%;
    min-width: 0;
    overflow: hidden;
    color: #6E6E70;
    font-size: 14px;
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.overview-title {
    font-size: 14px;
    font-weight: 600;
}

.address-text {
    color: #222222;
    font-weight: 400;
    font-size: 16px;
}

.purpose-text {
    margin-top: 10px;
    padding: 6px 10px;
    background-color: #FFFFFF;
    color: #13358D;
    font-weight: 600;
    font-size: 12px;
    border: 1px solid #13358D;
    border-radius: 18px;
}

.back-to-loans {
    cursor: pointer;
    height: 24px;
    padding-left: 12px;
    margin-top: 11px;
    margin-bottom: 13px;
}

.back-to-loans-icon {
    width: 24px;
    height: 24px;
}

.back-to-loans-text {
    color: #222222;
    font-size: 14px;
    font-weight: 500;
}

.company-name-container {
    height: 70px;
    padding: 15px 12px;
    margin-bottom: 1px;
}

.company-name {
    font-size: 20px;
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.company-name-underline {
    position: absolute;
    bottom: -1px;
    left: -14px;
    right: -12px;
    height: 1px;
    background-color: #DBDCDE;
}

.new-tag {
    font-size: 10px;
    font-weight: 600;
    padding: 0 6px;
    border-radius: 4px;
    margin-right: 0;
}
@media (max-width: 768px) {
    .side-bar-container {
        padding: 0;
    }
    .application-overview {
        border-radius: 0;
    }
    .step-menu-container {
        padding: 16px;
        height: auto;
    }
    .company-name-container {
        display: none !important;
    }
    .step-number {
        display: none;
    }
    .sidebar-footer {
        display: none;
    }
    .custom-step.processing-step {
        display: none;
    }
    .custom-step.closing-step {
        display: none;
    }
    .progress-wrapper {
        display: none;
    }
}
