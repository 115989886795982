.prequalInterviewTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.2px;
    font-feature-settings: 'pnum' on, 'lnum' on;
    color: #333333;
    white-space: normal
}

.page-header {
    display: flex;
    align-items: center;
}

.page-header-primary {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #222222;
}

.page-header-separator {
    margin: 0 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #6E6E70;
}

.page-header-secondary {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: #6E6E70;
    white-space: wrap !important;
}

.ant-input-number {
    border-radius: 4px;
}

.ant-input-number-group-addon {
    border-radius: 4px;
}

:where(.css-dev-only-do-not-override-sk7ap8).ant-input-number-group-wrapper-lg .ant-input-number-group-addon {
    border-radius: 4px;
}

.addAddress {
    background: #F7F8FC;
    border-radius: 8px;
    padding: 0 15px 15px 15px;
}

.prequalInterviewSubTitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #6E6E70;
}

.prequalInterviewH3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}


.prequalInterviewDisclaimer {
    font-weight: 400;
    font-size: 14px;
    color: #222222;
    white-space: pre-wrap;

}

.ant-form-item-explain-error {
    text-align: left !important;
}

 a:hover {
    text-decoration: none !important;
}

.prequalInterviewPane .operate-btn {
    width: 45%;
    max-width: 188px;
    height: 46px;
    font-weight: 600;
    border-radius: 6px !important;
}

.prequalInterviewPane .back {
    color: #325CEB !important;
    border: 1px solid #E3EAFD !important;
    background: #F7F8FF !important;
}

.prequalInterviewPane .application-bottom-bar {
    height: 62px;
    width: 100%;
    padding: 0 45px;
    position: absolute;
    bottom: 0;
    z-index: 999;
    border-top: 1px solid #ECECEC;
    background-color: #FFF;
    /* display: flex;
    justify-content: space-between;
    flex-direction: row-reverse; */
    align-items: center;
}

.xxl-padding {
    padding: 30px;
}

.full-height {
    height: calc(100vh - 62px);
    margin-right: 24px;
    overflow-y: auto;
}
.wrap-text {
    white-space: wrap !important;
}
.ant-spin-nested-loading .application-loding {
    max-height: initial !important;
}

.padding {
    padding: 30px 30px 0px;

}

.InputWithSignWrap {
    border: 1px solid #ECECEC;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: #FCFCFC;
    height: 44px;
}

.InputWithSignWrap:focus-within {
    border: 1px solid #325CEB !important;
}

.InputWithSignWrap:hover {
    border: 1px solid #000000 !important;
}

.InputWithSignWrap:focus {
    border: 1px solid #325CEB !important;
}

.InputWithSign-input {
    border: none;
    background-color: transparent;
}

.InputWithSign-input:focus {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent;
}

.InputWithSign-input:hover {
    border: none !important;
}

.InputWithSign-prefix {
    font-size: 14px;
    padding-left: 8px;
}

.phone-input-container {
    display: flex;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    overflow: hidden;
    max-width: 100%;
}
.phone-input-container .form-control:focus,
.phone-input-container .form-control:hover,
.phone-input-container .form-control.is-invalid,
.phone-input-container .form-control.error {
    border-radius: 8px 0 0 8px !important;
}
.form-group .phone-input-container .phone-input-form-control {
    flex: 2;
    border: none;
    border-radius: 8px 0 0 8px;
    box-shadow: none;
}
.phone-input-container .contact-select .ant-select-selector {
    border: none !important;
    border-radius: 0 8px 8px 0 !important;
    box-shadow: none !important;
}

.loan-officer-button {
    right: 20px;
    bottom: 20px;
    z-index: 999;
    width: 48px;
    height: 48px;
    border: none;
    box-shadow: 0 2px 8px rgba(8,18,101,0.25);
}

.loan-officer-card {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 249px;
    height: 191px;
    background-color: #F5F5F7;
    border: 1px;
    border-color: #ECECEC;
    border-radius: 16px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    transition: all 0.3s ease;
    z-index: 1000;
}

.card-close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    font-size: 16px;
    color: #6E6E70;
    transition: color 0.3s ease;
}
.applicant-months-input {
    padding-left: 12px;
    padding-right: 0;
}
.applicant-checkbox .ant-checkbox {
    align-self: baseline;
}
.address-wrap {
    margin-left: -15px !important;
    margin-right: -15px !important;
}
.ful-width-wrap {
    margin-left: -15px !important;
}
.income-dollar-input .ant-input-number-prefix {
    color: #6E6E70;
}
.income-checkbox .ant-checkbox {
    align-self: stretch;
    padding-top: 2px;
}
.previous-employment-checkbox.form-check {
    align-items: stretch;
}
.previous-employment-checkbox .form-check-label {
    flex: 1;
}
.previous-employment-checkbox .form-check-input {
    margin-top: 4px;
}
.previous-employment-checkGroup {
    width: 100%;
}
.finance-checkbox {
    white-space: wrap !important;
    align-items: initial;
}
.finance-checkbox .ant-checkbox {
    align-self: baseline;
    margin-top: 2px;
}
.application-interview-mobile-message-container .ant-message {
    top: auto !important;
    bottom: 8px;
    color: #2E605A;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
}
.application-interview-mobile-message-container .ant-message .mobile-message .ant-message-notice-content {
    background: rgba(225, 242, 226, 0.95);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);
}
.application-interview-mobile-message-container .ant-message .ant-message-notice-wrapper .ant-message-success>.anticon {
    color: #2E605A;
}
.mobile-tips-drawer {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: #fff;
}
.mobile-tips-drawer .ant-drawer-body {
    padding: 20px 18px;
}

.mobile-tips-close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}
.mobile-tips-close div {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile-tips-title {
    color: #222;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24px;
}

.mobile-tips-body {
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    padding: 0 8px;
}
.application-interview-submit-modal .ant-modal-content {
    padding: 60px 16px 20px;
}
.application-interview-submit-modal .ant-modal-content .mobile-modal-title {
    color: #222;
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
}
.application-interview-submit-modal .ant-modal-content .mobile-modal-content {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    line-height: 28px;
}
.application-interview-submit-modal .ant-modal-content .mobile-modal-footer {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 16px;
}
.application-interview-submit-modal .ant-modal-content .mobile-modal-footer .mobile-modal-footer-button-cancel {
    width: 112px;
    height: 50px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
}
.application-interview-submit-modal .ant-modal-content .mobile-modal-footer .mobile-modal-footer-button-submit {
    width: 120px;
    height: 50px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    border-radius: 8px !important;
    background-color: #325CEB;
}


@media (max-width: 480px) {
    .prequalInterviewDisclaimer {
        font-size: 12px;
    }
}

@media (max-width: 768px) {
    .prequalInterviewPane .application-bottom-bar {
        position: fixed;
        left: 0;
    }

    .padding {
        padding: 20px 20px 0px;
    }

    .full-height {
        height: calc(100vh - 132px);
        margin-right: 0;
        overflow-y: visible;
    }
    .loan-officer-button {
        bottom: 72px !important;
    }
    .applicant-months-input {
        margin-top: 12px;
        padding-left: 0 !important;
    }
    .application-pane-container {
        padding-bottom: 62px;
    }
    .newInterviewPane {
        padding: 115px 3px 3px 3px;
    }
}

@media (min-width: 1400px) {
    .xxl-padding {
        padding: 30px 30px 30px 30px;
    }

    .prequalInterviewPane .application-bottom-bar {
        padding: 0 160px 0 45px;
    }
}

@media (max-width: 575px) {
    .xxl-padding {
        padding: 0 !important;
    }
    .prequalInterviewPane .application-bottom-bar {
        padding: 0 15px;
    }
    .padding {
        padding: 0;
    }

    .prequalInterviewSubTitle {
        padding-top: 27px !important;
    }

    .newInterviewPaneProperty {
        padding: 0 5px 15px 15px;
    }

    .newInterviewPaneEmployment {
        padding: 0 15px 15px 15px;
    }
    .page-header-row {
        display: none;
    }
    .addAddress {
        padding: 0 0 15px 0 !important;
    }
    .phone-input-container {
        gap: 10px;
        flex-direction: column;
        flex-direction: column-reverse;
        border: none;
    }
    .phone-input-container .form-control:focus,
    .phone-input-container .form-control:hover,
    .phone-input-container .form-control.is-invalid,
    .phone-input-container .form-control.error {
        border-radius: 8px !important;
    }
    .form-group .phone-input-container .phone-input-form-control {
        border: 1px solid #d9d9d9;
        padding: 11px 0.75rem;
        border-radius: 8px !important;
    }
    .phone-input-container .phone-input-separator {
        display: none;
    }
    .phone-input-container .contact-select .ant-select-selector {
        border: 1px solid #d9d9d9 !important;
        border-radius: 8px !important;
    }
    .application-pane-container {
        padding-bottom: 62px;
    }
}