.input-text-percentage {
    border: none !important;
    width: 15%;
    font-size: 16px;
}
.input-text-dollar {
    border: none !important;
    width: 36%;
    font-size: 16px;
}
.bar-sign-percentage {
    margin: 6px;
}

.prefix-percent {
    margin: 15px;
}

.percentageBulb{
    border : 1px solid #D6E2FB;
    background-color: #D6E2FB;
    border-radius: 11.5px;
    padding: 5px 10px 5px 10px;
    font-size: 14px;
    font-weight: 500;
    color: #1F3988;
    line-height: normal;

}

@media (max-width: 480px) {
    .prefix-percent {
        margin: 10px;
    }
}