.special-rate-dna {
  background-color: #ffffff;
  height: auto;
  padding: 40px 0px 40px 0px;
  .single-program-container {
    margin: 0% 10% 2.5% 10%;
    background-color: white;
    min-height: 50%;
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .specialHeaderButton {
    font-size: 16px !important;
    cursor: pointer;
    font-weight: 500;
    margin: 0 20px;
  }

  .programInitialGuideWrap {
    background-color: #ffffff;
    position: relative;
    min-height: 200px;
    align-items: center;
    padding: 20px 80px 80px 80px;

    .slideDotsWrap {
      padding: 10px 40px;
    }

    .slide {
      text-align: center;
    }

    .title-1 {
      font-size: 32px !important;
      line-height: 40px;
    }

    .title-3 {
      font-size: 20px !important;
      line-height: 25px;
    }

    .ant-carousel .slick-dots li.slick-active button {
      background: #325ceb !important;
    }
    .ant-carousel .slick-dots li button {
      background: #d9d9d9 !important;
    }
  }

  .programInitialGuideWrapSpecial {
    background-color: #ffffff;
    position: relative;
    min-height: 200px;
    align-items: center;
    padding: 80px;
  }

  .programResultWrap {
    background-color: #ffffff;
    text-align: center;
    position: relative;
    min-height: 200px;
    align-items: center;
    padding: 20px 80px 80px 80px;
  }

  .programResultWrap .program {
    border: 1px solid #cfd4d9;
    padding: 20px 20px;
    text-align: left;
    width: 290px;
    cursor: pointer;
    margin: 12px 10px 0 10px;
    border-radius: 4px;
    background-color: #fcfcfc;
  }

  .carouselWrap {
    background-color: #f7f8fc;
    height: 100%;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    .ant-btn {
      width: 100px;
    }
  }

  .programResults {
    display: flex;
    justify-content: center;
  }

  .programResults img {
    height: 23px;
    width: 28px;
    margin-right: 7px;
  }

  .program-list-title {
    font-size: 30px;
    text-align: center;
    font-weight: 600;
  }

  .program-list {
    padding: 20px 80px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 60px !important;
    background: #ffffff;
    justify-content: initial !important;

    .program-card-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      border-radius: 6px;
      background: #fff;
      width: auto;
      height: auto;
      min-height: 150px;
      flex-shrink: 0;
      padding: 0px 30px 0px 30px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .program-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      .label {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .program-description {
      font-size: 16px;
      padding: 15px 0px 0px 0px;
    }
  }

  .program-list:after {
    content: "";
    width: 21%;
    border: 1px solid transparent;
  }
}
