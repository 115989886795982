

input[disabled] {
    pointer-events: none;
}

.exitstrategyTipTitle{
    display: flex;
    justify-content: start;
    align-items: center;
    color: #325CEB;
    font-size: 14px;
    font-weight: 600;
}

.loanTypeTooltip .ant-popover-inner{
    background-color: #EFF4FF !important;
    width: 400px;
    height: 400px;
    padding: 20px;
}

.loanPurposeTooltip .ant-popover-inner{
    background-color: #EFF4FF !important;
    width: 400px;
    padding: 20px;
}

.exitstrategyTooltip .ant-popover-inner{
    background-color: #EFF4FF !important;
    width: 450px;
    height: 450px;
    padding: 20px;
}

.loanTypeTooltip .ant-popover-title{
    font-size: 14px;
    font-weight: 600;
    color: #1F3988;
    margin-bottom: 20px;
}

.exitstrategyTooltip .ant-popover-title{
    font-size: 14px;
    font-weight: 600;
    color: #1F3988;
    margin-bottom: 20px;
}

.popoverContentWrap{
    display: flex;
    flex-direction: column;
    gap:5px;
    font-size: 14px;
}

.popoverContentWrap b{
    font-weight: 600;
    color: #1F3988;
}